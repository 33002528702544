import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import "./DeathVerificationPage.scss";
import { DeathVerification, GetStartedDeathVerification } from "../../common";
import history from "./../../common/history";
import VerifyIdentityInState from "../../common/forms/verifyIdentity/VerifyIdentityInState";
import VerifyIdentityOutOfState from "../../common/forms/verifyIdentity/VerifyIdentityOutOfState";
import useGlobal from "../../../store";
import uuid from "react-native-uuid";
import { useTranslation } from "react-i18next";
import GetCanonicalUrl from "../../common/GetCanonicalUrl";
import {GetStateData,GetCountyData} from "referencedata";

function DeathVerificationPage() {
  const { t } = useTranslation();
  const [stateList, setStateList] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [countyList, setCountyList] = useState({});
  const [globalState, globalActions] = useGlobal();
  const [arrayIndex, setArrayIndex] = useState("");

  const nextStep = (value) => {
    window.scrollTo(0, 0);
    value ? setCurrentStep(value) : setCurrentStep(currentStep + 1);

    value
      ? setUrlForForm("deathVerify", value)
      : setUrlForForm("deathVerify", currentStep + 1);
  };
  const prevStep = (value) => {
    window.scrollTo(0, 0);
    value ? setCurrentStep(value) : setCurrentStep(currentStep - 1);

    value
    ? setUrlForForm("deathVerify", value)
    : setUrlForForm("deathVerify", currentStep - 1);
  };

  const setUrlForForm = (record, step) => {
    if (record === "deathVerify") {
      switch (step) {
        case 0:
          history.push(t("URLS.ORDER_DEATH_VERIFICATION_LETTER"));
          break;
        case 1:
          history.push(
            t("URLS.ORDER_DEATH_VERIFICATION_LETTER") +
              t("URLS.VERIFY_IDENTITY_IN_STATE")
          );

          break;
        case 2:
          history.push(
            t("URLS.ORDER_DEATH_VERIFICATION_LETTER") +
              t("URLS.VERIFY_IDENTITY_OUT_STATE")
          );
          break;
        case 3:
          history.push(
            t("URLS.ORDER_DEATH_VERIFICATION_LETTER") +
              t("URLS.DEATH_VERIFICATION_INFORMATION")
          );
          break;
        default:
          history.push(t("URLS.ORDER_DEATH_VERIFICATION_LETTER"));
      }
    }
  };

  const firstStep = (value) => {
    window.scrollTo(0, 0);
    value ? setCurrentStep(value) : setCurrentStep(0);

    value
    ? setUrlForForm("deathVerify", value)
    : setUrlForForm("deathVerify", 0);
  };

  useEffect(() => {
    if (!globalState.uniqueIdentifier) {
      globalActions.addUniqueIdentifier("OVRAREF_ID-" + uuid.v1());
    }
    globalActions.clearGlobalState();
    if (
      globalState.saveEditIndex.arrayIndex !== "" &&
      globalState.saveEditIndex.referenceType === "deathVerification"
    ) {
      setArrayIndex(globalState.saveEditIndex.arrayIndex);
      globalState.getStarteDeathVerificationInfo.dateOfDeath = new Date(
        globalState.totalOrderRecords[
          globalState.saveEditIndex.arrayIndex
        ].dateOfDeath
      );
      globalState.getStarteDeathVerificationInfo.selectedState =
        globalState.totalOrderRecords[
          globalState.saveEditIndex.arrayIndex
        ].selectedState;
      globalState.deathVerificationInfo =
        globalState.totalOrderRecords[globalState.saveEditIndex.arrayIndex];
    }
  }, []);

  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {t("DEATH_VERIFICATION_INFO.TITLE")}
          </title>
          <meta
            name="description"
            content={t("DEATH_VERIFICATION_INFO.META_CONTENT")}
          />
          <link
            rel="canonical"
            href={
              GetCanonicalUrl() + t("URLS.ORDER_DEATH_VERIFICATION_LETTER_2")
            }
          />
        </Helmet>
        <Container className="deathverify-container">
          {currentStep === 0 && (
            <GetStartedDeathVerification
              stateList={GetStateData()}
              nextCallback={nextStep}
            />
          )}
          {currentStep === 1 && (
            <VerifyIdentityInState
              stateList={GetStateData()}
              nextCallback={nextStep}
              prevCallback={prevStep}
              flowIndex="deathVerification"
            />
          )}
          {currentStep === 2 && (
            <VerifyIdentityOutOfState
              stateList={GetStateData()}
              nextCallback={nextStep}
              prevCallback={firstStep}
              flowIndex="deathVerification"
            />
          )}
          {currentStep === 3 && (
            <DeathVerification
              countyList={GetCountyData()}
              stateList={GetStateData()}
              editIndex={arrayIndex}
            />
          )}
        </Container>
      </div>
    </>
  );
}

export default DeathVerificationPage;
