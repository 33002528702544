import GetApiUrl from "../components/common/GetApiUrl";

export default function GetPublicIPAddress(uniqueId, applicationId, agencyId) {
  return fetch(GetApiUrl() + "/v1/ovra/ipping/retrieveClientIp", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    },

    body: JSON.stringify({
      uniqueIdentifier: uniqueId,
      applicationId: applicationId,
      agencyId: agencyId,
    }),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      return resp.clientIpAddress;
    })
    .catch(function () {
      return "";
    });
}
