import { useEffect } from 'react';
import getSitekey from "./GetSiteKey";
function ReCaptcha() {
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = function () {
          const recapterTimer = setInterval(() => {
            const gReCaptcha = document.querySelector('.g-recaptcha-response');
            if (gReCaptcha) {
              clearInterval(recapterTimer);
              gReCaptcha.setAttribute("aria-label", "true");
              gReCaptcha.setAttribute("aria-readonly", "true");
              gReCaptcha.setAttribute("aria-hidden", "true");
            }
          }, 1000);
          
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };
    const siteKey = getSitekey();
    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
     'https://www.google.com/recaptcha/enterprise.js?render='+siteKey
    );
  }, []);
}

export default ReCaptcha;
