import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { Row, Col } from "react-bootstrap";
import Progress from "../../Progress";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../deathRecordInfo/DeathCertificateInfo.scss";
import useGlobal from "../../../../store";
import DateFormatter from "../../Date";
import { FormInput, FormInputOptional } from "../../../common/FormInput";
import GenderList from "../../CommonList";
import { NumberField, SelectFieldOptional } from "../../../common/SelectField";
import history from "./../../../common/history";
import { PurposeDeathList } from "../../PurposeList";
import { useTranslation } from "react-i18next";

import MaskedInput from "react-maskedinput";

import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { IDGenerator } from "../../../common/CommonList";
import Button from "react-bootstrap/Button";
import { isMobile, isMobileOnly } from "react-device-detect";

function GetStartedDeathCertificate(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const [showParentChecked, setShowParentChecked] = useState(false);
  const countyList = props.countyList;
  const stateList = props.stateList;

  const SignupSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(t("BIRTH_CERTITICATE_INFO_ERROR_MSG.FIRST_NAME_MISSING_ERROR"))
      .matches(
        /(?=^.{1,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("BIRTH_CERTITICATE_INFO_ERROR_MSG.FIRST_NAME_INVALID_ENTRY")
      ),
    middleName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("BIRTH_CERTITICATE_INFO_ERROR_MSG.MIDDLE_NAME_INVALID_ENTRY")
      ),
    lastName: yup
      .string()
      .required(t("BIRTH_CERTITICATE_INFO_ERROR_MSG.LAST_NAME_MISSING_ERROR"))
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("BIRTH_CERTITICATE_INFO_ERROR_MSG.LAST_NAME_INVALID_ENTRY")
      ),
    selectedGender: yup.object().shape({
      label: yup.string().required(t("COMMON_ERROR_MSG.GENDER_ERROR")),
      value: yup.string().required(t("COMMON_ERROR_MSG.GENDER_ERROR")),
    }),
    city: yup
      .string()
      .required(t("DEATH_CERTIFICATE_INFO.CITY_OF_DEATH"))
      .matches(
        /^[a-zA-Z ]*$/,
        t("BIRTH_CERTITICATE_INFO_ERROR_MSG.CITY_INVALID_ENTRY")
      ),
    countyOfDeath: yup.object().shape({
      label: yup.string().required(t("COMMON_ERROR_MSG.COUNTY_DEATH")),
      value: yup.string().required(t("COMMON_ERROR_MSG.COUNTY_DEATH")),
    }),

    dateOfDeath: yup.date(),

    dateOfBirth: yup
      .date()
      .required(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE"))
      .min(new Date("1780-01-01"), t("DATE_COMMON_ERRORS.VERIFY_OPTIONAL_BIRTH_DATE_VALID"))
      .when(
        "dateOfDeath",
        (dateOfDeath, schema) =>
          dateOfDeath &&
          schema.max(
            new Date(dateOfDeath),
            t("DATE_COMMON_ERRORS.VERIFY_BIRTH_DATE_VALID")
          )
      )
      .typeError(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE")),

    cityOfBirth: yup
      .string()
      .matches(
        /^[a-zA-Z ]*$/,
        t("BIRTH_CERTITICATE_INFO_ERROR_MSG.CITY_INVALID_ENTRY")
      ),
    countyOfBirth: yup
      .string()
      .matches(
        /^[_A-zÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÝàáâãäåçèéêëìíîïðòóôõöùúûüýÿ\\s]*((-|\s)*[_A-z\-])*$/,
        t("COMMON_ERROR_MSG.COUNTY_INVALID_ENTRY")
      ),
    countryOfBirth: yup
      .string()
      .matches(
        /^[_A-zÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÝàáâãäåçèéêëìíîïðòóôõöùúûüýÿ\\s]*((-|\s)*[_A-z\-])*$/,
        t("COMMON_ERROR_MSG.COUNTRY_INVALID_ENTRY")
      ),
    parent1FirstName: yup
      .string()
      .required(t("BIRTH_CERTITICATE_INFORMATION.PARENT1_FIRST_NAME_ERROR"))
      .matches(
        /(?=^.{1,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("COMMON_ERROR_MSG.FIRST_NAME_INVALID_ENTRY")
      ),
    parent1MiddleName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.MIDDLE_NAME_INVALID_ENTRY")
      ),
    parent1LastName: yup
      .string()
      .required(t("BIRTH_CERTITICATE_INFORMATION.PARENT1_LAST_NAME_ERROR"))
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("COMMON_ERROR_MSG.LAST_NAME_INVALID_ENTRY")
      ),

    parent2MiddleName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.MIDDLE_NAME_INVALID_ENTRY")
      ),

    parent2FirstName: yup.string().when("showParentTwo", {
      is: (showParentTwo) => showParentTwo === false,
      then: yup
        .string()
        .required(t("BIRTH_CERTITICATE_INFO_ERROR_MSG.PARENT_TWO_FIRST_NAME"))
        .matches(
          /(?=^.{1,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
          t("COMMON_ERROR_MSG.FIRST_NAME_INVALID_ENTRY")
        ),
    }),

    parent2LastName: yup.string().when("showParentTwo", {
      is: (showParentTwo) => showParentTwo === false,
      then: yup
        .string()
        .required(t("BIRTH_CERTITICATE_INFO_ERROR_MSG.PARENT_TWO_LAST_NAME"))
        .matches(
          /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
          t("COMMON_ERROR_MSG.LAST_NAME_INVALID_ENTRY")
        ),
    }),
    purposeList: yup.object().shape({
      label: yup.string().required(t("COMMON_ERROR_MSG.PURPOSE_ERROR")),
      value: yup.string().required(t("COMMON_ERROR_MSG.PURPOSE_ERROR")),
    }),
    noOfCopies: yup
      .number()
      .typeError(t("VALIDATION_COMMON.COPIES_LIMIT_MIN"))
      .required(t("VALIDATION_COMMON.COPIES_LIMIT_MIN"))
      .moreThan(0, t("COMMON_ERROR_MSG.NO_OF_COPIES"))
      .lessThan(11, t("VALIDATION_COMMON.COPIES_LIMIT_MAX")),
  });

  if (props.editIndex !== "") {
    globalState.deathCertificateInfoData =
      globalState.totalOrderRecords[props.editIndex];
    //setShowParentChecked(globalState.getStartedDeathCertificateData.showParentTwo);
  }

  globalState.deathCertificateInfoData.dateOfDeath = globalState
    .getStartedDeathCertificateData.dateOfDeath
    ? DateFormatter(
      globalState.getStartedDeathCertificateData.dateOfDeath,
      "mm/dd/yyyy"
    )
    : "";

  const { register, handleSubmit, control, errors } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(SignupSchema),
  });

  const onSubmit = (data) => {
    data.certificateInfoType = "deathCertificate";
    data.uid = IDGenerator();
    data.selectedState =
      globalState.getStartedDeathCertificateData.selectedState;
    data.selectedRelation =
      globalState.getStartedDeathCertificateData.selectedRelation;
    data.funeralHomeID =
      globalState.getStartedDeathCertificateData.funeralHomeID;
    globalActions.addDeathCertificateInfo(data, props.editIndex);
    history.push(t("URLS.ORDER_REVIEW"));
  };

  const addAnotherRecord = (data) => {
    data.certificateInfoType = "deathCertificate";
    data.uid = IDGenerator();
    data.selectedState =
      globalState.getStartedDeathCertificateData.selectedState;
    data.selectedRelation =
      globalState.getStartedDeathCertificateData.selectedRelation;
    globalActions.addDeathCertificateInfo(data, props.editIndex);
    window.scrollTo(0, 0);
    history.push(t("URLS.SELECT_VITAL_RECORD"));
  };

  return (
    <div>
      <Progress currentStep={2} totalSteps={4} />
      <h1 className="deathverification-heading">
        {t("DEATH_CERTIFICATE_INFO.HEADING")}
      </h1>
      <h2 className="birth-personal-info">
        {t("DEATH_CERTIFICATE_INFO.DEATH_CERTIFCATEINFO")}
      </h2>
      <div>
        <form
          className="verify-content-section"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="verifyinformation-div">
            <FormInput
              id="firstName"
              name="firstName"
              type="text"
              label={t("DEATH_CERTIFICATE_INFO.FIRST_NAME")}
              register={register}
              className={`birth-info-input answer ${errors.firstName ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathCertificateInfoData.firstName
                  ? globalState.deathCertificateInfoData.firstName
                  : ""
              }
              error={errors.firstName}
            />
            <FormInputOptional
              id="middleName"
              name="middleName"
              type="text"
              label={t("DEATH_CERTIFICATE_INFO.MIDDLE_NAME")}
              labelOptional={t("COMMONS.OPTIONAL")}
              register={register}
              className={`birth-info-input answer ${errors.middleName ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathCertificateInfoData.middleName
                  ? globalState.deathCertificateInfoData.middleName
                  : ""
              }
              error={errors.middleName}
            />
            <FormInput
              id="lastName"
              name="lastName"
              type="text"
              label={t("DEATH_CERTIFICATE_INFO.LAST_NAME")}
              register={register}
              className={`birth-info-input answer ${errors.lastName ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathCertificateInfoData.lastName
                  ? globalState.deathCertificateInfoData.lastName
                  : ""
              }
              error={errors.lastName}
            />

            <div className="death-gender-input-align">
              <label className="name">
                {t("DEATH_CERTIFICATE_INFO.GENDER")}
                <Controller
                  name="selectedGender"
                  className={`get-state-birth ${errors.selectedGender ? "error-red" : ""
                    }`}
                  as={Select}
                  options={GenderList()}
                  control={control}
                  isClearable
                  defaultValue={
                    globalState.deathCertificateInfoData.selectedGender.label
                      ? globalState.deathCertificateInfoData.selectedGender
                      : ""
                  }
                  rules={{ required: true }}
                />
                {errors.selectedGender && (
                  <p className="error-red-content">
                    {t("COMMON_ERROR_MSG.GENDER_ERROR")}
                  </p>
                )}
              </label>
            </div>
            <FormInputOptional
              id="ssn"
              name="ssn"
              type="number"
              label={t("DEATH_CERTIFICATE_INFO.SSN")}
              labelOptional={t("COMMONS.OPTIONAL")}
              register={register}
              className={`birth-info-input answer ${errors.ssn ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathCertificateInfoData.ssn
                  ? globalState.deathCertificateInfoData.ssn
                  : ""
              }
              error={errors.ssn}
            />
            <FormInput
              id="dateOfDeath"
              name="dateOfDeath"
              type="text"
              disabled={true}
              label={t("DEATH_CERTIFICATE_INFO.DATE_DEATH")}
              register={register}
              className={`birth-info-input answer ${errors.dateOfDeath ? "error-red" : ""
                }`}
              defaultValue={globalState.deathCertificateInfoData.dateOfDeath}
            />
            <FormInput
              id="city"
              name="city"
              type="text"
              label={t("DEATH_CERTIFICATE_INFO.CITY")}
              register={register}
              rules={{ required: true }}
              className={`birth-info-input answer ${errors.city ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathCertificateInfoData.city
                  ? globalState.deathCertificateInfoData.city
                  : ""
              }
              error={errors.city}
            />

            <div className="death-gender-input-align">
              <label className="name">
                {t("DEATH_CERTIFICATE_INFO.COUNTY")}
                <Controller
                  name="countyOfDeath"
                  className={`get-state-birth ${errors.countyOfDeath ? "error-red" : ""
                    }`}
                  as={Select}
                  options={countyList}
                  control={control}
                  isClearable
                  defaultValue={
                    globalState.deathCertificateInfoData.countyOfDeath.label
                      ? globalState.deathCertificateInfoData.countyOfDeath
                      : ""
                  }
                  rules={{ required: true }}
                />
                {errors.countyOfDeath && (
                  <p className="error-red-content">
                    {t("COMMON_ERROR_MSG.COUNTY_DEATH")}
                  </p>
                )}
              </label>
            </div>

            <label htmlFor="dateOfBirth" className="deathverification-info1">
              {t("DEATH_CERTIFICATE_INFO.DATE_ON_CERTIFICATE")}
            </label>
            <Controller
              control={control}
              id="dateOfBirth"
              name="dateOfBirth"
              rules={{ required: true }}
              defaultValue={globalState.deathCertificateInfoData.dateOfBirth}
              className="input-date"
              render={({ onChange, onBlur, value }) => (
                <ReactDatePicker
                  onChange={onChange}
                  id="dateOfBirth"
                  onBlur={onBlur}
                  selected={value}
                  labelOptional={t("COMMONS.OPTIONAL")}
                  className={`input-date get-state-birth ${errors.dateOfBirth ? "error-red" : ""
                    }`}
                  showMonthDropdown
                  showYearDropdown
                  open={isMobile ? false : undefined}
                  customInput={<MaskedInput mask="11/11/1111" />}
                  placeholderText="00/00/0000"
                />
              )}
            />
            {errors.dateOfBirth && (
              <p className="error-red-content">{errors.dateOfBirth.message}</p>
            )}

            <FormInputOptional
              id="cityOfBirth"
              name="cityOfBirth"
              type="text"
              label={t("DEATH_CERTIFICATE_INFO.CITY_BIRTH")}
              labelOptional={t("COMMONS.OPTIONAL")}
              register={register}
              className={`birth-info-input answer ${errors.cityOfBirth ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathCertificateInfoData.cityOfBirth
                  ? globalState.deathCertificateInfoData.cityOfBirth
                  : ""
              }
              error={errors.cityOfBirth}
            />
            <FormInputOptional
              id="countyOfBirth"
              name="countyOfBirth"
              type="text"
              label={t("DEATH_CERTIFICATE_INFO.COUNTY_BIRTH")}
              labelOptional={t("COMMONS.OPTIONAL")}
              register={register}
              className={`birth-info-input answer ${errors.countyOfBirth ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathCertificateInfoData.countyOfBirth
                  ? globalState.deathCertificateInfoData.countyOfBirth
                  : ""
              }
              error={errors.countyOfBirth}
            />

            <label className="birth-content">
              {t("DEATH_CERTIFICATE_INFO.STATE_BIRTH")}
              <span className="optional-text">{t("COMMONS.OPTIONAL")}</span>
              <div>
                <Controller
                  name="stateOfBirth"
                  className={`get-state-birth field-label-padding ${errors.stateOfBirth ? "error-red" : ""
                    }`}
                  as={Select}
                  options={stateList}
                  control={control}
                  isClearable
                  defaultValue={
                    globalState.deathCertificateInfoData.stateOfBirth
                      .label
                      ? globalState.deathCertificateInfoData.stateOfBirth
                      : ""
                  }
                  rules={{ required: true }}
                />
                {errors.stateOfBirth && (
                  <p className="error-red-content">
                    {t("GET_STARTED_BIRTHCERTIFICATE.STATE_ERROR")}
                  </p>
                )}
              </div>
            </label>

            <FormInputOptional
              id="countryOfBirth"
              name="countryOfBirth"
              type="text"
              label={t("DEATH_CERTIFICATE_INFO.COUNTRY_BIRTH")}
              labelOptional={t("COMMONS.OPTIONAL")}
              register={register}
              className={`birth-info-input answer ${errors.countryOfBirth ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathVerificationInfo.countryOfBirth
                  ? globalState.deathVerificationInfo.countryOfBirth
                  : ""
              }
              error={errors.countryOfBirth}
            />

            <h2 className="deathverification-parent">
              {t("DEATH_CERTIFICATE_INFO.PARENTS_HEADING")}
            </h2>
            <FormInput
              id="parent1FirstName"
              name="parent1FirstName"
              type="text"
              label={t("DEATH_CERTIFICATE_INFO.PARENT1_FIRST_NAME")}
              register={register}
              rules={{ required: true }}
              className={`birth-info-input answer ${errors.parent1FirstName ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathCertificateInfoData.parent1FirstName
                  ? globalState.deathCertificateInfoData.parent1FirstName
                  : ""
              }
              error={errors.parent1FirstName}
            />
            <FormInputOptional
              id="parent1MiddleName"
              name="parent1MiddleName"
              type="text"
              label={t("DEATH_CERTIFICATE_INFO.PARENT1_MIDDLE_NAME")}
              labelOptional={t("COMMONS.OPTIONAL")}
              register={register}
              className={`birth-info-input answer ${errors.parent1MiddleName ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathCertificateInfoData.parent1MiddleName
                  ? globalState.deathCertificateInfoData.parent1MiddleName
                  : ""
              }
              error={errors.parent1MiddleName}
            />
            <FormInput
              id="parent1LastName"
              name="parent1LastName"
              type="text"
              label={t("DEATH_CERTIFICATE_INFO.PARENT1_LAST_NAME")}
              register={register}
              rules={{ required: true }}
              className={`birth-info-input answer ${errors.parent1LastName ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathCertificateInfoData.parent1LastName
                  ? globalState.deathCertificateInfoData.parent1LastName
                  : ""
              }
              error={errors.parent1LastName}
            />
            <div className="form-checkbox-default">
              <input
                type="checkbox"
                onChange={() => setShowParentChecked(!showParentChecked)}
                className="checkbox"
                id="parenttwoshow"
                name="showParentTwo"
                ref={register}
                checked={globalState.deathCertificateInfoData.showParentTwo}
              />
              <label htmlFor="parenttwoshow" className="checkbox-parent">
                {t("DEATH_CERTIFICATE_INFO.PARENT_CHECKBOX")}
              </label>
            </div>
            {!showParentChecked && (
              <>
                <FormInput
                  id="parent2FirstName"
                  name="parent2FirstName"
                  type="text"
                  label={t("DEATH_CERTIFICATE_INFO.PARENT2_FIRST_NAME")}
                  register={register}
                  rules={{ required: true }}
                  className={`birth-info-input answer ${errors.parent2FirstName ? "error-red" : ""
                    }`}
                  defaultValue={
                    globalState.deathCertificateInfoData.parent2FirstName
                      ? globalState.deathCertificateInfoData.parent2FirstName
                      : ""
                  }
                  error={errors.parent2FirstName}
                />
                <FormInputOptional
                  id="parent2MiddleName"
                  name="parent2MiddleName"
                  type="text"
                  label={t("DEATH_CERTIFICATE_INFO.PARENT2_MIDDLE_NAME")}
                  labelOptional={t("COMMONS.OPTIONAL")}
                  register={register}
                  className={`birth-info-input answer ${errors.parent2MiddleName ? "error-red" : ""
                    }`}
                  defaultValue={
                    globalState.deathCertificateInfoData.parent2MiddleName
                      ? globalState.deathCertificateInfoData.parent2MiddleName
                      : ""
                  }
                  error={errors.parent2MiddleName}
                />
                <FormInput
                  id="parent2LastName"
                  name="parent2LastName"
                  type="text"
                  label={t("DEATH_CERTIFICATE_INFO.PARENT2_LAST_NAME")}
                  register={register}
                  rules={{ required: true }}
                  className={`birth-info-input answer ${errors.parent2LastName ? "error-red" : ""
                    }`}
                  defaultValue={
                    globalState.deathCertificateInfoData.parent2LastName
                      ? globalState.deathCertificateInfoData.parent2LastName
                      : ""
                  }
                  error={errors.parent2LastName}
                />
              </>
            )}

            <h2 className="death-verification-info record-details-padding">
              {t("DEATH_CERTIFICATE_INFO.RECORD_AMOUNT")}
            </h2>

            <div className="info-input-align">
              <label className="birth-content">
                {t("DEATH_CERTIFICATE_INFO.PURPOSE")}
                <Controller
                  name="purposeList"
                  className={`get-state-birth ${errors.purposeList ? "error-red" : ""
                    }`}
                  as={Select}
                  options={PurposeDeathList()}
                  control={control}
                  rules={{ required: true }}
                  isClearable
                  defaultValue={
                    globalState.deathCertificateInfoData.purposeList.label
                      ? globalState.deathCertificateInfoData.purposeList
                      : ""
                  }
                />
                {errors.purposeList && (
                  <p className="error-red-content">
                    {t("COMMON_ERROR_MSG.PURPOSE_ERROR")}
                  </p>
                )}
              </label>
            </div>

            <NumberField
              id="noOfCopies"
              name="noOfCopies"
              type="number"
              register={register}
              label={t("DEATH_CERTIFICATE_INFO.COPIES")}
              defaultValue={
                globalState.deathCertificateInfoData.noOfCopies
                  ? globalState.deathCertificateInfoData.noOfCopies
                  : ""
              }
              error={errors.noOfCopies}
            />
            <div>
              <Row className="btn-birth-info" sm={1} xs={1} md={2} lg={2}>
                <Col>
                  <Button
                    variant="outline-primary"
                    className="back-button-birth-info"
                    onClick={handleSubmit(addAnotherRecord)}
                  >
                    {t("COMMONS.ADD_RECORD")}
                  </Button>
                </Col>
                <Col xs={{ order: isMobileOnly ? "first" : "last" }}>
                  <Button
                    variant="primary"
                    className="next-button"
                    type="submit"
                  >
                    {t("COMMONS.NEXT")}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default GetStartedDeathCertificate;
