import ga from "react-ga";
//import uuid from "react-native-uuid";

  export  function intializeGA() {
        ga.initialize('UA-128978786-58', {
        debug: false,
        titleCase: false,
      });
  //  // workaround for initial visit
  //   if (window.performance && (performance.navigation.type === performance.navigation.TYPE_NAVIGATE)) {
  //       ga.pageview(window.location.href)
  //       console.log("PAGEVIEW: intial load"+ " pathname"+ window.location.pathname + " href: "+window.location.href);
  //   }
    //Retrieve and store clientID to localStorage
     ga.ga(function(tracker) {
      localStorage.setItem('gaClientId', tracker.get('clientId'));
    });
}

export default function pageViewGA(path) {
    ga.pageview(path);
}

export function evnetGA(_category,_action,_label,_value,_nonInteraction){
  
const options= ga.EventArgs = {
    category: _category,
    action: _action,
    label: _label,
    value: _value,
    nonInteraction: _nonInteraction
  };
  ga.event(options);
 // console.log("PAGEVIEW: category="+_category+" action="+_action+" label="+_label+" value="+_value+" _nonInteraction="+_nonInteraction)
}


export function exceptionGA(_exDescription,_exFatal){
  
  let fieldObject= ga.FieldsObject = {
    description: _exDescription,
    fatal:_exFatal
  };
  ga.exception(fieldObject);
  
  }