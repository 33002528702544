import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "./UnableToVerify.scss";

function UnableToVerify(props) {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Order a Birth Certificate | DSHS Official Application | Texas.gov
        </title>
      </Helmet>
      <Container className="first-container">
        <hr />
        <div className="lg-container container error-main">
          <h1 className="error-heading">{t("UNABLE_TO_VERIFY.HEADING")}</h1>
          <p className="error-page-content">
            {t("UNABLE_TO_VERIFY.IDENTITY_VERIFICATION_ERROR")}
          </p>
          <p className="error-page-content">
            {t("UNABLE_TO_VERIFY.MORE_INFORMATION")}
          </p>
          <div className="error-padding">
            <a
              rel={"external"}
              k
              className="link-color"
              href={
                "https://dshs.texas.gov/vs/reqproc/Ordering-Birth-Certificates-by-Mail/"
              }
              target="_blank"
            >
              {t("UNABLE_TO_VERIFY.LINK_LABEL")}
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default UnableToVerify;
