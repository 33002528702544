import PostCall from "./CommonApi";

export const GetOrderStatus = (props,uuid) => {
    const requestBody = {}
    requestBody["applicationId"] = "OVRA";
    requestBody["agencyId"] = "DSHS";
    requestBody["uniqueIdentifier"] = uuid;
    requestBody["orderDetails"] = [];
    props.forEach(function (item) {
        item.recordDetails.forEach(function (recordItem){
            var orderDetails = {
                "recordType": recordItem.recordType,
                "numberOfCopies": recordItem.copies,
                "id": recordItem.id
            }
            requestBody["orderDetails"].push(orderDetails);
        });
    });
    let url = "/v1/ovra/transaction/OrderReview";
    return PostCall(url,requestBody);
};

export const GetOrderAPICall = (props,uuid) => {
    const requestBody = {}
    requestBody["applicationId"] = "OVRA";
    requestBody["agencyId"] = "DSHS";
    requestBody["uniqueIdentifier"] = uuid;
    requestBody["orderDetails"] = props;
    let url = "/v1/ovra/transaction/OrderReview";
    return PostCall(url,requestBody);
};


