import { useTranslation } from "react-i18next";

export default function PurposeBirthList() {
  const { t } = useTranslation();

  const purposeBirthList = [
    { value: "Insurance", label: t("PURPOSE_LIST.INSURANCE") },
    { value: "Newborn", label: t("PURPOSE_LIST.NEWBORN") },
    { value: "Records", label: t("PURPOSE_LIST.RECORDS") },
    { value: "School", label: t("PURPOSE_LIST.SCHOOL") },
    { value: "Travel/Passport", label: t("PURPOSE_LIST.TRAVEL/PASSPORT") },
    { value: "Other", label: t("PURPOSE_LIST.OTHER") },
  ];

  return purposeBirthList;
}

export function PurposeDeathList() {
  const { t } = useTranslation();

  const purposeDeathList = [
    { value: "Estate", label: t("PURPOSE_LIST.ESTATE") },
    { value: "Insurance", label: t("PURPOSE_LIST.INSURANCE") },
    { value: "Records", label: t("PURPOSE_LIST.RECORDS") },
    { value: "Other", label: t("PURPOSE_LIST.OTHER") },
  ];

  return purposeDeathList;
}
