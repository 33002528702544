export const BindState = (index, globalProps) => {

    var bindState = {}

    if (index === "deathCertificate") {
       bindState = {
          label: globalProps.getStartedDeathCertificateData.selectedState.label,
          value: globalProps.getStartedDeathCertificateData.selectedState.value,
        };
      }
      else if (index === "birthCertificate") {
        bindState = {
          label: globalProps.getStartedBirthCertificateData.selectedState.label,
          value: globalProps.getStartedBirthCertificateData.selectedState.value,
        };
      }
      else if (index === "birthVerification") {
        bindState = {
          label: globalProps.getStartedBirthVerificationInfo.selectedState.label,
          value: globalProps.getStartedBirthVerificationInfo.selectedState.value,
        };
      }
      else if (index === "deathVerification") {
        bindState = {
          label: globalProps.getStarteDeathVerificationInfo.selectedState.label,
          value: globalProps.getStarteDeathVerificationInfo.selectedState.value,
        };
      }
      else if (index === "marriageVerification") {
        bindState = {
          label: globalProps.getStartedMarriageVerificationInfo.selectedState.label,
          value: globalProps.getStartedMarriageVerificationInfo.selectedState.value,
        };
      }
      else if (index === "divorceVerification") {
        bindState = {
          label: globalProps.getStartedDivorceVerificationInfoData.selectedState.label,
          value: globalProps.getStartedDivorceVerificationInfoData.selectedState.value,
        };
      }

    return bindState
}