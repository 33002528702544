import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import useGlobal from "../../../store";
import uuid from "react-native-uuid";
import "./ErrorRefPage.scss";

function ErrorRefPage(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  var uniqueIdentifier =  "";
  if (!globalState.uniqueIdentifier) {
    uniqueIdentifier = "OVRAREF_ID-" + uuid.v1();
    globalActions.addUniqueIdentifier(uniqueIdentifier);
  }
  else
  {
    uniqueIdentifier = globalState.uniqueIdentifier;
  }
  
  return (
    <div>
      <Container className="first-container">
        <hr />
        <div className="lg-container container error-main">
          <h1 className="error-common-heading">{t("ERROR.HEADING")}</h1>
          <p className="error-page-content">{t("ERROR.PLEASE_TRY_AGAIN")}
            <a
              className="error-modal"
              target="blank"
              href={t("ERROR.HELP_DESK_LINK")}
            >
              {t("ERROR.HELP_DESK_NAME")}
            </a>
          </p>
          <p className="error-page-content">
            {t("ERROR.ERROR_REFERENCE")} {uniqueIdentifier}
          </p>
          <a className="button-primary error-page-button"
            href={t("URLS.ORDER_VITAL_RECORDS")}
          >
            {t("ERROR.TAKE_ME_HOME")}
          </a>
        </div>
      </Container>
    </div>
  );
}

export default ErrorRefPage;
