import PostCall from "./CommonApi";

export default function GetSubmitPayment(
  paymentToken,
  usasCodes,
  outTransactionId,
  traceNumber,
  uuid
) {
  let url = "/v1/ovra/transaction/SubmitPayment";
  let requestObject = 
      {
        applicationId: "OVRA",
        agencyId: "DSHS",
        transactionStatus: "EPAY",
        outTransactionId: outTransactionId,
        uniqueIdentifier: uuid,
        paymentToken: paymentToken,
        traceNumber: traceNumber,
        paymentInfo: [
          {
            type: "PAYMENT",
            description: "OVRA-Vital Statistics Unit",
            paymentAmount: JSON.parse(sessionStorage.getItem("paymentItem"))
              .totalAmount,
            quantity: 1,
            sku: "0045078537VS",
            usasCodes: usasCodes,
          },
        ],
        additionalInfo: [
          {
            key: "SourceApplication",
            value: "OVRA",
          },
        ],
      }
    return PostCall(url, requestObject);
}
