import i18n from "../../i18n";
import React, { useEffect } from "react";
import { Container, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import history from "../common/history";
import "./../../styles/Header.scss";
import { useTranslation } from "react-i18next";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/Texas_DSHS_Logo.svg";

function HeaderSub() {
  const { t } = useTranslation();
  useEffect(() => {
    let currentUrl = window.location.href;
    if (currentUrl.indexOf("/es/") !== -1) {
      i18n.changeLanguage("es");
    } else {
      setTimeout(function () {
        i18n.changeLanguage("en");
      }, 100);
    }
  }, []);

  const handleClick = (lang) => {
    let currentUrl = window.location.href;
    console.log("Changing language", lang);
    if (lang === "es") {
      i18n.changeLanguage("es");
      if (currentUrl.indexOf("ovra/order-vital-records") !== -1) {
        history.push("/ovra/es/solicitar-registros-vitales");
      } else if (currentUrl.indexOf("ovra/vital-record-order-status") !== -1) {
        history.push("/ovra/es/estado-del-pedido-de-registros-vitales/");
      }

      // SELECT VITAL RECORD STATUS EN
      else if (currentUrl.indexOf("ovra/vital-record-order-status") !== -1) {
        history.push("/ovra/es/estado-del-pedido-de-registros-vitales/");
      }

      // SELECT VITAL RECORD EN
      else if (currentUrl.indexOf("ovra/select-vital-record-order") !== -1) {
        history.push("/ovra/es/seleccionar-un-registro-vital-para-solicitar");
      }
      //  MARRIAGE VERIFICATION EN
      else if (
        currentUrl.indexOf(
          "/ovra/order-marriage-verification-letter/marriage-verification-information"
        ) !== -1
      ) {
        history.push(
          "/ovra/es/solicitar-carta-de-verificacion-de-matrimonio/informacion-del-certificado-de-nacimiento"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/order-marriage-verification-letter/verify-identity-in-state"
        ) !== -1
      ) {
        history.push(
          "/ovra/es/solicitar-carta-de-verificacion-de-matrimonio/verificar-identidad-en-estado"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/order-marriage-verification-letter/verify-identity-out-of-state"
        ) !== -1
      ) {
        history.push(
          "/ovra/es/solicitar-carta-de-verificacion-de-matrimonio/verificar-identidad-fuera-de-estado"
        );
      } else if (
        currentUrl.indexOf("/ovra/order-marriage-verification-letter") !== -1
      ) {
        history.push("/ovra/es/solicitar-carta-de-verificacion-de-matrimonio");
      }

      // DEATH CERTIFICATE EN
      else if (
        currentUrl.indexOf(
          "/ovra/order-death-certificate/death-certificate-information"
        ) !== -1
      ) {
        history.push(
          "/ovra/es/solicitar-certificado-de-defuncion/informacion-del-certificado-de-defuncion"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/order-death-certificate/verify-identity-in-state"
        ) !== -1
      ) {
        history.push(
          "/ovra/es/solicitar-certificado-de-defuncion/verificar-identidad-en-estado"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/order-death-certificate/verify-identity-out-of-state"
        ) !== -1
      ) {
        history.push(
          "/ovra/es/solicitar-certificado-de-defuncion/verificar-identidad-fuera-de-estado"
        );
      } else if (currentUrl.indexOf("/ovra/order-death-certificate") !== -1) {
        history.push("/ovra/es/solicitar-certificado-de-defuncion");
      }

      // DEATH VERIFICATION EN
      else if (
        currentUrl.indexOf(
          "/ovra/order-death-verification-letter/death-verification-information"
        ) !== -1
      ) {
        history.push(
          "/ovra/es/solicitar-carta-de-verificacion-de-defuncion/informacion-de-verificacion-de-defuncion"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/order-death-verification-letter/verify-identity-in-state"
        ) !== -1
      ) {
        history.push(
          "/ovra/es/solicitar-carta-de-verificacion-de-defuncion/verificar-identidad-en-estado"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/order-death-verification-letter/verify-identity-out-of-state"
        ) !== -1
      ) {
        history.push(
          "/ovra/es/solicitar-carta-de-verificacion-de-defuncion/verificar-identidad-fuera-de-estado"
        );
      } else if (
        currentUrl.indexOf("/ovra/order-death-verification-letter") !== -1
      ) {
        history.push("/ovra/es/solicitar-carta-de-verificacion-de-defuncion");
      }

      //  DIVORCE VERIFICATION EN
      else if (
        currentUrl.indexOf(
          "/ovra/order-divorce-verification-letter/divorce-verification-information"
        ) !== -1
      ) {
        history.push(
          "/ovra/es/solicitar-carta-de-verificacion-de-divorcio/informacion-de-verificacion-de-defuncion"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/order-divorce-verification-letter/verify-identity-in-state"
        ) !== -1
      ) {
        history.push(
          "/ovra/es/solicitar-carta-de-verificacion-de-divorcio/verificar-identidad-en-estado"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/order-divorce-verification-letter/verify-identity-out-of-state"
        ) !== -1
      ) {
        history.push(
          "/ovra/es/solicitar-carta-de-verificacion-de-divorcio/verificar-identidad-fuera-de-estado"
        );
      } else if (
        currentUrl.indexOf("/ovra/order-divorce-verification-letter") !== -1
      ) {
        history.push("/ovra/es/solicitar-carta-de-verificacion-de-divorcio");
      }

      // BIRTH VERIFICATION EN
      else if (
        currentUrl.indexOf(
          "/ovra/order-birth-verification-letter/birth-verification-information"
        ) !== -1
      ) {
        history.push(
          "/ovra/es/solicitar-carta-de-verificacion-de-nacimiento/informacion-de-verificacion-de-nacimiento"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/order-birth-verification-letter/verify-identity-in-state"
        ) !== -1
      ) {
        history.push(
          "/ovra/es/solicitar-carta-de-verificacion-de-nacimiento/verificar-identidad-en-estado"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/order-birth-verification-letter/verify-identity-out-of-state"
        ) !== -1
      ) {
        history.push(
          "/ovra/es/solicitar-carta-de-verificacion-de-nacimiento/verificar-identidad-fuera-de-estado"
        );
      } else if (
        currentUrl.indexOf("/ovra/order-birth-verification-letter") !== -1
      ) {
        history.push("/ovra/es/solicitar-carta-de-verificacion-de-nacimiento");
      }

      // BIRTH CERTIFICATE EN
      else if (
        currentUrl.indexOf(
          "/ovra/order-birth-certificate/birth-certificate-information"
        ) !== -1
      ) {
        history.push(
          "/ovra/es/solicitar-certificado-de-nacimiento/informacion-del-certificado-de-nacimiento"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/order-birth-certificate/verify-identity-in-state"
        ) !== -1
      ) {
        history.push(
          "/ovra/es/solicitar-certificado-de-nacimiento/verificar-identidad-en-estado"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/order-birth-certificate/verify-identity-out-of-state"
        ) !== -1
      ) {
        history.push(
          "/ovra/es/solicitar-certificado-de-nacimiento/verificar-identidad-fuera-de-estado"
        );
      } else if (currentUrl.indexOf("/ovra/order-birth-certificate") !== -1) {
        history.push("/ovra/es/solicitar-certificado-de-nacimiento");
      }

      //OrderReview EN
      else if (currentUrl.indexOf("/ovra/order-review") !== -1) {
        history.push("/ovra/es/revision-del-pedido");
      }
      //Shipping Information EN
      else if (currentUrl.indexOf("/ovra/shipping-info") !== -1) {
        history.push("/ovra/es/informacion-de-envio");
      }
      //Payment EN
      else if (currentUrl.indexOf("/ovra/payment-info") !== -1) {
        history.push("/ovra/es/informacion-de-pago");
      }
      //OrderReceipt EN
      else if (currentUrl.indexOf("/ovra/order-receipt") !== -1) {
        history.push("/ovra/es/recibo-de-pedido");
      }
    } else if (lang === "en") {
      i18n.changeLanguage("en");
      if (currentUrl.indexOf("/ovra/es/solicitar-registros-vitales") !== -1) {
        history.push("/ovra/order-vital-records");
      }

      // DEATH CERTIFICATE ES
      else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-certificado-de-defuncion/informacion-del-certificado-de-defuncion"
        ) !== -1
      ) {
        history.push(
          "/ovra/order-death-certificate/death-certificate-information"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-certificado-de-defuncion/verificar-identidad-en-estado"
        ) !== -1
      ) {
        history.push("/ovra/order-death-certificate/verify-identity-in-state");
      } else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-certificado-de-defuncion/verificar-identidad-fuera-de-estado"
        ) !== -1
      ) {
        history.push(
          "/ovra/order-death-certificate/verify-identity-out-of-state"
        );
      } else if (
        currentUrl.indexOf("/ovra/es/solicitar-certificado-de-defuncion") !== -1
      ) {
        history.push("/ovra/order-death-certificate");
      }
      //  MARRIAGE VERIFICATION ES
      else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-carta-de-verificacion-de-matrimonio/informacion-del-certificado-de-nacimiento"
        ) !== -1
      ) {
        history.push(
          "/ovra/order-marriage-verification-letter/marriage-verification-information"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-carta-de-verificacion-de-matrimonio/verificar-identidad-en-estado"
        ) !== -1
      ) {
        history.push(
          "/ovra/order-marriage-verification-letter/verify-identity-in-state"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-carta-de-verificacion-de-matrimonio/verificar-identidad-fuera-de-estado"
        ) !== -1
      ) {
        history.push(
          "/ovra/order-marriage-verification-letter/verify-identity-out-of-state"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-carta-de-verificacion-de-matrimonio"
        ) !== -1
      ) {
        history.push("/ovra/order-marriage-verification-letter");
      }
      // DEATH VERIFICATION ES
      else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-carta-de-verificacion-de-defuncion/informacion-de-verificacion-de-defuncion"
        ) !== -1
      ) {
        history.push(
          "/ovra/order-death-verification-letter/death-verification-information"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-carta-de-verificacion-de-defuncion/verificar-identidad-en-estado"
        ) !== -1
      ) {
        history.push(
          "/ovra/order-death-verification-letter/verify-identity-in-state"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-carta-de-verificacion-de-defuncion/verificar-identidad-fuera-de-estado"
        ) !== -1
      ) {
        history.push(
          "/ovra/order-death-verification-letter/verify-identity-out-of-state"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-carta-de-verificacion-de-defuncion"
        ) !== -1
      ) {
        history.push("/ovra/order-death-verification-letter");
      }

      //  DIVORCE VERIFICATION ES
      else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-carta-de-verificacion-de-divorcio/informacion-de-verificacion-de-defuncion"
        ) !== -1
      ) {
        history.push(
          "/ovra/order-divorce-verification-letter/divorce-verification-information"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-carta-de-verificacion-de-divorcio/verificar-identidad-en-estado"
        ) !== -1
      ) {
        history.push(
          "/ovra/order-divorce-verification-letter/verify-identity-in-state"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-carta-de-verificacion-de-divorcio/verificar-identidad-fuera-de-estado"
        ) !== -1
      ) {
        history.push(
          "/ovra/order-divorce-verification-letter/verify-identity-out-of-state"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-carta-de-verificacion-de-divorcio"
        ) !== -1
      ) {
        history.push("/ovra/order-divorce-verification-letter");
      }

      // BIRTH VERIFICATION ES
      else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-carta-de-verificacion-de-nacimiento/informacion-de-verificacion-de-nacimiento"
        ) !== -1
      ) {
        history.push(
          "/ovra/order-birth-verification-letter/birth-verification-information"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-carta-de-verificacion-de-nacimiento/verificar-identidad-en-estado"
        ) !== -1
      ) {
        history.push(
          "/ovra/order-birth-verification-letter/verify-identity-in-state"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-carta-de-verificacion-de-nacimiento/verificar-identidad-fuera-de-estado"
        ) !== -1
      ) {
        history.push(
          "/ovra/order-birth-verification-letter/verify-identity-out-of-state"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-carta-de-verificacion-de-nacimiento"
        ) !== -1
      ) {
        history.push("/ovra/order-birth-verification-letter");
      }

      // BIRTH CERTIFICATE ES
      else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-certificado-de-nacimiento/informacion-del-certificado-de-nacimiento"
        ) !== -1
      ) {
        history.push(
          "/ovra/order-birth-certificate/birth-certificate-information"
        );
      } else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-certificado-de-nacimiento/verificar-identidad-en-estado"
        ) !== -1
      ) {
        history.push("/ovra/order-birth-certificate/verify-identity-in-state");
      } else if (
        currentUrl.indexOf(
          "/ovra/es/solicitar-certificado-de-nacimiento/verificar-identidad-fuera-de-estado"
        ) !== -1
      ) {
        history.push(
          "/ovra/order-birth-certificate/verify-identity-out-of-state"
        );
      } else if (
        currentUrl.indexOf("/ovra/es/solicitar-certificado-de-nacimiento") !==
        -1
      ) {
        history.push("/ovra/order-birth-certificate");
      }

      // SELECT VITAL RECORD STATUS ES
      else if (
        currentUrl.indexOf(
          "/ovra/es/estado-del-pedido-de-registros-vitales/"
        ) !== -1
      ) {
        history.push("/ovra/vital-record-order-status/");
      }

      // SELECT VITAL RECORD ES
      else if (
        currentUrl.indexOf(
          "/ovra/es/seleccionar-un-registro-vital-para-solicitar"
        ) !== -1
      ) {
        history.push("/ovra/select-vital-record-order");
      }

      //OrderReview EN
      else if (currentUrl.indexOf("/ovra/es/revision-del-pedido") !== -1) {
        history.push("/ovra/order-review");
      }
      //Shipping Information EN
      else if (currentUrl.indexOf("/ovra/es/informacion-de-envio") !== -1) {
        history.push("/ovra/shipping-info");
      }
      //Payment EN
      else if (currentUrl.indexOf("/ovra/es/informacion-de-pago") !== -1) {
        history.push("/ovra/payment-info");
      }
      //OrderReceipt EN
      else if (currentUrl.indexOf("/ovra/es/recibo-de-pedido") !== -1) {
        history.push("/ovra/order-receipt");
      }
    }
  };
  return (
    <React.Fragment>
      <Navbar className="header-background">
        <Container>
          <Col className="header-1" sm={8} md={8} xs={8}>
            <Image className="logo-dshs" src={logo} alt="DSHS Logo" />
          </Col>
          <Col sm={4} md={4} xs={4} className="lang-selector">
            <Link
              className={
                i18n.language === "en"
                  ? "translate-links-active"
                  : "translate-links"
              }
              onClick={() => {
                handleClick("en");
              }}
            >
              ENG
            </Link>
            |{" "}
            <Link
              className={
                i18n.language === "es"
                  ? "translate-links-active"
                  : "translate-links"
              }
              onClick={() => {
                handleClick("es");
              }}
            >
              ESP
            </Link>
          </Col>
        </Container>
      </Navbar>

      <div className="header-bluestrip"></div>
      <div className="header-redstrip"></div>

      <Container className="common-subheading">
        {" "}
        <p className="sub-heading">
          Texas Department of State Health Services
          <br />
          <strong>{t("COMMONS.ORDER_RECORDS")}</strong>
        </p>
      </Container>
    </React.Fragment>
  );
}

export default HeaderSub;
