import PostCall from "./CommonApi";

const ValidateZipCode = (props) => {
    let url = "/v1/ovra/util/validate/ShippingInfo";
    let requestObject = props;
    return PostCall(url,requestObject);
};

export default ValidateZipCode;


