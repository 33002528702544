import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "./BirthVerification.scss";
import useGlobal from "../../../../store";
import { FormInput, FormInputOptional } from "../../FormInput";
import GenderList from "../../CommonList";
import { NumberField } from "../../SelectField";
import Select from "react-select";
import history from "./../../history";
import Progress from "../../Progress";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import DateFormatter from "./../../../common/Date";
import { IDGenerator } from "../../../common/CommonList";
import { isMobileOnly } from "react-device-detect";

function BirthVerification(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const [showParentChecked, setShowParentChecked] = useState(false);

  const SignupSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(t("COMMON_ERROR_MSG.FIRST_NAME_MISSING_ERROR"))
      .matches(
        /(?=^.{1,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("COMMON_ERROR_MSG.FIRST_NAME_INVALID_ENTRY")
      ),
    middleName: yup
      .string()
      .notRequired()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.MIDDLE_NAME_INVALID_ENTRY")
      ),
    lastName: yup
      .string()
      .required(t("COMMON_ERROR_MSG.LAST_NAME_MISSING_ERROR"))
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("COMMON_ERROR_MSG.LAST_NAME_INVALID_ENTRY")
      ),
    selectedGender: yup.object().shape({
      label: yup.string().required(t("COMMON_ERROR_MSG.GENDER_ERROR")),
      value: yup.string().required(t("COMMON_ERROR_MSG.GENDER_ERROR")),
    }),
    cityOfBirth: yup
      .string()
      .required(t("BIRTH_VERIFICATION_INFO_ERROR_MSG.CITY_OF_BIRTH"))
      .matches(/^[a-zA-Z ]*$/, t("COMMON_ERROR_MSG.CITY_INVALID_ENTRY")),
    countyOfBirth: yup.object().shape({
      label: yup.string().required(t("BIRTH_VERIFICATION_INFO.COUNTY_BIRTH")),
      value: yup.string().required(t("BIRTH_VERIFICATION_INFO.COUNTY_BIRTH")),
    }),
    parent1FirstName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.FIRST_NAME_INVALID_ENTRY")
      ),
    parent1MiddleName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.MIDDLE_NAME_INVALID_ENTRY")
      ),
    parent1LastName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.LAST_NAME_INVALID_ENTRY")
      ),
    parent2FirstName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.FIRST_NAME_INVALID_ENTRY")
      ),
    parent2MiddleName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.MIDDLE_NAME_INVALID_ENTRY")
      ),
    parent2LastName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.LAST_NAME_INVALID_ENTRY")
      ),
    noOfCopies: yup
      .number()
      .typeError(t("VALIDATION_COMMON.COPIES_LIMIT_MIN"))
      .required(t("VALIDATION_COMMON.COPIES_LIMIT_MIN"))
      .moreThan(0, t("COMMON_ERROR_MSG.NO_OF_COPIES"))
      .lessThan(11, t("VALIDATION_COMMON.COPIES_LIMIT_MAX")),
  });

  const { register, handleSubmit, control, errors } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(SignupSchema),
  });

  if (props.editIndex !== "") {
    if (
      (globalState.birthVerificationInfo.lastName !== undefined ||
        globalState.birthVerificationInfo.lastName !== "") &&
      (globalState.birthVerificationInfo.firstName !== undefined ||
        globalState.birthVerificationInfo.firstName !== "")
    ) {
      globalState.birthVerificationInfo =
        globalState.totalOrderRecords[props.editIndex];
      //setShowParentChecked(globalState.deathVerificationInfo.showParentTwo);
    }
  }

  globalState.birthVerificationInfo.dateOfBirth = globalState
    .getStartedBirthVerificationInfo.dateOfBirth
    ? DateFormatter(
        globalState.getStartedBirthVerificationInfo.dateOfBirth,
        "mm/dd/yyyy"
      )
    : "";

  const countyList = props.countyList;

  const onSubmit = (data) => {
    data.certificateInfoType = "birthVerification";
    data.uid = IDGenerator();
    data.selectedState =
      globalState.getStartedBirthVerificationInfo.selectedState;
    globalActions.birthVerificationInfo(data, props.editIndex);
    history.push(t("URLS.ORDER_REVIEW"));
  };

  const addAnotherRecord = (data) => {
    data.certificateInfoType = "birthVerification";
    data.uid = IDGenerator();
    data.selectedState =
      globalState.getStartedBirthVerificationInfo.selectedState;
    globalActions.birthVerificationInfo(data, props.editIndex);
    window.scrollTo(0, 0);
    history.push(t("URLS.SELECT_VITAL_RECORD"));
  };

  return (
    <div>
      <Progress currentStep={2} totalSteps={4} />
      <h1 className="birthverification-heading">
        {t("BIRTH_VERIFICATION_INFO.HEADING")}
      </h1>
      <h2 className="birthverification-parent">
        {t("BIRTH_VERIFICATION_INFO.BIRTH_VERIFICATIONINFO")}
      </h2>
      <div>
        <form
          className="verify-content-section"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="verifyinformation-div">
            <FormInput
              id="firstName"
              name="firstName"
              type="text"
              label={t("BIRTH_VERIFICATION_INFO.FIRST_NAME")}
              register={register}
              className={`birth-info-input answer ${
                errors.firstName ? "error-red" : ""
              }`}
              defaultValue={
                globalState.birthVerificationInfo.firstName
                  ? globalState.birthVerificationInfo.firstName
                  : ""
              }
              error={errors.firstName}
            />
            <FormInputOptional
              id="middleName"
              name="middleName"
              type="text"
              label={t("BIRTH_VERIFICATION_INFO.MIDDLE_NAME")}
              labelOptional={t("COMMONS.OPTIONAL")}
              register={register}
              className={`birth-info-input answer ${
                errors.middleName ? "error-red" : ""
              }`}
              defaultValue={
                globalState.birthVerificationInfo.middleName
                  ? globalState.birthVerificationInfo.middleName
                  : ""
              }
              error={errors.middleName}
            />
            <FormInput
              id="lastName"
              name="lastName"
              type="text"
              label={t("BIRTH_VERIFICATION_INFO.LAST_NAME")}
              register={register}
              className={`birth-info-input answer ${
                errors.lastName ? "error-red" : ""
              }`}
              defaultValue={
                globalState.birthVerificationInfo.lastName
                  ? globalState.birthVerificationInfo.lastName
                  : ""
              }
              error={errors.lastName}
            />

            <FormInput
              id="dateOfBirth"
              name="dateOfBirth"
              type="text"
              label={t("BIRTH_VERIFICATION_INFO.DATE_ON_CERTIFICATE")}
              className={"birth-info-input answer"}
              register={register}
              disabled={true}
              defaultValue={globalState.birthVerificationInfo.dateOfBirth}
            />

            <div className="info-input-align">
              <label className="birth-info-name">
                {t("BIRTH_VERIFICATION_INFO.GENDER")}
              </label>
              <Controller
                name="selectedGender"
                className={`field-label-padding ${
                  errors.selectedGender ? "error-red" : ""
                }`}
                as={Select}
                options={GenderList()}
                control={control}
                isClearable
                defaultValue={
                  globalState.birthVerificationInfo.selectedGender.label
                    ? globalState.birthVerificationInfo.selectedGender
                    : ""
                }
                rules={{ required: true }}
              />
              {errors.selectedGender && (
                <p className="error-red-content">
                  {t("COMMON_ERROR_MSG.GENDER_ERROR")}
                </p>
              )}
            </div>

            <FormInput
              id="cityOfBirth"
              name="cityOfBirth"
              type="text"
              label={t("BIRTH_VERIFICATION_INFO.CITY_BIRTH")}
              register={register}
              className={`birth-info-input answer ${
                errors.cityOfBirth ? "error-red" : ""
              }`}
              defaultValue={
                globalState.birthVerificationInfo.cityOfBirth
                  ? globalState.birthVerificationInfo.cityOfBirth
                  : ""
              }
              error={errors.cityOfBirth}
            />

            <label className="birth-info-name">
              {t("BIRTH_VERIFICATION_INFO.COUNTY_BIRTH")}
            </label>
            <div className="info-input-align">
              <Controller
                name="countyOfBirth"
                className={`field-label-padding ${
                  errors.countyOfBirth ? "error-red" : ""
                }`}
                as={Select}
                options={countyList}
                control={control}
                isClearable
                defaultValue={
                  globalState.birthVerificationInfo.countyOfBirth.label
                    ? globalState.birthVerificationInfo.countyOfBirth
                    : ""
                }
                rules={{ required: true }}
              />
              {errors.countyOfBirth && (
                <p className="error-red-content">
                  {t("COMMON_ERROR_MSG.COUNTY_ERROR")}
                </p>
              )}
            </div>

            <div className="parent-both-info-padding">
              <h2 className="birth-personal-info">
                {t("BIRTH_VERIFICATION_INFO.PARENTS_HEADING")}
                <span className="optional-text">{t("COMMONS.OPTIONAL")}</span>
              </h2>
              <FormInput
                id="parent1FirstName"
                name="parent1FirstName"
                type="text"
                label={t("BIRTH_VERIFICATION_INFO.PARENT1_FIRST_NAME")}
                register={register}
                className={`birth-info-input answer ${
                  errors.parent1FirstName ? "error-red" : ""
                }`}
                defaultValue={
                  globalState.birthVerificationInfo.parent1FirstName
                    ? globalState.birthVerificationInfo.parent1FirstName
                    : ""
                }
                error={errors.parent1FirstName}
              />
              <FormInputOptional
                id="parent1MiddleName"
                name="parent1MiddleName"
                type="text"
                label={t("BIRTH_VERIFICATION_INFO.PARENT1_MIDDLE_NAME")}
                className={`birth-info-input answer ${
                  errors.parent1MiddleName ? "error-red" : ""
                }`}
                register={register}
                defaultValue={
                  globalState.birthVerificationInfo.parent1MiddleName
                    ? globalState.birthVerificationInfo.parent1MiddleName
                    : ""
                }
                error={errors.parent1MiddleName}
              />
              <FormInput
                id="parent1LastName"
                name="parent1LastName"
                type="text"
                label={t("BIRTH_VERIFICATION_INFO.PARENT1_LAST_NAME")}
                register={register}
                className={`birth-info-input answer ${
                  errors.parent1LastName ? "error-red" : ""
                }`}
                defaultValue={
                  globalState.birthVerificationInfo.parent1LastName
                    ? globalState.birthVerificationInfo.parent1LastName
                    : ""
                }
                error={errors.parent1LastName}
              />
            </div>
            <div className="form-checkbox-default">
              <input
                type="checkbox"
                onChange={() => setShowParentChecked(!showParentChecked)}
                className="checkbox"
                id="parenttwoshow"
                name="showParentTwo"
                ref={register}
                checked={globalState.birthVerificationInfo.showParentTwo}
              />
              <label htmlFor="parenttwoshow" className="checkbox-parent">
                {t("BIRTH_VERIFICATION_INFO.PARENT_CHECKBOX")}
              </label>
            </div>
            {!showParentChecked && (
              <>
                <FormInput
                  id="parent2FirstName"
                  name="parent2FirstName"
                  type="text"
                  label={t("BIRTH_VERIFICATION_INFO.PARENT2_FIRST_NAME")}
                  register={register}
                  className={`birth-info-input answer ${
                    errors.parent2FirstName ? "error-red" : ""
                  }`}
                  defaultValue={
                    globalState.birthVerificationInfo.parent2FirstName
                      ? globalState.birthVerificationInfo.parent2FirstName
                      : ""
                  }
                  error={errors.parent2FirstName}
                />
                <FormInputOptional
                  id="parent2MiddleName"
                  name="parent2MiddleName"
                  type="text"
                  label={t("BIRTH_VERIFICATION_INFO.PARENT2_MIDDLE_NAME")}
                  register={register}
                  className={`birth-info-input answer ${
                    errors.parent2MiddleName ? "error-red" : ""
                  }`}
                  defaultValue={
                    globalState.birthVerificationInfo.parent2MiddleName
                      ? globalState.birthVerificationInfo.parent2MiddleName
                      : ""
                  }
                  error={errors.parent2MiddleName}
                />
                <FormInput
                  id="parent2LastName"
                  name="parent2LastName"
                  type="text"
                  label={t("BIRTH_VERIFICATION_INFO.PARENT2_LAST_NAME")}
                  register={register}
                  className={`birth-info-input answer ${
                    errors.parent2LastName ? "error-red" : ""
                  }`}
                  defaultValue={
                    globalState.birthVerificationInfo.parent2LastName
                      ? globalState.birthVerificationInfo.parent2LastName
                      : ""
                  }
                  error={errors.parent2LastName}
                />
              </>
            )}
            <h2 className="birth-verification-info">
              {t("BIRTH_VERIFICATION_INFO.RECORD_AMOUNT")}
            </h2>
            <NumberField
              id="noOfCopies"
              name="noOfCopies"
              type="number"
              register={register}
              label={t("BIRTH_VERIFICATION_INFO.COPIES")}
              defaultValue={
                globalState.birthVerificationInfo.noOfCopies
                  ? globalState.birthVerificationInfo.noOfCopies
                  : ""
              }
              error={errors.noOfCopies}
            />
            <div className="option-container">
              <Row className="btn-birth-info" sm={1} xs={1} md={2} lg={2}>
                <Col>
                  <Button
                    variant="outline-primary"
                    className="back-button-birth-info"
                    onClick={handleSubmit(addAnotherRecord)}
                  >
                    {t("COMMONS.ADD_RECORD")}
                  </Button>
                </Col>
                <Col xs={{ order: isMobileOnly ? "first" : "last" }}>
                  <Button
                    variant="primary"
                    className="next-button"
                    type="submit"
                  >
                    {t("COMMONS.NEXT")}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default BirthVerification;
