import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col } from "react-bootstrap";
import Progress from "../../Progress";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { FormInput } from "../../../common/FormInput";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import GetApiUrl from "../../../../../src/components/common/GetApiUrl";
import "./VerifyIdentity.scss";
import useGlobal from "../../../../store";
import alert from "../../../../assets/alert-warning@1x.png";
import close from "../../../../assets/close.svg";
import history from "../../../common/history";
import { BindState } from "./VerifyIdentityHelper";
import { trackPromise } from "react-promise-tracker";
import { Spinner } from "../../../common/spinner";
import getSitekey from "../../GetSiteKey";
import { Error } from "../../../common/index";
import MaskedInput from "react-maskedinput";
import { isMobile, isMobileOnly } from "react-device-detect";

function VerifyIdentityOutOfState(props) {
  const stateList = props.stateList;
  const [showError, setShowError] = useState(false);
  const [showServiceDownError, setShowServiceDownError] = useState(false);
  const [selectedState, setSelectedState] = useState({
    label: "",
    value: "",
  });
  const { t } = useTranslation();

  const SignupSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(t("VERIFY_IDENTITY_ERROR_MSG.FIRST_NAME"))
      .matches(
        /(?=^.{1,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("VERIFY_IDENTITY_ERROR_MSG.FIRST_NAME_SP")
      ),
    lastName: yup
      .string()
      .required(t("VERIFY_IDENTITY_ERROR_MSG.LAST_NAME"))
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("VERIFY_IDENTITY_ERROR_MSG.LAST_NAME_SP")
      ),
    middleName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("VERIFY_IDENTITY_ERROR_MSG.MIDDLE_NAME_SP")
      ),
    dateOfBirth: yup
      .date()
      .required(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE"))
      .typeError(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE")),
    socialSecNumber: yup
      .string()
      .required(t("VERIFY_IDENTITY_ERROR_MSG.SOCIAL_SEC_NUMBER_SP"))
      .matches(
        /^(?!(000|666|9))\d{3}?(?!(00))\d{2}?(?!(0000))\d{4}$/,
        t("VERIFY_IDENTITY_ERROR_MSG.SOCIAL_SEC_NUMBER_SP")
      ),
    licenseNumber: yup
      .string()
      .required(t("VERIFY_IDENTITY_ERROR_MSG.OUT_OF_STATE_DL_ID"))
      .min(6, t("VERIFY_IDENTITY_ERROR_MSG.OUT_OF_STATE_DL_ID"))
      .max(25, t("VERIFY_IDENTITY_ERROR_MSG.OUT_OF_STATE_DL_ID"))
      .matches(
        /^[a-zA-Z0-9- ]*[a-zA-Z0-9]*$/,
        t("VERIFY_IDENTITY_ERROR_MSG.OUT_OF_STATE_DL_ID")
      ),
    city: yup
      .string()
      .required(t("VERIFY_IDENTITY_ERROR_MSG.CITY"))
      .matches(/^[a-zA-Z ]*$/, t("VERIFY_IDENTITY_ERROR_MSG.CITY_SP")),
    streetAddress1: yup
      .string()
      .required(t("VERIFY_IDENTITY_ERROR_MSG.STREET_ADDRESS1")),
    zipCode: yup
      .string()
      .required(t("VERIFY_IDENTITY_ERROR_MSG.ZIP_CODE"))
      .min(5)
      .max(5)
      .matches(
        /^[_0-9]*((-|\s)*[_0-9])*$/,
        t("VERIFY_IDENTITY_ERROR_MSG.ZIP_CODE_SP")
      ),

    // a: Yup.string(),
    // b: Yup.string().test("b-check", "B is required", checkBCD),
    // c: Yup.string().test("c-check", "C is required", checkBCD),
    // d: Yup.string().test("d-check", "D is required", checkBCD),
    // previousSelectedState: yup.object().shape({
    //   label: yup.string().default(),

    //   value: yup.string().default(),
    // }),
    previousCity: yup
      .string()
      .matches(
        /^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/,
        t("VERIFY_IDENTITY_ERROR_MSG.CITY_SP")
      ),
    previousZip: yup
      .string()
      // .min(5)
      .max(5)
      .matches(
        /^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/,
        t("VERIFY_IDENTITY_ERROR_MSG.ZIP_CODE_SP")
      ),
  });

  const siteKey = getSitekey();
  const actionName = "outState";

  const suffixNameList = [
    { value: "Jr.s", label: "Jr." },
    { value: "Sr.", label: "Sr." },
    { value: "II", label: "II" },
    { value: "III", label: "III" },
    { value: "IV", label: "IV" },
  ];

  const { register, handleSubmit, control, errors, setValue } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(SignupSchema),
  });

  const [globalState, globalActions] = useGlobal();
 //DMND0011685 - CaptchaToken Generation and form submission
 const generateCaptchaTokenAndSubmitForm = async (data) =>{
  
  window.grecaptcha.enterprise.ready(() => {
    window.grecaptcha.enterprise
      .execute(siteKey, { action: 'submit' })
      .then((token) => {
        data.token=token;
        onSubmit(data);
      })
      .catch((err) => {
        
      });
  });
}
//DMND0011685 - ------------End-----------------------------

  const onSubmit = async (data) => {
    var dateFormatter = new Date(data.dateOfBirth);

    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
      dateFormatter
    );
    let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
      dateFormatter
    );
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
      dateFormatter
    );
    da = da.replace(/\u200e/g, "");
    mo = mo.replace(/\u200e/g, "");
    ye = ye.replace(/\u200e/g, "");
    data.dayOfBirth = da;
    data.monthOfBirth = mo;
    data.yearOfBirth = ye;
    data.selectedState = selectedState;
    globalActions.addVerifyIdentity(data);
    getIdology(data.token);
  };

  useEffect(() => {
    var index = props.flowIndex;
    setSelectedState(BindState(index, globalState));
    if (
      globalState.getStartedBirthCertificateData.selectedRelation !==
      undefined &&
      globalState.getStartedBirthCertificateData.selectedRelation.label ===
      "Self" &&
      globalState.getStartedBirthCertificateData.dateOfBirth !== undefined
    ) {
      setValue(
        "dateOfBirth",
        globalState.getStartedBirthCertificateData.dateOfBirth
      );
    }
  }, []);

  const getIdology = (token) => {
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    trackPromise(
      axios
        .post(GetApiUrl() + "/v1/tcas/ovra/identityverification", {
          applicationId: "OVRA",
          agencyId: "DSHS",
          gaClientId: localStorage.getItem('gaClientId'),
          uniqueIdentifier: globalState.uniqueIdentifier,
          token: token,
          firstName: globalState.verifyIdentityData.firstName,
          lastName: globalState.verifyIdentityData.lastName,
          ssn: globalState.verifyIdentityData.socialSecNumber,
          dobMonth: globalState.verifyIdentityData.monthOfBirth,
          dobDay: globalState.verifyIdentityData.dayOfBirth,
          dobYear: globalState.verifyIdentityData.yearOfBirth,
          currentAddress: {
            street:
              globalState.verifyIdentityData.streetAddress1 +
              "" +
              globalState.verifyIdentityData.streetAddress2,
            city: globalState.verifyIdentityData.city,
            state: selectedState.value,
            zip: globalState.verifyIdentityData.zipCode,
          },
          formerAddress: {
            street:
              globalState.verifyIdentityData.previousStreetAddress1 +
              " " +
              globalState.verifyIdentityData.streetAddress2,
            city: globalState.verifyIdentityData.previousCity,
            state: globalState.verifyIdentityData.previousSelectedState
              ? globalState.verifyIdentityData.previousSelectedState.value
              : "",
            zip: globalState.verifyIdentityData.previousZipCode,
          },
        })
        .then(function (response) {
          globalActions.addJwtToken(response.data.jwtToken);
          sessionStorage.setItem("jwtToken", response.data.jwtToken);
          globalState.verifyIdentityData.driverLicenseMatches = null;
          if (response.data.summaryResult === "PASS") {
            globalState.verifyIdentityData.ssnMatches = response.data.ssnMatches
              ? "Y"
              : "N";
            globalState.verifyIdentityData.deathVerificationMatches = response
              .data.subjectIsDeceased
              ? "Y"
              : "N";
            globalState.verifyIdentityData.addressLastNameMatches = response
              .data.lastNameMatches
              ? "Y"
              : "N";
            globalState.verifyIdentityData.addressZipCodeMatches = response.data
              .zipMatches
              ? "Y"
              : "N";
            globalState.verifyIdentityData.ssnIssueDateMatches = response.data
              .ssnIssueDateAlert
              ? "Y"
              : "N";
            globalState.verifyIdentityData.idVerified = "Y";
            props.nextCallback();
          } else {
            globalState.verifyIdentityData.driverLicenseMatches = null;
            globalState.verifyIdentityData.ssnMatches = null;
            globalState.verifyIdentityData.deathVerificationMatches = null;
            globalState.verifyIdentityData.addressLastNameMatches = null;
            globalState.verifyIdentityData.addressZipCodeMatches = null;
            globalState.verifyIdentityData.ssnIssueDateMatches = null;
            globalState.verifyIdentityData.idVerified = "N";
            if (response.data.errorCode === "111") {
              window.scrollTo(0, 0);
              history.push(t("URLS.LOCKOUT_PAGE"));
            } else {
              setShowError(true);
            }
          }
        })
    ).catch(function (error) {
      console.log("Do failure action", error);
      setShowError(true);
      setShowServiceDownError(true);
    });
  };

  return (
    <>
      {!showServiceDownError && (
        <div>
          <Progress currentStep={1} totalSteps={4} />
          {/* Global data : {JSON.stringify(globalState)}
      {showError && (<span>Show error</span>)} */}
          <form
            className="verify-content-section"
            onSubmit={handleSubmit(generateCaptchaTokenAndSubmitForm)}
          >
            <div>
              <h1 className="verify-heading">
                {t("VERIFY_IDENTITY_INFORMATION.HEADING")}
              </h1>
              <h2 className="verify-description">
                {t("VERIFY_IDENTITY_INFORMATION.DESCRIPTION")}
              </h2>
            </div>
            <div className="personal-information-div">
              <h2 className="personal-information">
                {t("VERIFY_IDENTITY_INFORMATION.PERSONAL_INFORMATION")}
              </h2>
              <label htmlFor="firstName" className="name">
                {t("VERIFY_IDENTITY_INFORMATION.FIRST_NAME")}
              </label>
              <div className="input-align">
                <input
                  className={`answer ${errors.firstName ? "error-red" : ""}`}
                  type="text"
                  name="firstName"
                  id="firstName"
                  ref={register}
                  rules={{ required: true }}
                  defaultValue={
                    globalState.verifyIdentityData.firstName
                      ? globalState.verifyIdentityData.firstName
                      : ""
                  }
                />
                {errors.firstName && (
                  <p className="error-red-content">
                    {errors.firstName.message}
                  </p>
                )}
              </div>

              <label htmlFor="middleName" className="name">
                {t("VERIFY_IDENTITY_INFORMATION.MIDDLE_NAME")}
                <span className="optional-text">{t("COMMONS.OPTIONAL")}</span>
              </label>
              <div className="input-align">
                <input
                  className={`answer ${errors.middleName ? "error-red" : ""}`}
                  type="text"
                  name="middleName"
                  id="middleName"
                  ref={register}
                  defaultValue={
                    globalState.verifyIdentityData.middleName
                      ? globalState.verifyIdentityData.middleName
                      : ""
                  }
                />
                {errors.middleName && (
                  <p className="error-red-content">
                    {errors.middleName.message}
                  </p>
                )}
              </div>

              <label htmlFor="lastName" className="name">
                {t("VERIFY_IDENTITY_INFORMATION.LAST_NAME")}
              </label>
              <div className="input-align">
                <input
                  className={`answer ${errors.lastName ? "error-red" : ""}`}
                  type="text"
                  name="lastName"
                  id="lastName"
                  ref={register}
                  rules={{ required: true }}
                  defaultValue={
                    globalState.verifyIdentityData.lastName
                      ? globalState.verifyIdentityData.lastName
                      : ""
                  }
                />
                {errors.lastName && (
                  <p className="error-red-content">{errors.lastName.message}</p>
                )}
              </div>

              <div className="select-align">
                <label className="name">
                  {t("VERIFY_IDENTITY_OOS_INFORMATION.SUFFIX_NAME")}
                  <span className="optional-text">{t("COMMONS.OPTIONAL")}</span>
                  <Controller
                    name="selectedSuffix"
                    className={`get-state-birth ${errors.selectedSuffix ? "error-red" : ""
                      }`}
                    as={Select}
                    options={suffixNameList}
                    control={control}
                  />
                  {errors.selectedSuffix && (
                    <p className="error-red-content">
                      {errors.selectedSuffix.message}
                    </p>
                  )}
                </label>
              </div>
              <label htmlFor="dateOfBirth" className="name">
                {t("VERIFY_IDENTITY_INFORMATION.DATE_BIRTH")}
              </label>
              <div className="DoB-date">
                <Controller
                  control={control}
                  name="dateOfBirth"
                  className="input-date"
                  defaultValue={globalState.verifyIdentityData.dateOfBirth}
                  rules={{ required: true }}
                  render={({ onChange, onBlur, value }) =>
                    globalState.getStartedBirthCertificateData
                      .selectedRelation !== undefined &&
                      globalState.getStartedBirthCertificateData.selectedRelation
                        .label === "Self" ? (
                        <ReactDatePicker
                          disabled
                          disabledKeyboardNavigation
                          className={`input-date get-state-birth`}
                          selected={value}
                          id="dateOfBirth"
                        />
                      ) : (
                        <ReactDatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          id="dateOfBirth"
                          className={`input-date get-state-birth ${errors.dateOfBirth ? "error-red" : ""
                            }`}
                          showMonthDropdown
                          showYearDropdown
                          open={isMobile ? false : undefined}
                          customInput={<MaskedInput mask="11/11/1111" />}
                          placeholderText="00/00/0000"
                        />
                      )
                  }
                />
                {errors.dateOfBirth && (
                  <p className="error-red-content">
                    {errors.dateOfBirth.message}
                  </p>
                )}
              </div>

              <label htmlFor="socialSecNumber" className="name">
                {t("VERIFY_IDENTITY_INFORMATION.SSN")}</label>
              <div className="input-align">
                <input
                  className={`answer ${errors.socialSecNumber ? "error-red" : ""
                    }`}
                  type="number"
                  name="socialSecNumber"
                  id="socialSecNumber"
                  ref={register}
                  rules={{ required: true }}
                  defaultValue={
                    globalState.verifyIdentityData.socialSecNumber
                      ? globalState.verifyIdentityData.socialSecNumber
                      : ""
                  }
                />
                {errors.socialSecNumber && (
                  <p className="error-red-content">
                    {errors.socialSecNumber.message}
                  </p>
                )}
              </div>
              <div className="input-align">
                <label htmlFor="licenseNumber" className="name">
                  {t("VERIFY_IDENTITY_INFORMATION.LICENSE_NUMBER")}
                </label>
                <input
                  className={`answer ${errors.licenseNumber ? "error-red" : ""
                    }`}
                  id="licenseNumber"
                  type="text"
                  name="licenseNumber"
                  ref={register}
                  rules={{ required: true }}
                  defaultValue={
                    globalState.verifyIdentityData.licenseNumber
                      ? globalState.verifyIdentityData.licenseNumber
                      : ""
                  }
                />
                {errors.licenseNumber && (
                  <p className="error-red-content">
                    {errors.licenseNumber.message}
                  </p>
                )}
              </div>
            </div>
            <div className="new-address-copy">
              <h1 className="texas-driver-license">
                {t("VERIFY_IDENTITY_INFORMATION.CURRENT_ADDRESS")}
              </h1>
              <label htmlFor="streetAddress1" className="name">
                {t("VERIFY_IDENTITY_INFORMATION.STREET_ADDRESS")}
              </label>

              <div>
                <input
                  className={`answer ${errors.streetAddress1 ? "error-red" : ""
                    }`}
                  type="text"
                  name="streetAddress1"
                  id="streetAddress1"
                  ref={register}
                  rules={{ required: true }}
                  defaultValue={
                    globalState.verifyIdentityData.streetAddress1
                      ? globalState.verifyIdentityData.streetAddress1
                      : ""
                  }
                />
                {errors.streetAddress1 && (
                  <p className="error-red-content">
                    {errors.streetAddress1.message}
                  </p>
                )}

                <input
                  className="bg bg1 answer"
                  type="text"
                  name="streetAddress2"
                  id="streetAddress1"
                  ref={register}
                  rules={{ required: true }}
                  defaultValue={
                    globalState.verifyIdentityData.streetAddress2
                      ? globalState.verifyIdentityData.streetAddress2
                      : ""
                  }
                />

                {errors.streetAddress2 && (
                  <p className="error-red-content">
                    {errors.streetAddress2.message}
                  </p>
                )}
              </div>
              <div className="text-field-city">
                <label htmlFor="city" className="name">{t("VERIFY_IDENTITY_INFORMATION.CITY")}</label>
                <input
                  className={`answer ${errors.city ? "error-red" : ""}`}
                  type="text"
                  name="city"
                  id="city"
                  ref={register}
                  rules={{ required: true }}
                  defaultValue={
                    globalState.verifyIdentityData.city
                      ? globalState.verifyIdentityData.city
                      : ""
                  }
                />
                {errors.city && (
                  <p className="error-red-content">{errors.city.message}</p>
                )}
              </div>
              <FormInput
                id="selectedState"
                name="selectedState"
                className="answer"
                control={control}
                label={t("VERIFY_IDENTITY_INFORMATION.STATE")}
                disabled={true}
                type="text"
                defaultValue={selectedState.label}
              />

              <div className="text-field-zip">
                <label htmlFor="zipCode" className="name">
                  {t("VERIFY_IDENTITY_INFORMATION.ZIP")}</label>
                <input
                  className={`bgZip answer ${errors.zipCode ? "error-red" : ""
                    }`}
                  type="text"
                  name="zipCode"
                  id="zipCode"
                  ref={register}
                  rules={{ required: true }}
                  defaultValue={
                    globalState.verifyIdentityData.zipCode
                      ? globalState.verifyIdentityData.zipCode
                      : ""
                  }
                />
                {errors.zipCode && (
                  <p className="error-red-content">{errors.zipCode.message}</p>
                )}
              </div>
            </div>

            <h1 className="texas-driver-license">
              {t("VERIFY_IDENTITY_INFORMATION.PREVIOUS_ADDRESS")}
            </h1>
            <p className="texas-dl-description">
              {t("VERIFY_IDENTITY_INFORMATION.PREVIOUS_ADDRESS_MSG")}
            </p>
            <div className="cc-form">
              <div className="text-field">
                <label htmlFor="previousStreetAddress1" className="name">
                  {t("VERIFY_IDENTITY_INFORMATION.STREET_ADDRESS")}
                </label>
                <div>
                  <input
                    className={`answer ${errors.previousStreetAddress1 ? "error-red" : ""
                      }`}
                    type="text"
                    name="previousStreetAddress1"
                    id="previousStreetAddress1"
                    ref={register}
                    defaultValue={
                      globalState.verifyIdentityData.previousStreetAddress1
                        ? globalState.verifyIdentityData.previousStreetAddress1
                        : ""
                    }
                  />
                  {errors.previousStreetAddress1 && (
                    <p className="error-red-content">
                      {errors.previousStreetAddress1.message}
                    </p>
                  )}
                  <input
                    className="bg bg1 answer"
                    type="text"
                    name="previousStreetAddress2"
                    id="previousStreetAddress1"
                    ref={register}
                    defaultValue={
                      globalState.verifyIdentityData.previousStreetAddress2
                        ? globalState.verifyIdentityData.previousStreetAddress2
                        : ""
                    }
                  />
                  {errors.previousStreetAddress2 && (
                    <p className="error-red-content">
                      {errors.previousStreetAddress2.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="text-field-city">
                <label htmlFor="previousCity" className="name">
                  {t("VERIFY_IDENTITY_INFORMATION.CITY")}</label>
                <div>
                  <input
                    className={`answer ${errors.previousCity ? "error-red" : ""
                      }`}
                    type="text"
                    name="previousCity"
                    id="previousCity"
                    ref={register}
                    defaultValue={
                      globalState.verifyIdentityData.previousCity
                        ? globalState.verifyIdentityData.previousCity
                        : ""
                    }
                  />
                  {errors.previousCity && (
                    <p className="error-red-content">
                      {errors.previousCity.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="info-input-align">
                <label className="name">
                  {t("VERIFY_IDENTITY_INFORMATION.STATE")}
                  <Controller
                    name="previousSelectedState"
                    className={`get-state-birth field-label-padding ${errors.previousSelectedState ? "error-red" : ""
                      }`}
                    as={Select}
                    options={stateList}
                    control={control}
                    defaultValue={
                      globalState.verifyIdentityData.previousSelectedState.label
                        ? globalState.verifyIdentityData.previousSelectedState
                        : ""
                    }
                  />
                  {errors.previousSelectedState && (
                    <p className="error-red-content">
                      {t("VERIFY_IDENTITY_ERROR_MSG.STATE_ERROR")}
                    </p>
                  )}
                </label>
              </div>
              <div className="text-field-previouszip">
                <label htmlFor="previousZipCode" className="name">
                  {t("VERIFY_IDENTITY_INFORMATION.ZIP")}</label>
                <div>
                  <input
                    className={`bgZip answer ${errors.previousZip ? "error-red" : ""}`}
                    type="text"
                    name="previousZipCode"
                    id="previousZipCode"
                    ref={register}
                    defaultValue={
                      globalState.verifyIdentityData.previousZipCode
                        ? globalState.verifyIdentityData.previousZipCode
                        : ""
                    }
                  />
                  {errors.previousZip && (
                    <p className="error-red-content">
                      {errors.previousZip.message}
                    </p>
                  )}
                </div>
              </div>
              {showError && (
                <Row className="verify-error-message">
                  <Col
                    sm={2}
                    xs={2}
                    md={2}
                    lg={2}
                    className="verify-alert-icon"
                  >
                    <img className="verify-alert" src={alert} alt="alert" />
                  </Col>
                  <Col
                    className="verify-error-content"
                    sm={8}
                    xs={8}
                    md={8}
                    lg={8}
                  >
                    <p className="verify-error-text">
                      {t("UNABLE_TO_VERIFY.UNABLE_TO_VERIFY_ERROR")}
                    </p>
                    <p className="verify-error-desc">
                      {t("UNABLE_TO_VERIFY.UNABLE_TO_VERIFY_IDENTITY_ERROR")}
                    </p>
                  </Col>
                  <Col className="verify-close" sm={2} xs={2} md={2} lg={2}>
                    <img
                      src={close}
                      alt="close button"
                      onClick={() => setShowError(false)}
                    />
                  </Col>
                </Row>
              )}

              <Spinner />
              <Row className="btn-group-align" sm={1} xs={1} md={2} lg={2}>
                <Col>
                  <Button
                    variant="outline-primary"
                    className="back-button-verify"
                    onClick={() => props.prevCallback(0)}
                  >
                    {t("COMMONS.BACK")}
                  </Button>
                </Col>
                <Col xs={{ order: isMobileOnly ? "first" : "last" }}>
                  <Button
                    variant="primary"
                    className="next-button-verify"
                    type="submit"
                  >
                    {t("COMMONS.NEXT")}
                  </Button>
                </Col>
              </Row>
            </div>
          </form>
        </div>
      )}
      {showServiceDownError && (
        <Error errorReference={globalState.uniqueIdentifier} />
      )}
    </>
  );
}

export default VerifyIdentityOutOfState;
