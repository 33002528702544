import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./GetStartedBirthVerification.scss";
import useGlobal from "../../../../store";
import history from "../../history";
import Progress from "../../Progress";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

import Button from "react-bootstrap/Button";
import MaskedInput from "react-maskedinput";
import { isMobile, isMobileOnly } from "react-device-detect";

function GetStartedBirthVerification(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();

  const SignupSchema = yup.object().shape({
    dateOfBirth: yup
      .date()
      .required(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE"))
      .min(new Date("1903-01-01"), t("DATE_COMMON_ERRORS.VERIFY_DATE_MIN"))
      .max(new Date(), t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE_MAX"))
      .typeError(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE")),

    validateState: yup.boolean(
      globalState.verifyIdentityData.idVerified === "Y" ? true : false
    ),

    selectedState: yup.object().when("validateState", {
      is: (validateState) => validateState === false,
      then: yup.object().shape({
        label: yup
          .string()
          .required(t("GET_STARTED_BIRTHCERTIFICATE.STATE_ERROR")),

        value: yup
          .string()
          .required(t("GET_STARTED_BIRTHCERTIFICATE.STATE_ERROR")),
      }),
    }),
  });

  const { handleSubmit, control, errors } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(SignupSchema),
  });

  const stateList = props.stateList;

  const onSubmit = (data) => {
    globalActions.getStartedBirthVerificationInfo(data);
    if (
      globalState.verifyIdentityData.idVerified !== undefined &&
      globalState.verifyIdentityData.idVerified === "Y"
    ) {
      props.nextCallback(3);
    } else {
      data.selectedState.value === "TX"
        ? props.nextCallback()
        : props.nextCallback(2);
    }
  };

  return (
    <div>
      <Progress currentStep={1} totalSteps={4} />
      <h1 className="birthverification-heading">
        {t("BIRTH_VERIFICATION.HEADING")}
      </h1>
      <p className="birthverification-content">
        {t("BIRTH_VERIFICATION.DESCRIPTION")}
      </p>
      <div>
        <label className="birthverification-info">
          {t("BIRTH_VERIFICATION.GET_STARTED")}
        </label>
        <label className="birthverification-info1">
          {t("BIRTH_VERIFICATION.GET_STARTED_INFO")}
        </label>
        <form
          className="birth-content-section"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="birthverification-div">
            <div className="birthVerfiyDateRange">
              <div className="option-container">
                {(globalState.verifyIdentityData.idVerified === undefined ||
                  globalState.verifyIdentityData.idVerified === "" ||
                  globalState.verifyIdentityData.idVerified === "N") && (
                  <>
                    <label className="birth-content ">
                      {t("COMMONS.CHOOSE_STATE")}

                      <div className="select-align">
                        <Controller
                          name="selectedState"
                          className={`field-label-padding ${
                            errors.selectedState ? "error-red" : ""
                          }`}
                          as={Select}
                          options={stateList}
                          control={control}
                          isClearable
                          defaultValue={
                            globalState.getStartedBirthVerificationInfo
                              .selectedState.label
                              ? globalState.getStartedBirthVerificationInfo
                                  .selectedState
                              : ""
                          }
                          rules={{ required: true }}
                        />
                        {errors.selectedState && (
                          <p className="error-red-content">
                            {t("GET_STARTED_BIRTHCERTIFICATE.STATE_ERROR")}
                          </p>
                        )}
                      </div>
                    </label>
                  </>
                )}

                <label
                  htmlFor="dateOfBirth"
                  className="birthverification-info1"
                >
                  {t("BIRTH_VERIFICATION.GET_STARTED_TYPE")}
                </label>

                <div className="birth-date">
                  <Controller
                    control={control}
                    name="dateOfBirth"
                    rules={{ required: true }}
                    className="input-date"
                    defaultValue={
                      globalState.getStartedBirthVerificationInfo.dateOfBirth
                        ? globalState.getStartedBirthVerificationInfo
                            .dateOfBirth
                        : ""
                    }
                    render={({ onChange, onBlur, value }) => (
                      <ReactDatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        id="dateOfBirth"
                        className={`input-date get-state-birth ${
                          errors.dateOfBirth ? "error-red" : ""
                        }`}
                        showMonthDropdown
                        showYearDropdown
                        open={isMobile ? false : undefined}
                        customInput={<MaskedInput mask="11/11/1111" />}
                        placeholderText="00/00/0000"
                      />
                    )}
                  />
                  {errors.dateOfBirth && (
                    <p className="error-red-content">
                      {errors.dateOfBirth.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="option-container">
                <Row className="btn-group-align" sm={1} xs={1} md={2} lg={2}>
                  <Col>
                    <Link
                      className="birthverify-back-button button-secondary"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        history.push(t("URLS.SELECT_VITAL_RECORD"));
                      }}
                    >
                      {t("COMMONS.BACK")}
                    </Link>
                  </Col>
                  <Col xs={{ order: isMobileOnly ? "first" : "last" }}>
                    <Button
                      variant="primary"
                      className="next-button"
                      type="submit"
                    >
                      {t("COMMONS.NEXT")}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default GetStartedBirthVerification;
