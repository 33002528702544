import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../../components/pages/orderStatus/OrderStatus.scss";
import { Helmet } from "react-helmet";
import useGlobal from "../../../store";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";

const OrderFound = (props) => {
  const { t } = useTranslation();
  const [globalState] = useGlobal();
  const [show, setShow] = useState({ showOrderSuspended: false });

  let orderStatusDetails = [];
  orderStatusDetails = globalState.orderStatusRemitData;

  useEffect(() => {
    if (
      orderStatusDetails[0].Status.toLowerCase().indexOf("order suspended") > -1
    ) {
      setShow({ showOrderSuspended: true });
    }
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("ORDER_STATUS.TITLE")}</title>
        <meta name="description" content={t("ORDER_STATUS.META_CONTENT")} />
      </Helmet>
      <Container className="status-container">
        <div className="status-border"></div>
        <div className="order-found-padding">
          <h1 className="order-status-heading">{t("ORDER_STATUS.HEADING")}</h1>
          <h2 className="order-found">{t("ORDER_STATUS.INFORMATION")}</h2>
          <div className="order-found-flow">
            <div className="order-number-date order-number">
              <p className="order-status-found-content">
                {t("ORDER_STATUS.ORDER_NUMBER")}
              </p>
              <p className="order-field-detail">
                {orderStatusDetails[0].OrderNumber}
              </p>
            </div>
            <div className="order-number-date order-date">
              <p className="order-status-found-content">
                {t("ORDER_STATUS.INITIAL_ORDER")}
              </p>
              <p className="order-field-detail">
                {orderStatusDetails[0].OrderDate}
              </p>
            </div>

            <div className="order-number-date order-number">
              <p className="order-status-found-content">
                {t("ORDER_STATUS.FIRST_NAME")}
              </p>
              <p className="order-field-detail">
                {orderStatusDetails[0].RequestorFirstName}
              </p>
            </div>
            <div className="order-number-date order-date">
              <p className="order-status-found-content">
                {t("ORDER_STATUS.LAST_NAME")}
              </p>
              <p className="order-field-detail">
                {orderStatusDetails[0].RequestorLastName}
              </p>
            </div>
            {!show.showOrderSuspended &&
              orderStatusDetails.map((orderDetailArray, index) => {
                return (
                  <div className="order-found-container">
                    {index >= 0 && <div className="rectangle"></div>}
                    <Row sm={1} xs={1} md={2} lg={2}>
                      <Col>
                        <label className="info-titles">
                          {t("ORDER_STATUS.RECORD_TYPE")}
                        </label>
                        <p className="order-field-detail">
                          {orderDetailArray.RecordType}
                        </p>
                      </Col>
                      <Col>
                        <label className="info-titles">
                          {t("ORDER_STATUS.CURRENT_STATUS")}
                        </label>
                        <p className="order-field-detail-paid">
                          {orderDetailArray.Status}
                        </p>
                      </Col>
                    </Row>
                    {orderDetailArray.CompletedDate !== "" && (
                      <Row sm={1} xs={1} md={2} lg={2}>
                        <Col>
                          <label className="info-titles">
                            {t("ORDER_STATUS.COMPLETED_DATE")}
                          </label>
                          <p className="order-field-detail">
                            {orderDetailArray.CompletedDate}
                          </p>
                        </Col>
                      </Row>
                    )}
                    {orderDetailArray.Status.toLowerCase() ===
                      "order completed not found" && (
                      <Row>
                        <p className="express-shipping-ex express-shipping-rw found-text-order">
                          {t("ORDER_STATUS.ORDER_STATUS_COMPLETE_NOTFOUND")}
                        </p>
                      </Row>
                    )}
                    {(orderDetailArray.Status.toLowerCase() ===
                      "order completed" ||
                      orderDetailArray.Status.toLowerCase() ===
                        "order completed not found") && (
                      <>
                        {" "}
                        <Row sm={1} xs={1} md={2} lg={2}>
                          <Col>
                            <p className="express-shipping-ex">
                              {t("ORDER_STATUS.SHIPPING")}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <p className="express-shipping-ex express-shipping-rw">
                            {t("ORDER_STATUS.SHIPPING1")}
                          </p>
                        </Row>
                      </>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        {show.showOrderSuspended && (
          <div className="order-suspended">
            <b>{t("ORDER_STATUS.ORDER_APPLICATION_REVIEWED")} </b>
            <span>{t("ORDER_STATUS.LETTER_WITH_INSTRUCTIONS")} </span>
          </div>
        )}

        <Row className="btn-birth" sm={1} xs={1} md={2} lg={2}>
          <Col>
            <Button
              variant="outline-primary"
              className="next-button next-width-order"
              type="submit"
              onClick={() => {
                window.scrollTo(0, 0);
                props.prevCallback(0);
              }}
            >
              {t("ORDER_STATUS.CHECK_ANOTHER_RECORD")}
            </Button>
          </Col>

          {/* DFCT0023197 - Changed back button text and alignment */}
          <Col>
            <a
              className="orderfound-back-button button-secondary"
              href={t("URLS.ORDER_VITAL_RECORDS")}
            >
              {t("ORDER_STATUS.TAKE_ME_HOME")}
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OrderFound;
