import React from "react";
import { Row, Col } from "react-bootstrap";
import close from "../../assets/close.svg";
import alert from "../../assets/alert-warning@1x.png";

function ErrorInPage(props) {
  return (
    <div>
      <Row className="verify-error-message">
        <Col sm={2} xs={2} md={2} lg={2} className="verify-alert-icon">
          <img className="verify-alert" src={alert} alt="alert" />
        </Col>
        <Col className="verify-error-content" sm={8} xs={8} md={8} lg={8}>
          <p className="verify-error-text">{props.title}</p>
          <p className="verify-error-desc">{props.description}</p>
        </Col>
        <Col className="verify-close" sm={2} xs={2} md={2} lg={2}>
          <img
            src={close}
            alt="close button"
            onClick={() => props.hideCallBack()}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ErrorInPage;
