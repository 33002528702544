import PostCall from "./CommonApi";
export default function 
GetAmountDistribution(orderId,uuid) {
  let url =  "/v1/pacs/ovra/AmountDistribution";
  let requestObject =     {
        applicationId: "OVRA",
        agencyId: "DSHS",
        uniqueIdentifier: uuid,
        orderNumber: orderId,
      };
  return PostCall(url, requestObject);
}
