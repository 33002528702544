import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "../deathVerificationPage/DeathVerificationPage.scss";
import { OrderReview, Payment } from "../../common";
import ShippingInfo from "../shippingInfo/ShippingInfo";
import OrderReceipt from "../orderReceipt/OrderReceipt";
import useGlobal from "../../../store";
import uuid from "react-native-uuid";
import { useTranslation } from "react-i18next";
import history from "../../common/history";
import {GetStateData,GetCountyData} from "referencedata";

function OrderReviewFlow(props) {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(props.value ? props.value : 0);
  const [globalState, globalActions] = useGlobal();
  const nextStep = (value) => {
    window.scrollTo(0, 0);
    value ? setCurrentStep(value) : setCurrentStep(currentStep + 1);
    value
      ? setUrlForForm(value)
      : setUrlForForm(currentStep + 1);
  };
  const prevStep = (value) => {
    window.scrollTo(0, 0);
    value ? setCurrentStep(value) : setCurrentStep(currentStep - 1);
    value
      ? setUrlForForm(value)
      : setUrlForForm(currentStep + 1);
  };

  const setUrlForForm = (step) => {
    switch (step) {
      case 0:
        history.push(t("URLS.ORDER_REVIEW"));
        break;
      case 1:
        history.push(
          t("URLS.SHIPPING_INFO")
        );
        break;
      case 2:
        history.push(
          t("URLS.PAYMENT_INFO")
        );
        break;
      case 3:
        history.push(
          t("URLS.ORDER_RECEIPT")
        );
        break;
      default:
        history.push(t("URLS.ORDER_REVIEW"));
    }
  };
  if (!globalState.uniqueIdentifier) {
    globalActions.addUniqueIdentifier("OVRAREF_ID-" + uuid.v1());
  }

  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {t("LANDING_PAGE.TITLE")}
          </title>
          <meta
            name="description"
            content={t("LANDING_PAGE.META_CONTENT")}
          />
        </Helmet>

        {currentStep === 0 && (
          <OrderReview nextCallback={nextStep} />
        )}
        {currentStep === 1 && (
          <ShippingInfo
            stateList={GetStateData("true")}
            countyList={GetCountyData()}
            nextCallback={nextStep}
            prevCallback={prevStep}
          />
        )}
        {currentStep === 2 && (
          <Payment nextCallback={nextStep} />
        )}

        {currentStep === 3 && (
          <OrderReceipt nextCallback={nextStep} />
        )}

      </div>
    </>
  );
}

export default OrderReviewFlow;
