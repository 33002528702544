import React from "react";
import { Controller, useForm } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import history from "../../history";
import { useTranslation } from "react-i18next";
import useGlobal from "../../../../store";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import "./GetStartedMarriageVerification.scss";
import Progress from "../../Progress";
import Select from "react-select";
import MaskedInput from "react-maskedinput";
import { isMobile, isMobileOnly } from "react-device-detect";

function GetStartedMarriageVerification(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();

  const SignupSchema = yup.object().shape({
    dateOfMarriage: yup
      .date()
      .required(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE"))
      .min(
        new Date("1966-01-01"),
        t("DATE_COMMON_ERRORS.VERIFY_DATE_MIN_MARRIAGE")
      )
      .max(new Date(), t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE"))
      .typeError(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE")),

    validateState: yup.boolean(
      globalState.verifyIdentityData.idVerified === "Y" ? true : false
    ),

    selectedState: yup.object().when("validateState", {
      is: (validateState) => validateState === false,
      then: yup.object().shape({
        label: yup
          .string()
          .typeError(t("GET_STARTED_DEATHCERTIFICATE.STATE_ERROR"))
          .required(t("GET_STARTED_DEATHCERTIFICATE.STATE_ERROR")),

        value: yup
          .string()
          .typeError(t("GET_STARTED_DEATHCERTIFICATE.STATE_ERROR"))
          .required(t("GET_STARTED_DEATHCERTIFICATE.STATE_ERROR")),
      }),
    }),
  });

  const { handleSubmit, control, errors } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(SignupSchema),
  });
  const stateList = props.stateList;

  const onSubmit = (data) => {
    globalActions.getStartedMarriageVerificationInfo(data);
    if (
      globalState.verifyIdentityData.idVerified !== undefined &&
      globalState.verifyIdentityData.idVerified === "Y"
    ) {
      props.nextCallback(3);
    } else {
      data.selectedState.value === "TX"
        ? props.nextCallback()
        : props.nextCallback(2);
    }
  };

  return (
    <div>
      <Progress currentStep={1} totalSteps={4} />
      <h1 className="marriageverification-heading">
        {t("MARRIAGE_VERIFICATION.HEADING")}
      </h1>
      <p className="marriageverification-content">
        {t("MARRIAGE_VERIFICATION.DESCRIPTION")}
        <br />
        <br />
        <b>{t("MARRIAGE_VERIFICATION.LICENCE_COPIES")}</b> <br />
        {t("MARRIAGE_VERIFICATION.LICENCE_COPIES_INFO1")}
        <a
          rel={"external"}
          className="link-color"
          href={
            "https://www.dshs.texas.gov/vs/field/localremotedistrict.shtm#district"
          }
          target="_blank"
        >
          {t("MARRIAGE_VERIFICATION.LICENCE_COPIES_INFO2")}
        </a>
      </p>
      <div>
        <form
          className="marriageverification-content-section"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="marriageverification-div">
            <label className="sub-get-started">
              {t("MARRIAGE_VERIFICATION.GET_STARTED")}
            </label>
            <label className="marriageverification-content">
              {t("MARRIAGE_VERIFICATION.GET_STARTED_INFO")}
            </label>
            <div className="deathVerfiyDateRange">
              {(globalState.verifyIdentityData.idVerified === undefined ||
                globalState.verifyIdentityData.idVerified === "" ||
                globalState.verifyIdentityData.idVerified === "N") && (
                  <>
                    <label className="birth-content ">
                      {t("COMMONS.CHOOSE_STATE")}

                      <div className="select-align">
                        <Controller
                          name="selectedState"
                          className={`get-state-birth1 ${errors.selectedState ? "error-red" : ""
                            }`}
                          as={Select}
                          options={stateList}
                          control={control}
                          isClearable
                          defaultValue={
                            globalState.getStartedMarriageVerificationInfo
                              .selectedState.label
                              ? globalState.getStartedMarriageVerificationInfo
                                .selectedState
                              : ""
                          }
                          rules={{ required: true }}
                        />
                        {errors.selectedState && (
                          <p className="error-red-content">
                            {t("GET_STARTED_BIRTHCERTIFICATE.STATE_ERROR")}
                          </p>
                        )}
                      </div>
                    </label>
                  </>
                )}
              <label
                htmlFor="dateOfMarriage"
                className="marriageverification-content"
              >
                {t("MARRIAGE_VERIFICATION.GET_STARTED_TYPE")}
              </label>
              <div className="DoD-Range">
                <Controller
                  control={control}
                  name="dateOfMarriage"
                  rules={{ required: true }}
                  defaultValue={
                    globalState.getStartedMarriageVerificationInfo
                      .dateOfMarriage
                      ? globalState.getStartedMarriageVerificationInfo
                        .dateOfMarriage
                      : ""
                  }
                  className="input-date"
                  render={({ onChange, onBlur, value }) => (
                    <ReactDatePicker
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      id="dateOfMarriage"
                      className={`input-date get-state-birth ${errors.dateOfMarriage ? "error-red" : ""
                        }`}
                      showMonthDropdown
                      showYearDropdown
                      open={isMobile ? false : undefined}
                      customInput={<MaskedInput mask="11/11/1111" />}
                      placeholderText="00/00/0000"
                    />
                  )}
                />
                {errors.dateOfMarriage && (
                  <p className="error-red-content">
                    {errors.dateOfMarriage.message}
                  </p>
                )}
              </div>

              <Row className="btn-group-align" sm={1} xs={1} md={2} lg={2}>
                <Col>
                  <Button
                    variant="outline-primary"
                    className="back-button"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      history.push(t("URLS.SELECT_VITAL_RECORD"));
                    }}
                  >
                    {t("COMMONS.BACK")}
                  </Button>
                </Col>
                <Col xs={{ order: isMobileOnly ? "first" : "last" }}>
                  <Button
                    variant="primary"
                    className="next-button"
                    type="submit"
                  >
                    {t("COMMONS.NEXT")}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default GetStartedMarriageVerification;
