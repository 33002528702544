import React from "react";
import { useForm, Controller } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col } from "react-bootstrap";
import Progress from "../../Progress";
//import { useHistory } from "react-router-dom";
import useGlobal from "../../../../store";
import { NumberField } from "../../SelectField";
import Select from "react-select";
import { FormInput, FormInputOptional } from "./../../../common/FormInput";
import { useTranslation } from "react-i18next";
import DateFormatter from "../../Date";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { IDGenerator } from "../../../common/CommonList";
import history from "./../../../common/history";
import Button from "react-bootstrap/Button";
import "./DivorceVerificationInfo.scss";
import MaskedInput from "react-maskedinput";
import { isMobile, isMobileOnly } from "react-device-detect";

function DivorceVerificationInfo(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();

  //const history = useHistory();

  const SignupSchema = yup.object().shape({
    spouse1FirstName: yup
      .string()
      .required(t("COMMON_ERROR_MSG.SPOUSE1_FIRST_NAME_MISSING_ERROR"))
      .matches(
        /(?=^.{1,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("BIRTH_CERTITICATE_INFO_ERROR_MSG.FIRST_NAME_INVALID_ENTRY")
      ),
    spouse1MiddleName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("BIRTH_CERTITICATE_INFO_ERROR_MSG.MIDDLE_NAME_INVALID_ENTRY")
      ),
    spouse1LastName: yup
      .string()
      .required(t("COMMON_ERROR_MSG.SPOUSE1_LAST_NAME_MISSING_ERROR"))
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("BIRTH_CERTITICATE_INFO_ERROR_MSG.LAST_NAME_INVALID_ENTRY")
      ),

    spouse1DateOfBirth: yup
      .date()
      .nullable()
      .min(new Date("1780-01-01"), t("DATE_COMMON_ERRORS.VERIFY_OPTIONAL_BIRTH_DATE_VALID"))
      .when(
        "dateOfDivorce",
        (dateOfDivorce, schema) =>
          dateOfDivorce &&
          schema.max(
            new Date(dateOfDivorce),
            t("DATE_COMMON_ERRORS.VERIFY_DIVORCE_DATE_VALID")
          )
      )
      .typeError(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE")),

    spouse2FirstName: yup
      .string()
      .required(t("COMMON_ERROR_MSG.SPOUSE2_FIRST_NAME_MISSING_ERROR"))
      .matches(
        /(?=^.{1,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("BIRTH_CERTITICATE_INFO_ERROR_MSG.FIRST_NAME_INVALID_ENTRY")
      ),
    spouse2MiddleName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("BIRTH_CERTITICATE_INFO_ERROR_MSG.MIDDLE_NAME_INVALID_ENTRY")
      ),
    spouse2LastName: yup
      .string()
      .required(t("COMMON_ERROR_MSG.SPOUSE2_LAST_NAME_MISSING_ERROR"))
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("BIRTH_CERTITICATE_INFO_ERROR_MSG.LAST_NAME_INVALID_ENTRY")
      ),

    spouse2DateOfBirth: yup
      .date()
      .nullable()
      .min(new Date("1780-01-01"), t("DATE_COMMON_ERRORS.VERIFY_OPTIONAL_BIRTH_DATE_VALID"))
      .when(
        "dateOfDivorce",
        (dateOfDivorce, schema) =>
          dateOfDivorce &&
          schema.max(
            new Date(dateOfDivorce),
            t("DATE_COMMON_ERRORS.VERIFY_DIVORCE_DATE_VALID")
          )
      )
      .typeError(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE")),

    cityOfDivorce: yup
      .string()
      .required(t("DIVORCE_VERIFICATION_INFO_ERROR_MSG.CITY_OF_DIVORCE"))
      .matches(
        /[a-zA-Z ]*$/,
        t("DIVORCE_VERIFICATION_INFO_ERROR_MSG.CITY_INVALID_ENTRY")
      ),
    countyOfDivorce: yup.object().shape({
      label: yup.string(),

      value: yup.string(),
    }),
    noOfCopies: yup
      .number()
      .typeError(t("VALIDATION_COMMON.COPIES_LIMIT_MIN"))
      .required(t("VALIDATION_COMMON.COPIES_LIMIT_MIN"))
      .moreThan(0, t("COMMON_ERROR_MSG.NO_OF_COPIES"))
      .lessThan(11, t("VALIDATION_COMMON.COPIES_LIMIT_MAX")),
  });

  const { register, handleSubmit, control, errors } = useForm({
    // mode: "onChanges",
    reValidateMode: "onChange",
    resolver: yupResolver(SignupSchema),
  });

  if (props.editIndex !== "") {
    if (
      (globalState.divorceVerificationInfoData.lastName !== undefined ||
        globalState.divorceVerificationInfoData.lastName !== "") &&
      (globalState.divorceVerificationInfoData.firstName !== undefined ||
        globalState.divorceVerificationInfoData.firstName !== "")
    ) {
      globalState.divorceVerificationInfoData =
        globalState.totalOrderRecords[props.editIndex];
    }
  }

  globalState.divorceVerificationInfoData.dateOfDivorce = globalState
    .getStartedDivorceVerificationInfoData.dateOfDivorce
    ? DateFormatter(
      globalState.getStartedDivorceVerificationInfoData.dateOfDivorce,
      "mm/dd/yyyy"
    )
    : "";

  const onSubmit = (data) => {
    data.certificateInfoType = "divorceVerification";
    data.uid = IDGenerator();
    data.selectedState =
      globalState.getStartedDivorceVerificationInfoData.selectedState;
    globalActions.divorceVerificationInfo(data, props.editIndex);
    history.push(t("URLS.ORDER_REVIEW"));
  };

  const addAnotherRecord = (data) => {
    data.certificateInfoType = "divorceVerification";
    data.uid = IDGenerator();
    data.selectedState =
      globalState.getStartedDivorceVerificationInfoData.selectedState;
    globalActions.divorceVerificationInfo(data, props.editIndex);
    window.scrollTo(0, 0);
    history.push(t("URLS.SELECT_VITAL_RECORD"));
  };

  const countyList = props.countyList;

  return (
    <div>
      <Progress currentStep={2} totalSteps={4} />
      <h1 className="birth-info-heading">
        {t("DIVORCE_VERIFICATION_INFO.HEADING")}
      </h1>
      <form
        className="birth-info-content-section"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className="death-verification-info">
          {t("DIVORCE_VERIFICATION_INFO.SUB_HEADING")}
        </h2>

        <FormInput
          id="spouse1FirstName"
          name="spouse1FirstName"
          type="text"
          label={t("DIVORCE_VERIFICATION_INFO.SPOUSE1_FIRST_NAME")}
          register={register}
          className={`birth-info-input answer ${errors.spouse1FirstName ? "error-red" : ""
            }`}
          defaultValue={
            globalState.divorceVerificationInfoData.spouse1FirstName
              ? globalState.divorceVerificationInfoData.spouse1FirstName
              : ""
          }
          rules={{ required: true }}
          error={errors.spouse1FirstName}
        />

        <FormInputOptional
          id="spouse1MiddleName"
          name="spouse1MiddleName"
          type="text"
          label={t("DIVORCE_VERIFICATION_INFO.SPOUSE1_MIDDLE_NAME")}
          labelOptional={t("COMMONS.OPTIONAL")}
          register={register}
          className={`birth-info-input answer ${errors.spouse1MiddleName ? "error-red" : ""
            }`}
          defaultValue={
            globalState.divorceVerificationInfoData.spouse1MiddleName
              ? globalState.divorceVerificationInfoData.spouse1MiddleName
              : ""
          }
          rules={{ required: false }}
          error={errors.spouse1MiddleName}
        />

        <FormInput
          id="spouse1LastName"
          name="spouse1LastName"
          type="text"
          label={t("DIVORCE_VERIFICATION_INFO.SPOUSE1_LAST_NAME")}
          register={register}
          className={`birth-info-input answer ${errors.spouse1LastName ? "error-red" : ""
            }`}
          defaultValue={
            globalState.divorceVerificationInfoData.spouse1LastName
              ? globalState.divorceVerificationInfoData.spouse1LastName
              : ""
          }
          rules={{ required: true }}
          error={errors.spouse1LastName}
        />

        <label htmlFor="spouse1DateOfBirth" className="name birth-info-name">
          {t("DIVORCE_VERIFICATION_INFO.SPOUSE1_DOB")}
        </label>
        <label className="optional-text">{t("COMMONS.OPTIONAL")}</label>
        <div>
          <Controller
            control={control}
            name="spouse1DateOfBirth"
            className="input-date"
            isClearable
            defaultValue={
              globalState.divorceVerificationInfoData.spouse1DateOfBirth
            }
            render={({ onChange, onBlur, value }) => (
              <ReactDatePicker
                onChange={onChange}
                id="spouse1DateOfBirth"
                onBlur={onBlur}
                selected={value}
                className={`input-date get-state-birth ${errors.spouse1DateOfBirth ? "error-red" : ""
                  }`}
                showMonthDropdown
                showYearDropdown
                open={isMobile ? false : undefined}
                customInput={<MaskedInput mask="11/11/1111" />}
                placeholderText="00/00/0000"
              />
            )}
          />
          {errors.spouse1DateOfBirth && (
            <p className="error-red-content">
              {errors.spouse1DateOfBirth.message}
            </p>
          )}
        </div>

        <FormInputOptional
          id="spouse1Age"
          name="spouse1Age"
          type="number"
          label={t("DIVORCE_VERIFICATION_INFO.SPOUSE1_AGE")}
          labelOptional={t("COMMONS.OPTIONAL")}
          register={register}
          className={`birth-info-input answer ${errors.spouse1Age ? "error-red" : ""
            }`}
          defaultValue={
            globalState.divorceVerificationInfoData.spouse1Age
              ? globalState.divorceVerificationInfoData.spouse1Age
              : ""
          }
          rules={{ required: false }}
          error={errors.spouse1Age}
        />

        <FormInput
          id="spouse2FirstName"
          name="spouse2FirstName"
          type="text"
          label={t("DIVORCE_VERIFICATION_INFO.SPOUSE2_FIRST_NAME")}
          register={register}
          className={`birth-info-input answer ${errors.spouse2FirstName ? "error-red" : ""
            }`}
          defaultValue={
            globalState.divorceVerificationInfoData.spouse2FirstName
              ? globalState.divorceVerificationInfoData.spouse2FirstName
              : ""
          }
          rules={{ required: true }}
          error={errors.spouse2FirstName}
        />

        <FormInputOptional
          id="spouse2MiddleName"
          name="spouse2MiddleName"
          type="text"
          label={t("DIVORCE_VERIFICATION_INFO.SPOUSE2_MIDDLE_NAME")}
          labelOptional={t("COMMONS.OPTIONAL")}
          register={register}
          className={`birth-info-input answer ${errors.spouse2MiddleName ? "error-red" : ""
            }`}
          defaultValue={
            globalState.divorceVerificationInfoData.spouse2MiddleName
              ? globalState.divorceVerificationInfoData.spouse2MiddleName
              : ""
          }
          rules={{ required: false }}
          error={errors.spouse2MiddleName}
        />

        <FormInput
          id="spouse2LastName"
          name="spouse2LastName"
          type="text"
          label={t("DIVORCE_VERIFICATION_INFO.SPOUSE2_LAST_NAME")}
          register={register}
          className={`birth-info-input answer ${errors.spouse2LastName ? "error-red" : ""
            }`}
          defaultValue={
            globalState.divorceVerificationInfoData.spouse2LastName
              ? globalState.divorceVerificationInfoData.spouse2LastName
              : ""
          }
          rules={{ required: true }}
          error={errors.spouse2LastName}
        />

        <label htmlFor="spouse2DateOfBirth" className="name birth-info-name">
          {t("DIVORCE_VERIFICATION_INFO.SPOUSE2_DOB")}
        </label>
        <label className="optional-text">{t("COMMONS.OPTIONAL")}</label>
        <div>
          <Controller
            control={control}
            name="spouse2DateOfBirth"
            className="input-date"
            defaultValue={
              globalState.divorceVerificationInfoData.spouse2DateOfBirth
            }
            render={({ onChange, onBlur, value }) => (
              <ReactDatePicker
                onChange={onChange}
                id="spouse2DateOfBirth"
                onBlur={onBlur}
                selected={value}
                className={`input-date get-state-birth ${errors.spouse2DateOfBirth ? "error-red" : ""
                  }`}
                showMonthDropdown
                showYearDropdown
                open={isMobile ? false : undefined}
                customInput={<MaskedInput mask="11/11/1111" />}
                placeholderText="00/00/0000"
              />
            )}
          />
          {errors.spouse2DateOfBirth && (
            <p className="error-red-content">
              {errors.spouse2DateOfBirth.message}
            </p>
          )}
        </div>

        <FormInputOptional
          id="spouse2Age"
          name="spouse2Age"
          type="number"
          label={t("DIVORCE_VERIFICATION_INFO.SPOUSE2_AGE")}
          labelOptional={t("COMMONS.OPTIONAL")}
          register={register}
          className={`birth-info-input answer ${errors.spouse2Age ? "error-red" : ""
            }`}
          defaultValue={
            globalState.divorceVerificationInfoData.spouse2Age
              ? globalState.divorceVerificationInfoData.spouse2Age
              : ""
          }
          rules={{ required: false }}
          error={errors.spouse2Age}
        />

        <h2 className="death-verification-info">
          {t("DIVORCE_VERIFICATION_INFO.SUB_HEADING2")}
        </h2>

        <FormInput
          control={control}
          id="dateOfDivorce"
          name="dateOfDivorce"
          className={"birth-info-input answer"}
          rules={{ required: true }}
          register={register}
          label={t("DIVORCE_VERIFICATION_INFO.DIVORCE_DATE")}
          disabled={true}
          defaultValue={globalState.divorceVerificationInfoData.dateOfDivorce}
        />
        <FormInputOptional
          id="cityOfDivorce"
          name="cityOfDivorce"
          type="text"
          label={t("DIVORCE_VERIFICATION_INFO.CITY_OF_DIVORCE")}
          register={register}
          className={`birth-info-input answer ${errors.cityOfDivorce ? "error-red" : ""
            }`}
          defaultValue={
            globalState.divorceVerificationInfoData.cityOfDivorce
              ? globalState.divorceVerificationInfoData.cityOfDivorce
              : ""
          }
          error={errors.cityOfDivorce}
        />

        <label className="name">
          {t("DIVORCE_VERIFICATION_INFO.COUNTY_OF_DIVORCE")}
          <div className="info-input-align">
            <Controller
              name="countyOfDivorce"
              className={`get-state-birth field-label-padding ${errors.countyOfDivorce ? "error-red" : ""
                }`}
              as={Select}
              options={countyList}
              control={control}
              isClearable
              defaultValue={
                globalState.divorceVerificationInfoData.countyOfDivorce.label
                  ? globalState.divorceVerificationInfoData.countyOfDivorce
                  : ""
              }
              rules={{ required: true }}
            />
            {errors.countyOfDivorce && (
              <p className="error-red-content">
                {t("DIVORCE_VERIFICATION_INFO_ERROR_MSG.COUNTY_OF_DIVORCE")}
              </p>
            )}
          </div>
        </label>
        <h2 className="death-verification-info">
          {t("DIVORCE_VERIFICATION_INFO.RECORD_AMOUNT")}
        </h2>
        <NumberField
          id="noOfCopies"
          name="noOfCopies"
          type="number"
          register={register}
          rules={{ required: true }}
          label={t("DIVORCE_VERIFICATION_INFO.NUMBER_OF_COPIES")}
          defaultValue={
            globalState.divorceVerificationInfoData.noOfCopies
              ? globalState.divorceVerificationInfoData.noOfCopies
              : ""
          }
          error={errors.noOfCopies}
        />

        <div>
          <Row className="btn-birth-info" sm={1} xs={1} md={2} lg={2}>
            <Col>
              <Button
                variant="outline-primary"
                className="back-button-birth-info"
                onClick={handleSubmit(addAnotherRecord)}
              >
                {t("COMMONS.ADD_RECORD")}
              </Button>
            </Col>
            <Col xs={{ order: isMobileOnly ? "first" : "last" }}>
              <Button
                variant="primary"
                className="next-button"
                type="submit"
              >
                {t("COMMONS.NEXT")}
              </Button>
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
}

export default DivorceVerificationInfo;
