import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col } from "react-bootstrap";
import Progress from "../../Progress";
import history from "./../../history";
import Modal from "react-bootstrap/Modal";

import Select from "react-select";
import add from "../../../../assets/add.svg";
import useGlobal from "../../../../store";
import { NumberField } from "../../SelectField";
import PurposeBirthList from "./../../../common/PurposeList";
import { FormInput, FormInputOptional } from "./../../../common/FormInput";
import GenderList from "./../../../common/CommonList";
import { useTranslation } from "react-i18next";
import { ErrorInPage } from "./../../../common/index";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import "./BirthCertificateInfo.scss";
import { IDGenerator } from "../../../common/CommonList";
import DateFormatter from "../../Date";
import { isMobileOnly } from "react-device-detect";

function BirthCertificateInfo(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const [showCopiesError, setShowCopiesError] = useState(false);
  const listingOptions = PurposeBirthList();
  const [showParentChecked, setShowParentChecked] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const [helperText, setHelpterText] = useState("");
  const [helperText2, setHelpterText2] = useState("");
  const [helperText3, setHelpterText3] = useState("");

  const BirthCertificateList = [
    { value: "select", label: t("BIRTH_CERTITICATE_INFORMATION.SELECT") },
    {
      value: "Birth Certificate-Full Size",
      label: t("BIRTH_CERTITICATE_INFORMATION.LONG_FORM"),
    },
    {
      value: "Birth Certificate-Standard Size",
      label: t("BIRTH_CERTITICATE_INFORMATION.SHORT_FORM"),
    },
    {
      value: "Birth Certificate-Heirloom (Flag Image)",
      label: t("BIRTH_CERTITICATE_INFORMATION.STATE_FLAG_HEIROOM"),
    },
    {
      value: "Birth Certificate-Heirloom (Bassinet Image)",
      label: t("BIRTH_CERTITICATE_INFORMATION.BASSINET_HEIRLOOM"),
    },
  ];

  const getHelperText = (label) => {
    switch (label) {
      case "Birth Certificate-Full Size":
        return t("BIRTH_CERTITICATE_INFORMATION.LONG_FORM_ACCEPTED");

      case "Birth Certificate-Standard Size":
        return t("BIRTH_CERTITICATE_INFORMATION.SHORT_FORM_ACCEPTED");

      case "Birth Certificate-Heirloom (Flag Image)":
        return t("BIRTH_CERTITICATE_INFORMATION.HEIRLOOM_NOT_ACCEPTED");

      case "Birth Certificate-Heirloom (Bassinet Image)":
        return t("BIRTH_CERTITICATE_INFORMATION.HEIRLOOM_NOT_ACCEPTED");

      default:
        return "";
    }
  };

  const selectedBirthCertificateHelp = (recordTypeHelperTextParam) => {
    console.log(recordTypeHelperTextParam);
    setHelpterText(getHelperText(recordTypeHelperTextParam));
  };

  const selectedBirthCertificateHelp2 = (recordTypeHelperTextParam) => {
    console.log(recordTypeHelperTextParam);
    setHelpterText2(getHelperText(recordTypeHelperTextParam));
  };

  const selectedBirthCertificateHelp3 = (recordTypeHelperTextParam) => {
    console.log(recordTypeHelperTextParam);
    setHelpterText3(getHelperText(recordTypeHelperTextParam));
  };
  const addAdditionalRecord = () => {
    globalActions.addDocumentTypeFlagOne(true);
  };

  const removeAdditionalRecord = () => {
    globalActions.addDocumentTypeFlagOne(false);
  };

  const addSecondAdditionalRecord = () => {
    globalActions.addDocumentTypeFlagTwo(true);
  };

  const removeSecondAdditionalRecord = () => {
    globalActions.addDocumentTypeFlagTwo(false);
  };

  const SignupSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(t("BIRTH_CERTITICATE_INFO_ERROR_MSG.FIRST_NAME_MISSING_ERROR"))
      .matches(
        /(?=^.{1,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("BIRTH_CERTITICATE_INFO_ERROR_MSG.FIRST_NAME_INVALID_ENTRY")
      ),
    middleName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("BIRTH_CERTITICATE_INFO_ERROR_MSG.MIDDLE_NAME_INVALID_ENTRY")
      ),
    lastName: yup
      .string()
      .required(t("BIRTH_CERTITICATE_INFO_ERROR_MSG.LAST_NAME_MISSING_ERROR"))
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("BIRTH_CERTITICATE_INFO_ERROR_MSG.LAST_NAME_INVALID_ENTRY")
      ),
    cityOfBirth: yup
      .string()
      .required(t("BIRTH_CERTITICATE_INFO_ERROR_MSG.CITY_ERROR"))
      .matches(
        /^[a-zA-Z ]*$/,
        t("BIRTH_CERTITICATE_INFO_ERROR_MSG.CITY_INVALID_ENTRY")
      ),
    selectedGender: yup.object().shape({
      label: yup.string().required(t("COMMON_ERROR_MSG.GENDER_ERROR")),
      value: yup.string().required(t("COMMON_ERROR_MSG.GENDER_ERROR")),
    }),
    selectedBirthCertificate: yup
      .string()
      .oneOf([
        "Birth Certificate-Full Size",
        "Birth Certificate-Standard Size",
        "Birth Certificate-Heirloom (Flag Image)",
        "Birth Certificate-Heirloom (Bassinet Image)",
      ])
      .required(t("BIRTH_CERTITICATE_INFO_ERROR_MSG.SELECT_RECORD_ERROR")),

    selectedBirthCertificate2: yup.string().when("inputDocumentTypeFlagOne", {
      is: (inputDocumentTypeFlagOne) => inputDocumentTypeFlagOne === "true",
      then: yup
        .string()
        .oneOf([
          "Birth Certificate-Full Size",
          "Birth Certificate-Standard Size",
          "Birth Certificate-Heirloom (Flag Image)",
          "Birth Certificate-Heirloom (Bassinet Image)",
        ]),
    }),
    selectedBirthCertificate3: yup.string().when("inputDocumentTypeFlagTwo", {
      is: (inputDocumentTypeFlagTwo) => inputDocumentTypeFlagTwo === "true",
      then: yup
        .string()
        .oneOf([
          "Birth Certificate-Full Size",
          "Birth Certificate-Standard Size",
          "Birth Certificate-Heirloom (Flag Image)",
          "Birth Certificate-Heirloom (Bassinet Image)",
        ]),
    }),
    selectedPurposeList: yup.object().shape({
      label: yup.string().required(t("COMMON_ERROR_MSG.PURPOSE_ERROR")),
      value: yup.string().required(t("COMMON_ERROR_MSG.PURPOSE_ERROR")),
    }),
    selectedPurposeList2: yup.object().when("inputDocumentTypeFlagOne", {
      is: (inputDocumentTypeFlagOne) => inputDocumentTypeFlagOne === "true",
      then: yup.object().shape({
        label: yup.string().required(t("COMMON_ERROR_MSG.PURPOSE_ERROR")),
        value: yup.string().required(t("COMMON_ERROR_MSG.PURPOSE_ERROR")),
      }),
    }),

    selectedPurposeList3: yup.object().when("inputDocumentTypeFlagTwo", {
      is: (inputDocumentTypeFlagTwo) => inputDocumentTypeFlagTwo === "true",
      then: yup.object().shape({
        label: yup.string().required(t("COMMON_ERROR_MSG.PURPOSE_ERROR")),
        value: yup.string().required(t("COMMON_ERROR_MSG.PURPOSE_ERROR")),
      }),
    }),
    firstNameParent1: yup
      .string()
      .required(t("BIRTH_CERTITICATE_INFO_ERROR_MSG.PARENT_ONE_FIRST_NAME"))
      .matches(
        /(?=^.{1,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("COMMON_ERROR_MSG.FIRST_NAME_INVALID_ENTRY")
      ),
    middleNameParent1: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.MIDDLE_NAME_INVALID_ENTRY")
      ),
    lastNameParent1: yup
      .string()
      .required(t("BIRTH_CERTITICATE_INFO_ERROR_MSG.PARENT_ONE_LAST_NAME"))
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("COMMON_ERROR_MSG.LAST_NAME_INVALID_ENTRY")
      ),

    firstNameParent2: yup.string().when("showParentTwo", {
      is: (showParentTwo) => showParentTwo === false,
      then: yup
        .string()
        .required(t("BIRTH_CERTITICATE_INFO_ERROR_MSG.PARENT_TWO_FIRST_NAME"))
        .matches(
          /(?=^.{1,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
          t("COMMON_ERROR_MSG.FIRST_NAME_INVALID_ENTRY")
        ),
    }),

    middleNameParent2: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.MIDDLE_NAME_INVALID_ENTRY")
      ),

    lastNameParent2: yup.string().when("showParentTwo", {
      is: (showParentTwo) => showParentTwo === false,
      then: yup
        .string()
        .required(t("BIRTH_CERTITICATE_INFO_ERROR_MSG.PARENT_TWO_LAST_NAME"))
        .matches(
          /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
          t("COMMON_ERROR_MSG.LAST_NAME_INVALID_ENTRY")
        ),
    }),

    noOfCopies: yup
      .number()
      .typeError(t("COMMON_ERROR_MSG.NO_OF_COPIES_BC"))
      .required(t("COMMON_ERROR_MSG.NO_OF_COPIES_BC"))
      .moreThan(0, t("VALIDATION_COMMON.COPIES_LIMIT_MIN_BC"))
      .lessThan(4, t("VALIDATION_COMMON.COPIES_LIMIT_MIN_BC")),

    noOfCopies2: yup.number().when("inputDocumentTypeFlagOne", {
      is: (inputDocumentTypeFlagOne) => inputDocumentTypeFlagOne === "true",
      then: yup
        .number()
        .typeError(t("COMMON_ERROR_MSG.NO_OF_COPIES_BC"))
        .required(t("COMMON_ERROR_MSG.NO_OF_COPIES_BC"))
        .moreThan(0, t("VALIDATION_COMMON.COPIES_LIMIT_MIN_BC"))
        .lessThan(4, t("VALIDATION_COMMON.COPIES_LIMIT_MIN_BC")),
    }),

    noOfCopies3: yup.number().when("inputDocumentTypeFlagTwo", {
      is: (inputDocumentTypeFlagTwo) => inputDocumentTypeFlagTwo === "true",
      then: yup
        .number()
        .typeError(t("COMMON_ERROR_MSG.NO_OF_COPIES_BC"))
        .required(t("COMMON_ERROR_MSG.NO_OF_COPIES_BC"))
        .moreThan(0, t("VALIDATION_COMMON.COPIES_LIMIT_MIN_BC"))
        .lessThan(4, t("VALIDATION_COMMON.COPIES_LIMIT_MIN_BC")),
    }),
  });

  const { register, handleSubmit, control, errors } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(SignupSchema),
  });

  if (props.editIndex !== "") {
    if (
      (globalState.birthCertificateInfoData.lastName === undefined ||
        globalState.birthCertificateInfoData.lastName === "") &&
      (globalState.birthCertificateInfoData.firstName === undefined ||
        globalState.birthCertificateInfoData.firstName === "")
    ) {
      globalState.birthCertificateInfoData =
        globalState.totalOrderRecords[props.editIndex];
      setShowParentChecked(globalState.birthCertificateInfoData.showParentTwo);
    }
  }

  globalState.birthCertificateInfoData.dateOfBirth = globalState
    .getStartedBirthCertificateData.dateOfBirth
    ? DateFormatter(
      globalState.getStartedBirthCertificateData.dateOfBirth,
      "mm/dd/yyyy"
    )
    : "";

  const BindNoOfCopies = (data) => {
    let orderDetails = [];

    orderDetails.push({
      recordType: data.selectedBirthCertificate,
      numberOfCopies: parseInt(data.noOfCopies),
      purposeList: data.selectedPurposeList,
    });

    if (globalState.documentTypeFlagOne && parseInt(data.noOfCopies2) > 0) {
      orderDetails.push({
        recordType: data.selectedBirthCertificate2,
        numberOfCopies: parseInt(data.noOfCopies2),
        purposeList: data.selectedPurposeList2,
      });
    }
    if (globalState.documentTypeFlagTwo && parseInt(data.noOfCopies3) > 0) {
      orderDetails.push({
        recordType: data.selectedBirthCertificate3,
        numberOfCopies: parseInt(data.noOfCopies3),
        purposeList: data.selectedPurposeList3,
      });
    }
    return orderDetails;
  };

  const onSubmit = (data) => {
    let orderDetails = BindNoOfCopies(data);
    let totalCount = orderDetails
      .map((orderDetail) => orderDetail.numberOfCopies)
      .reduce((totalNumber, noOfCopy) => noOfCopy + totalNumber);

    if (orderDetails.length > 3 || totalCount > 3) {
      setShowCopiesError(true);
    } else {
      data.orderDetails = orderDetails;
      data.totalNoOfCopies = totalCount;
      data.certificateInfoType = "birthCertificate";
      data.selectedState =
        globalState.getStartedBirthCertificateData.selectedState;
      data.selectedRelation =
        globalState.getStartedBirthCertificateData.selectedRelation;
      data.uid = IDGenerator();
      globalActions.addBirthCertificateInfo(data, props.editIndex);
      history.push(t("URLS.ORDER_REVIEW"));
    }
  };

  const countyList = props.countyList;

  const addAnotherRecord = (data) => {
    let orderDetails = BindNoOfCopies(data);
    let totalCount = orderDetails
      .map((orderDetail) => orderDetail.numberOfCopies)
      .reduce((totalNumber, noOfCopy) => noOfCopy + totalNumber);

    if (orderDetails.length > 3 || totalCount > 3) {
      setShowCopiesError(true);
    } else {
      data.orderDetails = orderDetails;
      data.totalNoOfCopies = totalCount;
      data.certificateInfoType = "birthCertificate";
      data.selectedState =
        globalState.getStartedBirthCertificateData.selectedState;
      data.selectedRelation =
        globalState.getStartedBirthCertificateData.selectedRelation;
      data.uid = IDGenerator();
      globalActions.addBirthCertificateInfo(data, props.editIndex);
      history.push(t("URLS.SELECT_VITAL_RECORD"));
    }
  };

  return (
    <div>
      <Progress currentStep={2} totalSteps={4} />
      <h1 className="birth-info-heading">
        {t("BIRTH_CERTITICATE_INFORMATION.HEADING")}
      </h1>
      <form
        className="birth-info-content-section"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className="birth-personal-info">
          {t("BIRTH_CERTITICATE_INFORMATION.SUB_HEADING_CHILD")}
        </h2>

        <FormInput
          id="firstName"
          name="firstName"
          type="text"
          label={t("BIRTH_CERTITICATE_INFORMATION.CHILD_FIRST_NAME")}
          register={register}
          className={`birth-info-input answer ${errors.firstName ? "error-red" : ""
            }`}
          defaultValue={
            globalState.birthCertificateInfoData.firstName
              ? globalState.birthCertificateInfoData.firstName
              : ""
          }
          rules={{ required: true }}
          error={errors.firstName}
        />

        <FormInputOptional
          id="middleName"
          name="middleName"
          className="birth-info-input answer"
          type="text"
          label={t("BIRTH_CERTITICATE_INFORMATION.CHILD_MIDDLE_NAME")}
          labelOptional={t("COMMONS.OPTIONAL")}
          register={register}
          defaultValue={
            globalState.birthCertificateInfoData.middleName
              ? globalState.birthCertificateInfoData.middleName
              : ""
          }
        />

        <FormInput
          id="lastName"
          name="lastName"
          type="text"
          label={t("BIRTH_CERTITICATE_INFORMATION.CHILD_LAST_NAME")}
          register={register}
          className={`birth-info-input answer ${errors.lastName ? "error-red" : ""
            }`}
          defaultValue={
            globalState.birthCertificateInfoData.lastName
              ? globalState.birthCertificateInfoData.lastName
              : ""
          }
          rules={{ required: true }}
          error={errors.lastName}
        />

        <FormInput
          id="dateOfBirth"
          name="dateOfBirth"
          type="text"
          disabled={true}
          label={t("BIRTH_CERTITICATE_INFORMATION.DOB_CERTIFICATE")}
          register={register}
          className={`birth-info-input answer ${errors.ssn ? "error-red" : ""}`}
          defaultValue={globalState.birthCertificateInfoData.dateOfBirth}
        />

        <div className="gender-info">
          <label className="select-input-field-align">
            {t("BIRTH_CERTITICATE_INFORMATION.GENDER")}

            <Controller
              name="selectedGender"
              className={`field-label-padding ${errors.selectedGender ? "error-red" : ""
                }`}
              as={Select}
              options={GenderList()}
              control={control}
              isClearable
              defaultValue={
                globalState.birthCertificateInfoData.selectedGender.label
                  ? globalState.birthCertificateInfoData.selectedGender
                  : ""
              }
              rules={{ required: true }}
            />

            {errors.selectedGender && (
              <p className="error-red-content">
                {t("COMMON_ERROR_MSG.GENDER_ERROR")}
              </p>
            )}
          </label>
        </div>
        <FormInput
          id="cityOfBirth"
          name="cityOfBirth"
          type="text"
          label={t("BIRTH_CERTITICATE_INFORMATION.BIRTH_CITY")}
          register={register}
          className={`birth-info-input answer ${errors.cityOfBirth ? "error-red" : ""
            }`}
          defaultValue={
            globalState.birthCertificateInfoData.cityOfBirth
              ? globalState.birthCertificateInfoData.cityOfBirth
              : ""
          }
          rules={{ required: true }}
          error={errors.cityOfBirth}
        />
        <div className="birth-county-align">
          <label className="select-input-field-align">
            {t("BIRTH_CERTITICATE_INFORMATION.BIRTH_COUNTY")}

            <Controller
              name="countyOfBirth"
              className={`field-label-padding ${errors.countyOfBirth ? "error-red" : ""
                }`}
              as={Select}
              options={countyList}
              control={control}
              isClearable
              defaultValue={
                globalState.birthCertificateInfoData.countyOfBirth.label
                  ? globalState.birthCertificateInfoData.countyOfBirth
                  : ""
              }
              rules={{ required: true }}
            />

            {errors.countyOfBirth && (
              <p className="error-red-content">
                {t("COMMON_ERROR_MSG.COUNTY_ERROR")}
              </p>
            )}
          </label>
        </div>

        <div className="parent-both-info-padding">
          <h2 className="birth-personal-info">
            {t("BIRTH_CERTITICATE_INFORMATION.SUB_HEADING_PARENT")}
          </h2>

          <FormInput
            id="firstNameParent1"
            name="firstNameParent1"
            type="text"
            label={t("BIRTH_CERTITICATE_INFORMATION.PARENT1_FIRST_NAME")}
            register={register}
            className={`birth-info-input answer ${errors.firstNameParent1 ? "error-red" : ""
              }`}
            defaultValue={
              globalState.birthCertificateInfoData.firstNameParent1
                ? globalState.birthCertificateInfoData.firstNameParent1
                : ""
            }
            rules={{ required: true }}
            error={errors.firstNameParent1}
          />

          <FormInputOptional
            id="middleNameParent1"
            name="middleNameParent1"
            className="birth-info-input answer"
            type="text"
            labelOptional={t("COMMONS.OPTIONAL")}
            label={t("BIRTH_CERTITICATE_INFORMATION.PARENT1_MIDDLE_NAME")}
            register={register}
            defaultValue={
              globalState.birthCertificateInfoData.middleNameParent1
                ? globalState.birthCertificateInfoData.middleNameParent1
                : ""
            }
          />
          <FormInput
            id="lastNameParent1"
            name="lastNameParent1"
            type="text"
            label={t("BIRTH_CERTITICATE_INFORMATION.PARENT1_LAST_NAME")}
            register={register}
            className={`birth-info-input answer ${errors.lastNameParent1 ? "error-red" : ""
              }`}
            defaultValue={
              globalState.birthCertificateInfoData.lastNameParent1
                ? globalState.birthCertificateInfoData.lastNameParent1
                : ""
            }
            rules={{ required: true }}
            error={errors.lastNameParent1}
          />
        </div>
        <div className="form-checkbox-default">
          <input
            type="checkbox"
            onChange={() => setShowParentChecked(!showParentChecked)}
            className="checkbox"
            id="parenttwoshow"
            name="showParentTwo"
            ref={register}
            checked={globalState.birthCertificateInfoData.showParentTwo}
          />
          <label htmlFor="parenttwoshow" className="checkbox-parent">
            {t("BIRTH_CERTITICATE_INFORMATION.PARENT_CHECKBOX")}
          </label>
        </div>
        {!showParentChecked && (
          <>
            <FormInput
              id="firstNameParent2"
              name="firstNameParent2"
              type="text"
              label={t("BIRTH_CERTITICATE_INFORMATION.PARENT2_FIRST_NAME")}
              register={register}
              className={`birth-info-input answer ${errors.firstNameParent2 ? "error-red" : ""
                }`}
              defaultValue={
                globalState.birthCertificateInfoData.firstNameParent2
                  ? globalState.birthCertificateInfoData.firstNameParent2
                  : ""
              }
              rules={{ required: true }}
              error={errors.firstNameParent2}
            />{" "}
            <FormInputOptional
              id="middleNameParent2"
              name="middleNameParent2"
              type="text"
              className="birth-info-input answer"
              labelOptional={t("COMMONS.OPTIONAL")}
              label={t("BIRTH_CERTITICATE_INFORMATION.PARENT2_MIDDLE_NAME")}
              register={register}
              defaultValue={
                globalState.birthCertificateInfoData.middleNameParent2
                  ? globalState.birthCertificateInfoData.middleNameParent2
                  : ""
              }
            />
            <FormInput
              id="lastNameParent2"
              name="lastNameParent2"
              type="text"
              label={t("BIRTH_CERTITICATE_INFORMATION.PARENT2_LAST_NAME")}
              register={register}
              className={`birth-info-input answer ${errors.lastNameParent2 ? "error-red" : ""
                }`}
              defaultValue={
                globalState.birthCertificateInfoData.lastNameParent2
                  ? globalState.birthCertificateInfoData.lastNameParent2
                  : ""
              }
              rules={{ required: true }}
              error={errors.lastNameParent2}
            />
          </>
        )}
        <div className="cc-form">
          <div>
            <h2 className="birth-personal-info">
              {t("BIRTH_CERTITICATE_INFORMATION.RECORD_FORMAT")}
            </h2>
            <p className="birth-info-content">
              {t("BIRTH_CERTITICATE_INFORMATION.BIRTH_CERTIFICATE_COPIES")}{" "}
              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="birth-info-modal-heading">
                    {" "}
                    {t(
                      "BIRTH_CERTITICATE_INFORMATION.BIRTH_CERTIFICATE_FORMATS"
                    )}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="birth-info-modal-container">
                  <p className="please-note-the-diff">
                    {t(
                      "BIRTH_CERTITICATE_INFORMATION.DIFFERENCE_IN_BIRTH_CERTIFICATE_FORMATS"
                    )}
                  </p>

                  <div>
                    <p className="long-form-22">
                      {t("BIRTH_CERTITICATE_INFORMATION.LONG_FORM")}
                    </p>
                    <ul className="most-extensive-bir">
                      <li>
                        {t(
                          "BIRTH_CERTITICATE_INFORMATION.EXTENSIVE_BIRTH_RECORD"
                        )}
                      </li>
                      <li>
                        {t(
                          "BIRTH_CERTITICATE_INFORMATION.ORIGINAL_BIRTH_CERTIFICATE_COPY"
                        )}
                      </li>
                      <li>
                        {t("BIRTH_CERTITICATE_INFORMATION.HISTORY_OF_CHANGES")}
                      </li>
                      <li>
                        {t("BIRTH_CERTITICATE_INFORMATION.ORDERING_PASSPORTS")}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p className="long-form-22">
                      {t("BIRTH_CERTITICATE_INFORMATION.SHORT_FORM")}
                    </p>
                    <ul className="most-extensive-bir">
                      <li>{t("BIRTH_CERTITICATE_INFORMATION.PURPOSE")}</li>
                      <li>
                        {t(
                          "BIRTH_CERTITICATE_INFORMATION.BIRTH_RECORD_SUMMARY"
                        )}
                      </li>
                      <li>
                        {t("BIRTH_CERTITICATE_INFORMATION.CURRENT_INFORMATION")}{" "}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p className="long-form-22">
                      {t("BIRTH_CERTITICATE_INFORMATION.HEIRLOOM")}
                    </p>
                    <ul className="most-extensive-bir">
                      <li>
                        {t(
                          "BIRTH_CERTITICATE_INFORMATION.BIRTH_CERTIFICATE_SUMMARY"
                        )}
                      </li>
                      <li>{t("BIRTH_CERTITICATE_INFORMATION.DECORATIVE")}</li>
                      <li>{t("BIRTH_CERTITICATE_INFORMATION.SIMILAR_INFO")}</li>
                    </ul>
                  </div>
                </Modal.Body>
                <div className="modal-close-button">
                  <button
                    className="primary-blue-button modal-birth-info-button"
                    onClick={() => setShow(false)}
                  >
                    {t("BIRTH_CERTITICATE_INFORMATION.OK_GOT_IT")}
                  </button>
                </div>
              </Modal>
              <p className="birth-info-content">
                <a onClick={() => setShow(true)}>
                  <span className="modal-link-color menu__MenuTrigger">
                    {t(
                      "BIRTH_CERTITICATE_INFORMATION.BIRTH_CERTIFICATE_DIFFERENCE"
                    )}
                  </span>
                </a>
              </p>
            </p>
          </div>
        </div>
        <div>
          <>
            {/* DEFAULT FIRST ADD FORMAT  */}
            <div className="select-align-death">
              <label
                htmlFor="selectedBirthCertificate"
                className="birth-info-label"
              >
                {t("BIRTH_CERTITICATE_INFORMATION.FORMAT")}
              </label>
              <select
                id="selectedBirthCertificate"
                name="selectedBirthCertificate"
                className={`birth-info-input answer ${errors.selectedBirthCertificate ? "error-red" : ""
                  }`}
                control={control}
                ref={register}
                rules={{ required: true }}
                onChange={(e) => selectedBirthCertificateHelp(e.target.value)}
              >
                {BirthCertificateList.map(function ({ value, label }, index) {
                  return value ===
                    globalState.birthCertificateInfoData
                      .selectedBirthCertificate ? (
                      <option value={value} selected>
                        {label}
                      </option>
                    ) : (
                      <option value={value}>{label}</option>
                    );
                })}
              </select>
              {errors.selectedBirthCertificate && (
                <p className="error-red-content">
                  {t("BIRTH_CERTITICATE_INFO_ERROR_MSG.SELECT_RECORD_ERROR")}
                </p>
              )}

              <p className="birth-recordtype-helptext">{helperText}</p>
              <label className="birth-content">
                {t("PURPOSE_LIST_LABEL.PURPOSE")}
                <Controller
                  name="selectedPurposeList"
                  className={`get-state-birth form-input-padding ${errors.selectedPurposeList ? "error-red" : ""
                    }`}
                  as={Select}
                  options={listingOptions}
                  control={control}
                  rules={{ required: true }}
                  isClearable
                  defaultValue={
                    globalState.birthCertificateInfoData.selectedPurposeList
                      ? globalState.birthCertificateInfoData.selectedPurposeList
                      : ""
                  }
                />
                {errors.selectedPurposeList && (
                  <p className="error-red-content">
                    {t("COMMON_ERROR_MSG.PURPOSE_ERROR")}
                  </p>
                )}
              </label>

              <NumberField
                id="noOfCopies"
                name="noOfCopies"
                type="number"
                className="birth-info-label"
                register={register}
                label={t("BIRTH_CERTITICATE_INFORMATION.NUMBER_OF_COPIES")}
                defaultValue={
                  globalState.birthCertificateInfoData.noOfCopies
                    ? globalState.birthCertificateInfoData.noOfCopies
                    : ""
                }
                error={errors.noOfCopies}
              />
            </div>

            {!globalState.documentTypeFlagOne && (
              <div className="add-format">
                <img
                  alt="add additional format type"
                  className="icon-add add-record-type"
                  src={add}
                  onClick={addAdditionalRecord}
                />
                <p
                  className="add-another-format add-record-type"
                  onClick={addAdditionalRecord}
                >
                  {t("BIRTH_CERTITICATE_INFORMATION.ADD_ANOTHER_FORMAT")}
                </p>
              </div>
            )}
            {/* ADD ADDITIONAL SECOND RECORD TYPE */}
            {globalState.documentTypeFlagOne && (
              <>
                <div className="select-align-death">
                  <label
                    htmlFor="selectedBirthCertificate2"
                    className="birth-info-label"
                  >
                    {t("BIRTH_CERTITICATE_INFORMATION.FORMAT")}
                  </label>
                  <select
                    id="selectedBirthCertificate2"
                    name="selectedBirthCertificate2"
                    className={`birth-info-input answer ${errors.selectedBirthCertificate2 ? "error-red" : ""
                      }`}
                    control={control}
                    ref={register}
                    rules={{ required: true }}
                    onChange={(e) =>
                      selectedBirthCertificateHelp2(e.target.value)
                    }
                  >
                    {BirthCertificateList.map(function (
                      { value, label },
                      index
                    ) {
                      return value ===
                        globalState.birthCertificateInfoData
                          .selectedBirthCertificate2 ? (
                          <option value={value} selected>
                            {label}
                          </option>
                        ) : (
                          <option value={value}>{label}</option>
                        );
                    })}
                  </select>
                  {errors.selectedBirthCertificate2 && (
                    <p className="error-red-content">
                      {t(
                        "BIRTH_CERTITICATE_INFO_ERROR_MSG.SELECT_RECORD_ERROR"
                      )}
                    </p>
                  )}
                  <p className="birth-recordtype-helptext">{helperText2}</p>
                  <label className="birth-content">
                    {t("PURPOSE_LIST_LABEL.PURPOSE")}
                    <Controller
                      name="selectedPurposeList2"
                      className={`get-state-birth form-input-padding ${errors.selectedPurposeList2 ? "error-red" : ""
                        }`}
                      as={Select}
                      options={listingOptions}
                      control={control}
                      rules={{ required: true }}
                      isClearable
                      defaultValue={
                        globalState.birthCertificateInfoData.selectedPurposeList2
                          ? globalState.birthCertificateInfoData
                            .selectedPurposeList2
                          : ""
                      }
                    />
                    {errors.selectedPurposeList2 && (
                      <p className="error-red-content">
                        {t("COMMON_ERROR_MSG.PURPOSE_ERROR")}
                      </p>
                    )}
                  </label>

                  <NumberField
                    id="noOfCopies2"
                    name="noOfCopies2"
                    className="birth-info-label"
                    type="number"
                    register={register}
                    label={t("BIRTH_CERTITICATE_INFORMATION.NUMBER_OF_COPIES")}
                    defaultValue={
                      globalState.birthCertificateInfoData.noOfCopies2
                        ? globalState.birthCertificateInfoData.noOfCopies2
                        : ""
                    }
                    error={errors.noOfCopies2}
                  />
                </div>

                <FormInput
                  id="inputDocumentTypeFlagOne"
                  name="inputDocumentTypeFlagOne"
                  type="text"
                  register={register}
                  value={globalState.documentTypeFlagOne}
                />

                <Button
                  variant="outline-danger"
                  className="remove-button"
                  onClick={removeAdditionalRecord}
                >
                  {t("BIRTH_CERTITICATE_INFORMATION.REMOVE")}
                </Button>

                {!globalState.documentTypeFlagTwo && (
                  <div className="add-format">
                    <img
                      alt="Add additional number of records"
                      className="icon-add add-record-type"
                      src={add}
                      onClick={addSecondAdditionalRecord}
                    />
                    <p
                      className="add-another-format add-record-type"
                      onClick={addSecondAdditionalRecord}
                    >
                      {t("BIRTH_CERTITICATE_INFORMATION.ADD_ANOTHER_FORMAT")}
                    </p>
                  </div>
                )}
              </>
            )}

            {/* THIRD ADDITIONAL FORMAT  */}

            {globalState.documentTypeFlagTwo && (
              <>
                <div className="select-align-death">
                  <label
                    htmlFor="selectedBirthCertificate3"
                    className="birth-info-label"
                  >
                    {t("BIRTH_CERTITICATE_INFORMATION.FORMAT")}
                  </label>
                  <select
                    id="selectedBirthCertificate3"
                    name="selectedBirthCertificate3"
                    className={`birth-info-input answer ${errors.selectedBirthCertificate3 ? "error-red" : ""
                      }`}
                    control={control}
                    ref={register}
                    rules={{ required: true }}
                    onChange={(e) =>
                      selectedBirthCertificateHelp3(e.target.value)
                    }
                  >
                    {BirthCertificateList.map(function (
                      { value, label },
                      index
                    ) {
                      return value ===
                        globalState.birthCertificateInfoData
                          .selectedBirthCertificate3 ? (
                          <option value={value} selected>
                            {label}
                          </option>
                        ) : (
                          <option value={value}>{label}</option>
                        );
                    })}{" "}
                  </select>
                  {errors.selectedBirthCertificate3 && (
                    <p className="error-red-content">
                      {t(
                        "BIRTH_CERTITICATE_INFO_ERROR_MSG.SELECT_RECORD_ERROR"
                      )}
                    </p>
                  )}
                  <p className="birth-recordtype-helptext">{helperText3}</p>
                  <label className="birth-content">
                    {t("PURPOSE_LIST_LABEL.PURPOSE")}
                    <Controller
                      name="selectedPurposeList3"
                      className={`get-state-birth form-input-padding ${errors.selectedPurposeList3 ? "error-red" : ""
                        }`}
                      as={Select}
                      options={listingOptions}
                      control={control}
                      rules={{ required: true }}
                      isClearable
                      defaultValue={
                        globalState.birthCertificateInfoData.selectedPurposeList3
                          ? globalState.birthCertificateInfoData
                            .selectedPurposeList3
                          : ""
                      }
                    />
                    {errors.selectedPurposeList3 && (
                      <p className="error-red-content">
                        {t("COMMON_ERROR_MSG.PURPOSE_ERROR")}
                      </p>
                    )}
                  </label>

                  <NumberField
                    id="noOfCopies3"
                    className="birth-info-label"
                    name="noOfCopies3"
                    type="number"
                    register={register}
                    label={t("BIRTH_CERTITICATE_INFORMATION.NUMBER_OF_COPIES")}
                    defaultValue={
                      globalState.birthCertificateInfoData.noOfCopies3
                        ? globalState.birthCertificateInfoData.noOfCopies3
                        : ""
                    }
                    error={errors.noOfCopies3}
                  />
                </div>
                <FormInput
                  id="inputDocumentTypeFlagTwo"
                  name="inputDocumentTypeFlagTwo"
                  type="text"
                  register={register}
                  value={globalState.documentTypeFlagTwo}
                />

                <Button
                  variant="outline-danger"
                  className="remove-button"
                  onClick={removeSecondAdditionalRecord}
                >
                  {t("BIRTH_CERTITICATE_INFORMATION.REMOVE")}
                </Button>
              </>
            )}
          </>
        </div>

        {showCopiesError && (
          <ErrorInPage
            title={t("BIRTH_CERTITICATE_INFORMATION.UNABLE_TO_PROCESS")}
            description={t(
              "BIRTH_CERTITICATE_INFORMATION.UNABLE_TO_PROCESS_DESC"
            )}
            hideCallBack={() => setShowCopiesError(false)}
          />
        )}

        <div>
          <Row className="btn-birth-info" sm={1} xs={1} md={2} lg={2}>
            <Col>
              <Button
                variant="outline-primary"
                className="back-button-birth-info"
                onClick={handleSubmit(addAnotherRecord)}
              >
                {t("COMMONS.ADD_RECORD")}
              </Button>
            </Col>
            <Col xs={{ order: isMobileOnly ? "first" : "last" }}>
              <Button
                variant="primary"
                className="next-button next-button-birth-mobile-align"
                type="submit"
              >
                {t("COMMONS.NEXT")}
              </Button>
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
}

export default BirthCertificateInfo;
