import DateFormatter from "../../common/Date";

const BirthRecordFormat = (value) => {
  // eslint-disable-next-line default-case
  switch (value) {
    case "Birth Certificate-Full Size":
      return "Full Size";
    case "Birth Certificate-Standard Size":
      return "Standard Size";
    case "Birth Certificate-Heirloom (Flag Image)":
      return "Heirloom (Flag Image)";
    case "Birth Certificate-Heirloom (Bassinet Image)":
      return "Heirloom (Bassinet Image)";
  }
};

const GetFuneralHomeID = (value) => {
  let FuneralID = [];
  FuneralID = value.filter(
    (x) =>
      x.certificateInfoType === "deathCertificate" &&
      x.selectedRelation === "Funeral Home of Record"
  );
  return FuneralID.length > 0 ? FuneralID[0].funeralHomeID : "";
};

export const BindSaveOrder = (globalProps, validateRespone, props) => {
  var saveOrder = {
    orderNumber: "0",
    applicationId: "OVRA",
    agencyId: "DSHS",
    uniqueIdentifier: globalProps.uniqueIdentifier,
    requestorInfo: {
      firstName: globalProps.verifyIdentityData.firstName || "",
      middleName: globalProps.verifyIdentityData.middleName || "",
      lastName: globalProps.verifyIdentityData.lastName || "",
      birthDate: DateFormatter(
        globalProps.verifyIdentityData.dateOfBirth,
        "dd-mmm-yyyy"
      ),
      dlNumber: globalProps.verifyIdentityData.licenseNumber || "",
      ssn: globalProps.verifyIdentityData.socialSecNumber || "",
      issuantState: globalProps.verifyIdentityData.selectedState
        ? globalProps.verifyIdentityData.selectedState.value
        : "",
      funeralDirectorId: GetFuneralHomeID(globalProps.totalOrderRecords),
    },
    currentAddress: {
      addressLine1: globalProps.verifyIdentityData.streetAddress1 || "",
      addressLine2: globalProps.verifyIdentityData.streetAddress2 || "",
      city: globalProps.verifyIdentityData.city || "",
      state: globalProps.verifyIdentityData.selectedState
        ? globalProps.verifyIdentityData.selectedState.value
        : "",
      zip: globalProps.verifyIdentityData.zipCode || "",
      currentZipext: "",
    },
    previousAddress: {
      addressLine1: globalProps.verifyIdentityData.previousStreetAddress || "",
      addressLine2: globalProps.verifyIdentityData.previousStreetAddress2 || "",
      city: globalProps.verifyIdentityData.previousCity || "",
      state: globalProps.verifyIdentityData.previousSelectedState
        ? globalProps.verifyIdentityData.previousSelectedState.value
        : "",
      zip: globalProps.verifyIdentityData.previousZip || "",
      zipExt: "",
    },
    matchingInfo: {
      deathVerification:
        globalProps.verifyIdentityData.deathVerificationMatches || "N",
      fullName: globalProps.verifyIdentityData.ssnMatches || "N",
      addressName: globalProps.verifyIdentityData.addressLastNameMatches || "N",
      addressZipState:
        globalProps.verifyIdentityData.addressZipCodeMatches || "N",
      driverLicense: globalProps.verifyIdentityData.driverLicenseMatches || "N",
      ssnDate: globalProps.verifyIdentityData.ssnIssueDateMatches || "N",
    },
    shippingDetails: {
      phone: props.phoneNumber || "",
      phoneExt: "",
      email: props.email || "",
      firstName: props.firstName || "",
      middleName: props.middleName || "",
      lastName: props.lastName || "",
      type: validateRespone.shippingType || "",
      shipPhone: props.phoneNumber || "",
      shipPhoneExt: "",
      shipEmail: props.email || "",
    },
    shippingAddress: {
      addressLine1: props.streetAddress1 || "",
      addressLine2: props.streetAddress2 || "",
      city: props.city || "",
      state: props.selectedState ? props.selectedState.value : "",
      zip: props.zipCode || "",
      zipExt: "",
    },
    invShipping: "0",
    invTotal: "0",
    homeVisitDonation: globalProps.orderReview.donationChecked ? "Y" : "N",
  };
  return saveOrder;
};

export const BindBirthCertificate = (globalProps) => {
  var saveOrderDetails = [];
  if (
    globalProps.firstName !== "" &&
    globalProps.lastName !== "" &&
    globalProps.orderDetails.length > 0
  ) {
    globalProps.orderDetails.forEach(function (item) {
      var birthCertificate = {
        purpose: item.purposeList.value || "",
        firstName: globalProps.firstName || "",
        middleName: globalProps.middleName || "",
        lastName: globalProps.lastName || "",
        gender: globalProps.selectedGender.label
          ? globalProps.selectedGender.label.substring(0, 1)
          : "",
        birthDate: DateFormatter(globalProps.dateOfBirth, "dd-mmm-yyyy"),
        birthCity: globalProps.cityOfBirth || "",
        birthCounty: globalProps.countyOfBirth
          ? globalProps.countyOfBirth.value
          : "",
        birthState: globalProps.stateOfBirth
          ? globalProps.stateOfBirth.value
          : "",
        parent1Details: {
          parent1NameListed: globalProps.firstNameParent1 ? "Y" : "N",
          firstName: globalProps.firstNameParent1 || "",
          middleName: globalProps.middleNameParent1 || "",
          lastName: globalProps.lastNameParent1 || "",
        },
        parent2Details: {
          parent2NameListed: globalProps.firstNameParent2 ? "Y" : "N",
          firstName: globalProps.firstNameParent2 || "",
          middleName: globalProps.middleNameParent2 || "",
          lastName: globalProps.lastNameParent2 || "",
        },
        relationship: globalProps.selectedRelation.value || "",
        recordFormat: BirthRecordFormat(item.recordType),
        recordCopies: item.numberOfCopies || "",
      };
      saveOrderDetails.push(birthCertificate);
    });
  }
  return saveOrderDetails;
};

export const BindDeathCertificate = (globalDCProps) => {
  var deathCertificate = {};
  if (globalDCProps.firstName !== "" && globalDCProps.lastName !== "") {
    deathCertificate = {
      purpose: globalDCProps.purposeList.value || "",
      firstName: globalDCProps.firstName || "",
      middleName: globalDCProps.middleName || "",
      lastName: globalDCProps.lastName || "",
      gender: globalDCProps.selectedGender.label
        ? globalDCProps.selectedGender.label.substring(0, 1)
        : "",
      birthDate: DateFormatter(globalDCProps.dateOfBirth, "dd-mmm-yyyy"),
      birthCity: globalDCProps.cityOfBirth || "",
      birthCounty: globalDCProps.countyOfBirth || "",
      birthState: globalDCProps.stateOfBirth.value || "",
      birthCountry: globalDCProps.countryOfBirth,
      parent1Details: {
        firstName: globalDCProps.parent1FirstName || "",
        middleName: globalDCProps.parent1MiddleName || "",
        lastName: globalDCProps.parent1LastName || "",
      },
      parent2Details: {
        firstName: globalDCProps.parent2FirstName || "",
        middleName: globalDCProps.parent2MiddleName || "",
        lastName: globalDCProps.parent2LastName || "",
      },
      datestart: DateFormatter(globalDCProps.dateOfDeath, "dd-mmm-yyyy"),
      dateEnd: "",
      city: globalDCProps.city || "",
      county: globalDCProps.countyOfDeath.value || "",
      ssn: globalDCProps.ssn || "",
      recordCopies: globalDCProps.noOfCopies,
      relationship: globalDCProps.selectedRelation || "",
    };
  }
  return deathCertificate;
};

export const BindDeathVerification = (item) => {
  var deathVerification = {};
  if (item.firstName !== "" && item.lastName !== "") {
    deathVerification = {
      firstName: item.firstName || "",
      middleName: item.middleName || "",
      lastName: item.lastName || "",
      gender: item.selectedGender.value
        ? item.selectedGender.label.substring(0, 1)
        : "",
      birthDate: DateFormatter(item.dateOfBirth, "dd-mmm-yyyy"),
      birthCity: item.cityOfBirth || "",
      birthState: item.stateOfBirth.value ? item.stateOfBirth.value : "",
      birthCounty: item.countyOfBirth || "",
      parent1Details: {
        firstName: item.parent1FirstName || "",
        middleName: item.parent1MiddleName || "",
        lastName: item.parent1LastName || "",
      },
      parent2Details: {
        firstName: item.parent2FirstName || "",
        middleName: item.parent2MiddleName || "",
        lastName: item.parent2LastName || "",
      },

      datestart: DateFormatter(item.dateOfDeath, "dd-mmm-yyyy"),
      dateEnd: "",
      city: item.city,
      county: item.countyOfDeath.value ? item.countyOfDeath.value : "",
      ssn: item.ssn || "",
      recordCopies: item.noOfCopies || "",
    };
  }
  return deathVerification;
};

export const BindBirthVerification = (item) => {
  var birthVerification = {};
  if (item.firstName !== "" && item.lastName !== "") {
    birthVerification = {
      firstName: item.firstName || "",
      middleName: item.middleName || "",
      lastName: item.lastName || "",
      gender: item.selectedGender
        ? item.selectedGender.label.substring(0, 1)
        : "",
      birthDate: DateFormatter(item.dateOfBirth, "dd-mmm-yyyy"),
      birthCity: item.cityOfBirth || "",
      birthCounty: item.countyOfBirth ? item.countyOfBirth.value : "",
      parent1Details: {
        firstName: item.parent1FirstName || "",
        middleName: item.parent1MiddleName || "",
        lastName: item.parent1LastName || "",
      },
      parent2Details: {
        firstName: item.parent2FirstName || "",
        middleName: item.parent2MiddleName || "",
        lastName: item.parent2LastName || "",
      },
      recordCopies: item.noOfCopies || "",
    };
  }
  return birthVerification;
};

export const BindDivorceVerification = (item) => {
  var divorceVerification = {};
  if (item.spouse2FirstName !== "" && item.spouse2LastName !== "") {
    divorceVerification = {
      spouse2Details: {
        firstName: item.spouse2FirstName || "",
        middleName: item.spouse2MiddleName || "",
        lastName: item.spouse2LastName || "",
        dob: DateFormatter(item.spouse2DateOfBirth, "dd-mmm-yyyy"),
        age: item.spouse2Age || "",
      },
      spouse1Details: {
        firstName: item.spouse1FirstName || "",
        middleName: item.spouse1MiddleName || "",
        lastName: item.spouse1LastName || "",
        dob: DateFormatter(item.spouse1DateOfBirth, "dd-mmm-yyyy"),
        age: item.spouse1Age || "",
      },
      dateStart: DateFormatter(item.dateOfDivorce, "dd-mmm-yyyy"),
      dateEnd: "",
      city: item.cityOfDivorce || "",
      county: item.countyOfDivorce.value ? item.countyOfDivorce.value : "",
      recordCopies: item.noOfCopies || "",
    };
  }
  return divorceVerification;
};

export const BindMarriageVerification = (item) => {
  var marriageVerification = {};
  if (item.spouse2FirstName !== "" && item.spouse2LastName !== "") {
    marriageVerification = {
      spouse2Details: {
        firstName: item.spouse2FirstName || "",
        middleName: item.spouse2MiddleName || "",
        lastName: item.spouse2LastName || "",
        dob: DateFormatter(item.spouse2DateOfBirth, "dd-mmm-yyyy"),
        age: item.spouse2Age || "",
      },
      spouse1Details: {
        firstName: item.spouse1FirstName || "",
        middleName: item.spouse1MiddleName || "",
        lastName: item.spouse1LastName || "",
        dob: DateFormatter(item.spouse1DateOfBirth, "dd-mmm-yyyy"),
        age: item.spouse1Age || "",
      },
      dateStart: DateFormatter(item.dateOfMarriage, "dd-mmm-yyyy"),
      dateEnd: "",
      city: item.cityOfMarriage || "",
      county: item.countyOfMarriage.value ? item.countyOfMarriage.value : "",
      recordCopies: item.noOfCopies || "",
    };
  }
  return marriageVerification;
};
