import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "../../../components/pages/landingPage/LandingPage.scss";
import GetCanonicalUrl from "../../common/GetCanonicalUrl";
import { isMobileOnly } from 'react-device-detect';

function LandingPage() {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("LANDING_PAGE.TITLE")}</title>
        <meta name="description" content={t("LANDING_PAGE.META_CONTENT")} />
        <link
          rel="canonical"
          href={GetCanonicalUrl() + t("URLS.ORDER_VITAL_RECORDS_2")}
        />
      </Helmet>

      <Container className="vital-record-container">
        <div className="vital-top-line"></div>

        <h1 className="page-heading landing-padding">
          {t("LANDING_PAGE.HEADING")}
        </h1>
        {/* <h1 className="page-heading">{t("LANDING_PAGE.HEADING")}</h1> */}
        <div className="section-info">
          <p className="para-1">{t("LANDING_PAGE.PARA1")}</p>
          <p className="para-2">
            {t("LANDING_PAGE.PARA2SPLIT1")}{" "}
            <a
              rel={"external"}
              className="link-color"
              href={
                "https://www.cdc.gov/nchs/w2w/index.htm?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Fnchs%2Fw2w.htm"
              }
              target="blank"
            >
              {t("LANDING_PAGE.PARA2SPLIT2")}
            </a>
          </p>
          <p className="para-3">{t("LANDING_PAGE.PARA3")}</p>

          <div className="para-5">
            <p className="para-space-align">{t("LANDING_PAGE.PARA5")}</p>
            <ul>
              <li>{t("LANDING_PAGE.PARA5OPT1")}</li>
              <li>{t("LANDING_PAGE.PARA5OPT2")}</li>
              <li>{t("LANDING_PAGE.PARA5OPT3")} </li>
              <li>{t("LANDING_PAGE.PARA5OPT4")}</li>
            </ul>
          </div>
          <p className="para-4">
            <b>{t("LANDING_PAGE.PARA4Split1")}</b>
            {t("LANDING_PAGE.PARA4Split2")}
          </p>
          <div className="para-5">
            <p className="para-space-align">{t("LANDING_PAGE.PARA7")}</p>
            <ul>
              <li>{t("LANDING_PAGE.PARA7OPT1")}</li>
              <li>{t("LANDING_PAGE.PARA7OPT2")}</li>
            </ul>
          </div>
          <p className="para-6">{t("LANDING_PAGE.PARA6")}</p>
          <div className="para-8">
            <p className="para-space-align">
              {t("LANDING_PAGE.PARA8")}
              <a
                rel={"external"}
                className="link-color"
                target="blank"
                href="https://dshs.texas.gov/vs/faq/"
              >
                {t("LANDING_PAGE.PARA8OPT1")}
                <br />
              </a>
            </p>
          </div>
          <Row className="button-group" sm={1} xs={1} md={2} lg={2}>
            <Col>
              <a
                className="button-secondary landing-check-order"
                href={t("URLS.VITAL_RECORD_ORDER_STATUS")}
              >
                {t("LANDING_PAGE.CHECKOUTBUTTON")}
              </a>
            </Col>
            <Col xs={{ order: isMobileOnly ? 'first' : 'last' }}>
              <a
                className="button-primary landing-page-button"

                href={t("URLS.SELECT_VITAL_RECORD")}
              >
                {t("LANDING_PAGE.GETSTARTED")}
              </a>
            </Col>
          </Row>
        </div>

        <div className="height-fix-mobile"></div>
      </Container>
    </div>
  );
}

export default LandingPage;
