import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col } from "react-bootstrap";
import Progress from "../../Progress";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import GetApiUrl from "../../../../../src/components/common/GetApiUrl";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { FormInput } from "../../../common/FormInput";
import "./VerifyIdentity.scss";
import { BindState } from "./VerifyIdentityHelper";
import DL1 from "../../../../assets/DL_1.jpg";
import DL2 from "../../../../assets/DL_2.jpg";
import DL3 from "../../../../assets/DL_es1.jpg";
import DL4 from "../../../../assets/DL_es2.jpg";
import useGlobal from "../../../../store";
import alert from "../../../../assets/alert-warning@1x.png";
import close from "../../../../assets/close.svg";
import history from "../../../common/history";
import DateFormatter from "../../../common/Date";
import { trackPromise } from "react-promise-tracker";
import { Spinner } from "../../../common/spinner";
import getSitekey from "../../GetSiteKey";
import { Error } from "../../../common/index";
import MaskedInput from "react-maskedinput";
import { isMobile, isMobileOnly } from "react-device-detect";

function VerifyIdentityInState(props) {
  const stateList = props.stateList;
  const [showError, setShowError] = useState(false);
  const [showServiceDownError, setShowServiceDownError] = useState(false);
  const [selectedState, setSelectedState] = useState({
    label: "",
    value: "",
  });
  const { t } = useTranslation();
  const SignupSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(t("VERIFY_IDENTITY_ERROR_MSG.FIRST_NAME"))
      .matches(
        /(?=^.{1,50}$)^([A-Za-z])([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("VERIFY_IDENTITY_ERROR_MSG.FIRST_NAME_SP")
      ),
    lastName: yup
      .string()
      .required(t("VERIFY_IDENTITY_ERROR_MSG.LAST_NAME"))
      .matches(
        /(?=^.{2,50}$)^([A-Za-z])([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("VERIFY_IDENTITY_ERROR_MSG.LAST_NAME_SP")
      ),
    middleName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("VERIFY_IDENTITY_ERROR_MSG.MIDDLE_NAME_SP")
      ),
    socialSecNumber: yup
      .string()
      .required(t("VERIFY_IDENTITY_ERROR_MSG.SOCIAL_SEC_NUMBER"))
      .matches(
        /^(?!(0000))\d{4}$/,
        t("VERIFY_IDENTITY_ERROR_MSG.SOCIAL_SEC_NUMBER_INVALID")
      ),
    dateOfBirth: yup
      .date()
      .required(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE"))
      .typeError(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE")),
    licenseNumber: yup
      .string()
      .required(t("VERIFY_IDENTITY_ERROR_MSG.LICENSE_NUMBER"))
      .min(8, t("VERIFY_IDENTITY_ERROR_MSG.LICENSE_NUMBER"))
      .max(10, t("VERIFY_IDENTITY_ERROR_MSG.LICENSE_NUMBER"))
      .matches(
        /^[_0-9]*((-|\s)*[_0-9])*$/,
        t("VERIFY_IDENTITY_ERROR_MSG.LICENSE_NUMBER_SP")
      ),
    auditNumber: yup
      .string()
      .required(t("VERIFY_IDENTITY_ERROR_MSG.AUDIT_NUMBER"))
      .min(11, t("VERIFY_IDENTITY_ERROR_MSG.AUDIT_NUMBER"))
      .max(20, t("VERIFY_IDENTITY_ERROR_MSG.AUDIT_NUMBER"))
      .matches(
        /^[_0-9]*((-|\s)*[_0-9])*$/,
        t("VERIFY_IDENTITY_ERROR_MSG.AUDIT_NUMBER_SP")
      ),
    city: yup
      .string()
      .required(t("VERIFY_IDENTITY_ERROR_MSG.CITY"))
      .matches(/^[a-zA-Z ]*$/, t("VERIFY_IDENTITY_ERROR_MSG.CITY_SP")),
    streetAddress1: yup
      .string()
      .required(t("VERIFY_IDENTITY_ERROR_MSG.STREET_ADDRESS1")),
    zipCode: yup
      .string()
      .required(t("VERIFY_IDENTITY_ERROR_MSG.ZIP_CODE"))
      .min(5, t("VERIFY_IDENTITY_ERROR_MSG.ZIP_CODE"))
      .max(5, t("VERIFY_IDENTITY_ERROR_MSG.ZIP_CODE"))
      .matches(
        /^[_0-9]*((-|\s)*[_0-9])*$/,
        t("VERIFY_IDENTITY_ERROR_MSG.ZIP_CODE_SP")
      ),
    previousCity: yup
      .string()
      .matches(
        /^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/,
        t("VERIFY_IDENTITY_ERROR_MSG.CITY_SP")
      ),
    previousZip: yup
      .string()
      .matches(
        /^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/,
        <span className="error-color">
          {t("VERIFY_IDENTITY_ERROR_MSG.ZIP_CODE_SP")}
        </span>
      ),
  });

  const { register, handleSubmit, control, errors, setValue } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(SignupSchema),
  });

  const siteKey = getSitekey();
  const actionName = "inState";
  const [globalState, globalActions] = useGlobal();

  //DMND0011685 - CaptchaToken Generation and form submission
  const generateCaptchaTokenAndSubmitForm = async (data) =>{
    
    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise
        .execute(siteKey, { action: 'submit' })
        .then((token) => {
          data.token=token;
          onSubmit(data);
        })
        .catch((err) => {
          
        });
    });
  }
//DMND0011685 - ------------End-----------------------------

  const onSubmit = async (data) => {
    data.selectedState = selectedState;
    globalActions.addVerifyIdentity(data);
    getToas(data.token); 
  };

  useEffect(() => {
    var index = props.flowIndex;
    setSelectedState(BindState(index, globalState));

    if (
      globalState.getStartedBirthCertificateData.selectedRelation !==
      undefined &&
      globalState.getStartedBirthCertificateData.selectedRelation.label ===
      "Self" &&
      globalState.getStartedBirthCertificateData.dateOfBirth !== undefined
    ) {
      setValue(
        "dateOfBirth",
        globalState.getStartedBirthCertificateData.dateOfBirth
      );
    }
  }, []);
  const getToas = (token) => {
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    trackPromise(
      axios
        .post(GetApiUrl() + "/v1/tcas/ovra/toas", {
          dl_number: globalState.verifyIdentityData.licenseNumber,
          dob: DateFormatter(
            globalState.verifyIdentityData.dateOfBirth,
            "mmddyyyy"
          ),
          token: token,
          last_four_ssn: globalState.verifyIdentityData.socialSecNumber,
          audit_number: globalState.verifyIdentityData.auditNumber,
          comment_field: "OVRA transaction",
          uniqueIdentifier: globalState.uniqueIdentifier,
          applicationId: "OVRA",
          agencyId: "DSHS",
          gaClientId: localStorage.getItem('gaClientId')
        })
        .then(function (response) {
          globalActions.addJwtToken(response.data.jwtToken);
          sessionStorage.setItem("jwtToken", response.data.jwtToken);
          globalState.verifyIdentityData.ssnMatches = null;
          globalState.verifyIdentityData.deathVerificationMatches = null;
          globalState.verifyIdentityData.addressLastNameMatches = null;
          globalState.verifyIdentityData.addressZipCodeMatches = null;
          globalState.verifyIdentityData.ssnIssueDateMatches = null;
          if (response.data.match === true) {
            globalState.verifyIdentityData.driverLicenseMatches = "Y";
            globalState.verifyIdentityData.idVerified = "Y";
            props.nextCallback(3);
          } else {
            globalState.verifyIdentityData.driverLicenseMatches = "N";
            globalState.verifyIdentityData.idVerified = "N";
            if (response.data.error_code === "202") {
              window.scrollTo(0, 0);
              history.push(t("URLS.LOCKOUT_PAGE"));
            } else {
              setShowError(true);
            }
          }
        })
    ).catch(function (error) {
      console.log("Do failure action", error);
      setShowError(true);
      setShowServiceDownError(true);
    });
  };

  return (
    <>
      {!showServiceDownError && (
        <div>
          <Progress currentStep={1} totalSteps={4} />

          <form
            className="verify-content-section"
            onSubmit={handleSubmit(generateCaptchaTokenAndSubmitForm)}
          >
            <div>
              <h1 className="verify-heading">
                {t("VERIFY_IDENTITY_INFORMATION.HEADING")}
              </h1>
              <label className="verify-description">
                {t("VERIFY_IDENTITY_INFORMATION.DESCRIPTION")}
              </label>
            </div>
            <div className="personal-information-div">
              <label className="personal-information">
                {t("VERIFY_IDENTITY_INFORMATION.PERSONAL_INFORMATION")}
              </label>

              <div className="input-align">
                <label htmlFor="firstName" className="name">
                  {t("VERIFY_IDENTITY_INFORMATION.FIRST_NAME")}
                </label>
                <input
                  className={`answer ${errors.firstName ? "error-red" : ""}`}
                  id="firstName"
                  type="text"
                  name="firstName"
                  ref={register}
                  rules={{ required: true }}
                  defaultValue={
                    globalState.verifyIdentityData.firstName
                      ? globalState.verifyIdentityData.firstName
                      : ""
                  }
                />
                {errors.firstName && (
                  <p className="error-red-content">
                    {errors.firstName.message}
                  </p>
                )}
              </div>

              <div className="input-align">
                <label htmlFor="middleName" className="name">
                  {t("VERIFY_IDENTITY_INFORMATION.MIDDLE_NAME")}
                  <span className="optional-text">{t("COMMONS.OPTIONAL")}</span>
                </label>
                <input
                  className={`answer ${errors.middleName ? "error-red" : ""}`}
                  id="middleName"
                  type="text"
                  name="middleName"
                  ref={register}
                  defaultValue={
                    globalState.verifyIdentityData.middleName
                      ? globalState.verifyIdentityData.middleName
                      : ""
                  }
                />
                {errors.middleName && (
                  <p className="error-red-content">
                    {errors.middleName.message}
                  </p>
                )}
              </div>

              <div className="input-align">
                <label htmlFor="lastName" className="name">
                  {t("VERIFY_IDENTITY_INFORMATION.LAST_NAME")}
                </label>
                <input
                  className={`answer ${errors.lastName ? "error-red" : ""}`}
                  id="lastName"
                  type="text"
                  name="lastName"
                  ref={register}
                  rules={{ required: true }}
                  defaultValue={
                    globalState.verifyIdentityData.lastName
                      ? globalState.verifyIdentityData.lastName
                      : ""
                  }
                />
                {errors.lastName && (
                  <p className="error-red-content">{errors.lastName.message}</p>
                )}
              </div>

              <label htmlFor="dateOfBirth" className="birth-content">
                {t("VERIFY_IDENTITY_INFORMATION.DATE_BIRTH")}
              </label>
              <div className="DoB-date">
                <Controller
                  control={control}
                  name="dateOfBirth"
                  className="input-date"
                  defaultValue={globalState.verifyIdentityData.dateOfBirth}
                  rules={{ required: true }}
                  render={({ onChange, onBlur, value }) => (
                    globalState.getStartedBirthCertificateData
                      .selectedRelation !== undefined &&
                      globalState.getStartedBirthCertificateData.selectedRelation
                        .label === "Self" ? (
                        <ReactDatePicker
                          disabled
                          disabledKeyboardNavigation
                          className={`input-date get-state-birth`}
                          id="dateOfBirth"
                          selected={value}
                        />
                      ) : (
                        <ReactDatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          id="dateOfBirth"
                          className={`input-date get-state-birth ${errors.dateOfBirth ? "error-red" : ""
                            }`}
                          selected={value}
                          showMonthDropdown
                          showYearDropdown
                          open={isMobile ? false : undefined}
                          customInput={<MaskedInput mask="11/11/1111" />}
                          placeholderText="00/00/0000"
                        />
                      )
                  )
                  }
                />
                {errors.dateOfBirth && (
                  <p className="error-red-content">
                    {errors.dateOfBirth.message}
                  </p>
                )}
              </div>

              <div className="input-align">
                <label htmlFor="socialSecNumber" className="name">
                  {t("VERIFY_IDENTITY_INFORMATION.SSN_Last_four")}
                </label>
                <input
                  className={`answer ${errors.socialSecNumber ? "error-red" : ""
                    }`}
                  id="socialSecNumber"
                  type="number"
                  name="socialSecNumber"
                  ref={register}
                  rules={{ required: true }}
                  defaultValue={
                    globalState.verifyIdentityData.socialSecNumber
                      ? globalState.verifyIdentityData.socialSecNumber
                      : ""
                  }
                />
                {errors.socialSecNumber && (
                  <p className="error-red-content">
                    {errors.socialSecNumber.message}
                  </p>
                )}
              </div>

              <div className="input-align">
                <label htmlFor="licenseNumber" className="name">
                  {t("VERIFY_IDENTITY_INFORMATION.LICENSE_NUMBER")}
                </label>
                <input
                  className={`answer ${errors.licenseNumber ? "error-red" : ""
                    }`}
                  id="licenseNumber"
                  type="number"
                  name="licenseNumber"
                  ref={register}
                  rules={{ required: true }}
                  defaultValue={
                    globalState.verifyIdentityData.licenseNumber
                      ? globalState.verifyIdentityData.licenseNumber
                      : ""
                  }
                />
                {errors.licenseNumber && (
                  <p className="error-red-content">
                    {errors.licenseNumber.message}
                  </p>
                )}
              </div>

              <div className="input-align">
                <label htmlFor="auditNumber" className="name">
                  {t("VERIFY_IDENTITY_INFORMATION.AUDIT_NUMBER")}
                </label>
                <input
                  className={`answer ${errors.auditNumber ? "error-red" : ""}`}
                  id="auditNumber"
                  type="number"
                  name="auditNumber"
                  ref={register}
                  rules={{ required: true }}
                  defaultValue={
                    globalState.verifyIdentityData.auditNumber
                      ? globalState.verifyIdentityData.auditNumber
                      : ""
                  }
                />
                {errors.auditNumber && (
                  <p className="error-red-content">
                    {errors.auditNumber.message}
                  </p>
                )}
              </div>
            </div>
            {window.location.href.indexOf("/es/") === -1 ? (
              <>
                <div className="personal-information-div">
                  <h1 className="texas-driver-license">
                    {t("VERIFY_IDENTITY_INFORMATION.DL_AUDIT_NUMBER")}
                  </h1>
                  <p className="texas-dl-description">
                    {t("VERIFY_IDENTITY_INFORMATION.PREVIOUS_DESIGN")}
                  </p>
                  <div>
                    <img className="texas-dl-visual" src={DL1} alt="texas DL" />
                  </div>
                  <p className="real-id-2020">
                    {t("VERIFY_IDENTITY_INFORMATION.2020_DESIGN")}
                  </p>
                  <img className="texas-dl-visual" src={DL2} alt="texas logo" />
                </div>
              </>
            ) : (
                <>
                  <div className="personal-information-div">
                    <h1 className="texas-driver-license">
                      {t("VERIFY_IDENTITY_INFORMATION.DL_AUDIT_NUMBER")}
                    </h1>
                    <p className="texas-dl-description">
                      {t("VERIFY_IDENTITY_INFORMATION.PREVIOUS_DESIGN")}
                    </p>
                    <div>
                      <img className="texas-dl-visual" src={DL3} alt="texas DL" />
                    </div>
                    <p className="real-id-2020">
                      {t("VERIFY_IDENTITY_INFORMATION.2020_DESIGN")}
                    </p>
                    <img className="texas-dl-visual" src={DL4} alt="texas logo" />
                  </div>
                </>
              )}
            <div className="new-address-copy">
              <h1 className="texas-driver-license">
                {t("VERIFY_IDENTITY_INFORMATION.CURRENT_ADDRESS")}
              </h1>
              <label htmlFor="streetAddress1" className="name">
                {t("VERIFY_IDENTITY_INFORMATION.STREET_ADDRESS")}
              </label>
              <div>
                <input
                  className={`answer ${errors.streetAddress1 ? "error-red" : ""
                    }`}
                  type="text"
                  id="streetAddress1"
                  name="streetAddress1"
                  ref={register}
                  rules={{ required: true }}
                  defaultValue={
                    globalState.verifyIdentityData.streetAddress1
                      ? globalState.verifyIdentityData.streetAddress1
                      : ""
                  }
                />
                {errors.streetAddress1 && (
                  <p className="error-red-content">
                    {errors.streetAddress1.message}
                  </p>
                )}

                <input
                  className={`bg1 answer ${errors.streetAddress2 ? "error-red" : ""
                    }`}
                  type="text"
                  id="streetAddress1"
                  name="streetAddress2"
                  ref={register}
                  rules={{ required: true }}
                  defaultValue={
                    globalState.verifyIdentityData.streetAddress2
                      ? globalState.verifyIdentityData.streetAddress2
                      : ""
                  }
                />
              </div>
              <div className="text-field-city">
                <label htmlFor="city" className="name">{t("VERIFY_IDENTITY_INFORMATION.CITY")}</label>
                <input
                  className={`answer ${errors.city ? "error-red" : ""}`}
                  id="city"
                  type="text"
                  name="city"
                  ref={register}
                  rules={{ required: true }}
                  defaultValue={
                    globalState.verifyIdentityData.city
                      ? globalState.verifyIdentityData.city
                      : ""
                  }
                />
                {errors.city && (
                  <p className="error-red-content">{errors.city.message}</p>
                )}
              </div>
              <FormInput
                id="selectedState"
                name="selectedState"
                className="answer"
                control={control}
                label={t("VERIFY_IDENTITY_INFORMATION.STATE")}
                disabled={true}
                type="text"
                defaultValue={selectedState.label}
              />

              <div className="text-field-2">
                <label htmlFor="zipCode" className="name">
                  {t("VERIFY_IDENTITY_INFORMATION.ZIP")}
                </label>
                <div>
                  <input
                    className={`bgZip answer ${errors.zipCode ? "error-red" : ""
                      }`}
                    id="zipCode"
                    type="text"
                    name="zipCode"
                    ref={register}
                    rules={{ required: true }}
                    defaultValue={
                      globalState.verifyIdentityData.zipCode
                        ? globalState.verifyIdentityData.zipCode
                        : ""
                    }
                  />
                  {errors.zipCode && (
                    <p className="error-red-content">{errors.zipCode.message}</p>
                  )}
                </div>
              </div>
            </div>

            <h1 className="texas-driver-license">
              {t("VERIFY_IDENTITY_INFORMATION.PREVIOUS_ADDRESS")}
            </h1>
            <p className="texas-dl-description">
              {t("VERIFY_IDENTITY_INFORMATION.PREVIOUS_ADDRESS_MSG")}
            </p>
            <div className="cc-form">
              <div className="text-field">
                <label htmlFor="previousStreetAddress" className="name">
                  {t("VERIFY_IDENTITY_INFORMATION.STREET_ADDRESS")}
                </label>
                <div>
                  <input
                    className={`answer ${errors.previousStreetAddress ? "error-red" : ""
                      }`}
                    id="previousStreetAddress"
                    type="text"
                    name="previousStreetAddress"
                    ref={register}
                    defaultValue={
                      globalState.verifyIdentityData.previousStreetAddress
                        ? globalState.verifyIdentityData.previousStreetAddress
                        : ""
                    }
                  />
                  {errors.previousStreetAddress && (
                    <p className="error-red-content">
                      {errors.previousStreetAddress.message}
                    </p>
                  )}
                  <input
                    className={`bg1 answer ${errors.previousStreetAddress2 ? "error-red" : ""
                      }`}
                    type="text"
                    id="previousStreetAddress"
                    name="previousStreetAddress2"
                    ref={register}
                    defaultValue={
                      globalState.verifyIdentityData.previousStreetAddress2
                        ? globalState.verifyIdentityData.previousStreetAddress2
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="text-field-city">
                <label htmlFor="previousCity" className="name">
                  {t("VERIFY_IDENTITY_INFORMATION.CITY")}
                </label>
                <div>
                  <input
                    className={`answer ${errors.previousCity ? "error-red" : ""
                      }`}
                    id="previousCity"
                    type="text"
                    name="previousCity"
                    ref={register}
                    defaultValue={
                      globalState.verifyIdentityData.previousCity
                        ? globalState.verifyIdentityData.previousCity
                        : ""
                    }
                  />
                  {errors.previousCity && (
                    <p className="error-red-content">
                      {errors.previousCity.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="text-field-copy-3">
                <label className="name">
                  {t("VERIFY_IDENTITY_INFORMATION.STATE")}
                  <Controller
                    name="previousSelectedState"
                    className={`bgState ${errors.previousSelectedState ? "error-red" : ""
                      }`}
                    as={Select}
                    options={stateList}
                    control={control}
                    defaultValue={
                      globalState.verifyIdentityData.previousSelectedState.label
                        ? globalState.verifyIdentityData.previousSelectedState
                        : ""
                    }
                  />
                  {errors.previousSelectedState && (
                    <p className="error-red-content">
                      {t("VERIFY_IDENTITY_ERROR_MSG.STATE_ERROR")}
                    </p>
                  )}
                </label>
              </div>
              <div className="text-field-2">
                <label htmlFor="previousZip" className="name">
                  {t("VERIFY_IDENTITY_INFORMATION.ZIP")}
                </label>
                <div>
                  <input
                    className={`bgZip answer ${errors.previousZip ? "error-red" : ""
                      }`}
                    id="previousZip"
                    type="text"
                    name="previousZip"
                    ref={register}
                    defaultValue={
                      globalState.verifyIdentityData.previousZip
                        ? globalState.verifyIdentityData.previousZip
                        : ""
                    }
                  />
                  {errors.previousZip && (
                    <p className="error-red-content">
                      {errors.previousZip.message}
                    </p>
                  )}
                </div>
              </div>
              {showError && (
                <Row className="verify-error-message">
                  <Col
                    sm={2}
                    xs={2}
                    md={2}
                    lg={2}
                    className="verify-alert-icon"
                  >
                    <img className="verify-alert" src={alert} alt="alert" />
                  </Col>
                  <Col
                    className="verify-error-content"
                    sm={8}
                    xs={8}
                    md={8}
                    lg={8}
                  >
                    <p className="verify-error-text">
                      {t("UNABLE_TO_VERIFY.UNABLE_TO_VERIFY_ERROR")}
                    </p>
                    <p className="verify-error-desc">
                      {t("UNABLE_TO_VERIFY.UNABLE_TO_VERIFY_IDENTITY_ERROR")}
                    </p>
                  </Col>
                  <Col className="verify-close" sm={2} xs={2} md={2} lg={2}>
                    <img
                      src={close}
                      alt="close button"
                      onClick={() => setShowError(false)}
                    />
                  </Col>
                </Row>
              )}
              <Spinner />
              <Row className="btn-group-align" sm={1} xs={1} md={2} lg={2}>
                <Col>
                  <Button
                    variant="outline-primary"
                    className="back-button-verify"
                    onClick={() => props.prevCallback()}
                  >
                    {t("COMMONS.BACK")}
                  </Button>
                </Col>
                <Col xs={{ order: isMobileOnly ? "first" : "last" }}>
                  <Button
                    variant="primary"
                    className="next-button-verify"
                    type="submit"
                  >
                    {t("COMMONS.NEXT")}
                  </Button>
                </Col>
              </Row>
            </div>
          </form>
        </div>
      )}
      {showServiceDownError && (
        <Error errorReference={globalState.uniqueIdentifier} />
      )}
    </>
  );
}

export default VerifyIdentityInState;
