import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import "./BirthVerificationPage.scss";
import history from "./../../common/history";
import { BirthVerification, GetStartedBirthVerification } from "../../common";
import VerifyIdentityInState from "../../common/forms/verifyIdentity/VerifyIdentityInState";
import VerifyIdentityOutOfState from "../../common/forms/verifyIdentity/VerifyIdentityOutOfState";

import { useTranslation } from "react-i18next";
import useGlobal from "../../../store";
import uuid from "react-native-uuid";
import GetCanonicalUrl from "../../common/GetCanonicalUrl";
import {GetStateData,GetCountyData} from "referencedata";

function BirthVerificationPage() {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [globalState, globalActions] = useGlobal();
  const [arrayIndex, setArrayIndex] = useState("");

  const nextStep = (value) => {
    window.scrollTo(0, 0);
    value ? setCurrentStep(value) : setCurrentStep(currentStep + 1);

    value
      ? setUrlForForm("birthVerify", value)
      : setUrlForForm("birthVerify", currentStep + 1);
  };
  const prevStep = (value) => {
    window.scrollTo(0, 0);
    value ? setCurrentStep(value) : setCurrentStep(currentStep - 1);
    value
      ? setUrlForForm("birthVerify", value)
      : setUrlForForm("birthVerify", currentStep - 1);
  };

  const setUrlForForm = (record, step) => {
    if (record === "birthVerify") {
      switch (step) {
        case 0:
          history.push(t("URLS.ORDER_BIRTH_VERIFICATION_LETTER"));
          break;
        case 1:
          history.push(
            t("URLS.ORDER_BIRTH_VERIFICATION_LETTER") +
              t("URLS.VERIFY_IDENTITY_IN_STATE")
          );

          break;
        case 2:
          history.push(
            t("URLS.ORDER_BIRTH_VERIFICATION_LETTER") +
              t("URLS.VERIFY_IDENTITY_OUT_STATE")
          );
          break;
        case 3:
          history.push(
            t("URLS.ORDER_BIRTH_VERIFICATION_LETTER") +
              t("URLS.BIRTH_VERIFICATION_INFORMATION")
          );
          break;
        default:
          history.push(t("URLS.ORDER_BIRTH_VERIFICATION_LETTER"));
      }
    }
  };
  const firstStep = (value) => {
    window.scrollTo(0, 0);
    value ? setCurrentStep(value) : setCurrentStep(0);
    value
      ? setUrlForForm("birthVerify", value)
      : setUrlForForm("birthVerify", 0);
  };

  useEffect(() => {
    if (!globalState.uniqueIdentifier) {
      globalActions.addUniqueIdentifier("OVRAREF_ID-" + uuid.v1());
    }
    globalActions.clearGlobalState();
    if (
      globalState.saveEditIndex.arrayIndex !== "" &&
      globalState.saveEditIndex.referenceType === "birthVerification"
    ) {
      setArrayIndex(globalState.saveEditIndex.arrayIndex);
      globalState.getStartedBirthVerificationInfo.dateOfBirth = new Date(
        globalState.totalOrderRecords[
          globalState.saveEditIndex.arrayIndex
        ].dateOfBirth
      );
      globalState.getStartedBirthVerificationInfo.selectedState =
        globalState.totalOrderRecords[
          globalState.saveEditIndex.arrayIndex
        ].selectedState;
      globalState.birthVerificationInfo =
        globalState.totalOrderRecords[globalState.saveEditIndex.arrayIndex];
    }
  }, []);

  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t("BIRTH_VERIFICATION_INFO.TITLE")}</title>
          <meta
            name="description"
            content={t("BIRTH_VERIFICATION_INFO.META_CONTENT")}
          />
          <link
            rel="canonical"
            href={
              GetCanonicalUrl() + t("URLS.ORDER_BIRTH_VERIFICATION_LETTER_2")
            }
          />
        </Helmet>
        <Container className="birthverify-container">
          {currentStep === 0 && (
            <GetStartedBirthVerification
              stateList={GetStateData()}
              nextCallback={nextStep}
            />
          )}
          {currentStep === 1 && (
            <VerifyIdentityInState
              stateList={GetStateData()}
              nextCallback={nextStep}
              prevCallback={prevStep}
              flowIndex="birthVerification"
            />
          )}
          {currentStep === 2 && (
            <VerifyIdentityOutOfState
              stateList={GetStateData()}
              nextCallback={nextStep}
              prevCallback={firstStep}
              flowIndex="birthVerification"
            />
          )}

          {currentStep === 3 && (
            <BirthVerification
              countyList={GetCountyData()}
              stateList={GetStateData()}
              nextCallback={nextStep}
              editIndex={arrayIndex}
            />
          )}
        </Container>
      </div>
    </>
  );
}

export default BirthVerificationPage;
