function DateFormatter(date, format) {
  if (date !== undefined && date !== "") {
    var dateFormatter = new Date(date);
    let options = {};
    if (format !== undefined && format !== "") {
      if (format === "dd-mmm-yyyy") {
        options = { year: "numeric", month: "short", day: "2-digit" };
      } else if (format === "dd-mmm-yy") {
        options = { year: "2-digit", month: "short", day: "2-digit" };
      } else if (format === "mmddyyyy" || format === "mm/dd/yyyy") {
        options = { year: "numeric", month: "2-digit", day: "2-digit" };
      } else {
        options = { year: "numeric", month: "2-digit", day: "2-digit" };
      }
    } else {
      options = { year: "numeric", month: "2-digit", day: "2-digit" };
    }

    let ye = new Intl.DateTimeFormat("en", { year: options.year }).format(
      dateFormatter
    );
    let mo = new Intl.DateTimeFormat("en", { month: options.month }).format(
      dateFormatter
    );
    let da = new Intl.DateTimeFormat("en", { day: options.day }).format(
      dateFormatter
    );
    da = da.replace(/\u200e/g, "");
    mo = mo.replace(/\u200e/g, "");
    ye = ye.replace(/\u200e/g, "");

    // console.log("FORMAT DATE FROM ", da, mo, ye);
    if (format !== undefined && format !== "") {
      if (format === "dd-mmm-yyyy" || format === "dd-mmm-yy")
        return da + "-" + mo + "-" + ye;
      else if (format === "mmddyyyy") {
        return mo + da + ye;
      } else if (format === "mm/dd/yyyy") {
        return mo + "/" + da + "/" + ye;
      } else {
        return mo + "-" + da + "-" + ye;
      }
    } else {
      return mo + "-" + da + "-" + ye;
    }
  } else {
    return "";
  }
}

export default DateFormatter;
