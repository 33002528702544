import axios from "axios";
import GetApiUrl from "../../src/components/common/GetApiUrl"
export default function PostCall(Url,requestObject) {
  axios.defaults.headers.post["Content-Type"] =
    "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  if(Url.indexOf("StateList")===-1 && Url.indexOf("RelationshipList")===-1 && Url.indexOf("CountyList")===-1)
  {    axios.defaults.headers.post["Authorization"] =
  "Bearer "+sessionStorage.getItem("jwtToken"); }
  return axios
    .post(
        GetApiUrl()+Url,
        requestObject
    )
    .then((res) => res);
}
