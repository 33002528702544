import { useTranslation } from "react-i18next";
import uuid from "react-native-uuid";

export default function GenderList() {
  const { t } = useTranslation();
  const genderList = [
    { value: "male", label: t("GENDER_LIST.MALE") },
    { value: "female", label: t("GENDER_LIST.FEMALE") },
  ];
  return genderList
}

export function ShippingType() {
  const { t } = useTranslation();
 const shippingType = [
 //Updated 11/11/2021 by Justin Young for SCTASK0321740 Start
  { value: "First Class Mail", label: t("SHIPPING_TYPE.STANDARD_SHIPPING") },
  { value: "Express", label: t("SHIPPING_TYPE.EXPRESS_SHIPPING")},
  //Updated 11/11/2021 by Justin Young for SCTASK0321740 End
];
return shippingType;
}

export function IDGenerator() {
  //changed from Math.Random to UUID for Security Issue.
  return uuid.v1();
};