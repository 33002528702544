export const GetOrderStatusAmount = (OrderReviewProps, ResponseProps) => {
  let OrderReviewResponse = [];
  OrderReviewProps.forEach(function (element) {
    element.recordDetails.forEach(function (recordItem) {
      ResponseProps.forEach(function (item) {
        if (item.id === recordItem.id) {
          element.amount = parseFloat(element.amount) + parseFloat(item["amount"]);
        }
      });
    });
    OrderReviewResponse.push(element);
  });
  return OrderReviewResponse;
};
