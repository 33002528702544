import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DeathVerification.scss";
import useGlobal from "../../../../store";
import { FormInput, FormInputOptional } from "../../../common/FormInput";
import GenderList from "../../CommonList";
import { NumberField, SelectFieldOptional } from "../../../common/SelectField";
import DateFormatter from "./../../../common/Date";
import Progress from "../../Progress";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import { IDGenerator } from "../../../common/CommonList";
import history from "./../../../common/history";
import MaskedInput from "react-maskedinput";
import { isMobile, isMobileOnly } from "react-device-detect";

function GetStartedDeathVerification(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const [showParentChecked, setShowParentChecked] = useState(false);
  const countyList = props.countyList;
  const stateList = props.stateList;

  const SignupSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(t("COMMON_ERROR_MSG.FIRST_NAME_MISSING_ERROR"))
      .matches(
        /(?=^.{1,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("COMMON_ERROR_MSG.FIRST_NAME_INVALID_ENTRY")
      ),
    middleName: yup
      .string()
      .notRequired()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.MIDDLE_NAME_INVALID_ENTRY")
      ),
    lastName: yup
      .string()
      .required(t("COMMON_ERROR_MSG.LAST_NAME_MISSING_ERROR"))
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*()_+=?/.,<>|;:]*)([-]*[A-Za-z])+$/,
        t("COMMON_ERROR_MSG.LAST_NAME_INVALID_ENTRY")
      ),
    selectedGender: yup.object().shape({
      label: yup.string().required(t("COMMON_ERROR_MSG.GENDER_ERROR")),

      value: yup.string().required(t("COMMON_ERROR_MSG.GENDER_ERROR")),
    }),
    city: yup
      .string()
      .required(t("DEATH_CERTIFICATE_INFO.CITY_OF_DEATH"))
      .matches(/^[a-zA-Z ]*$/, t("COMMON_ERROR_MSG.CITY_INVALID_ENTRY")),
    countyOfDeath: yup.object().shape({
      label: yup.string().required(t("COMMON_ERROR_MSG.COUNTY_DEATH")),

      value: yup.string().required(t("COMMON_ERROR_MSG.COUNTY_DEATH")),
    }),
    cityOfBirth: yup
      .string()
      .matches(/^[a-zA-Z ]*$/, t("COMMON_ERROR_MSG.CITY_INVALID_ENTRY")),
    countyOfBirth: yup
      .string()
      .matches(
        /^[_A-zÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÝàáâãäåçèéêëìíîïðòóôõöùúûüýÿ\\s]*((-|\s)*[_A-z\-])*$/,
        t("COMMON_ERROR_MSG.COUNTY_ERROR")
      ),

    dateOfDeath: yup.date(),

    dateOfBirth: yup
      .date()
      .nullable()
      .min(new Date("1780-01-01"), t("DATE_COMMON_ERRORS.VERIFY_OPTIONAL_BIRTH_DATE_VALID"))
      .when(
        "dateOfDeath",
        (dateOfDeath, schema) =>
          dateOfDeath &&
          schema.max(
            new Date(dateOfDeath),
            t("DATE_COMMON_ERRORS.VERIFY_BIRTH_DATE_VALID")
          )
      ),

    parent1FirstName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.FIRST_NAME_INVALID_ENTRY")
      ),
    parent1MiddleName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.MIDDLE_NAME_INVALID_ENTRY")
      ),
    parent1LastName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.LAST_NAME_INVALID_ENTRY")
      ),
    parent2FirstName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.FIRST_NAME_INVALID_ENTRY")
      ),
    parent2MiddleName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.MIDDLE_NAME_INVALID_ENTRY")
      ),
    parent2LastName: yup
      .string()
      .matches(
        /(?=^.{2,50}$)^[A-Za-z]([^0-9!@#$%^&*{}()_+=?/.,<>|;:]*)([-]*[A-Za-z])$|^$/,
        t("COMMON_ERROR_MSG.LAST_NAME_INVALID_ENTRY")
      ),
    noOfCopies: yup
      .number()
      .typeError(t("VALIDATION_COMMON.COPIES_LIMIT_MIN"))
      .required(t("VALIDATION_COMMON.COPIES_LIMIT_MIN"))
      .moreThan(0, t("COMMON_ERROR_MSG.NO_OF_COPIES"))
      .lessThan(11, t("VALIDATION_COMMON.COPIES_LIMIT_MAX")),
  });

  const { register, handleSubmit, control, errors } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(SignupSchema),
  });

  if (props.editIndex !== "") {
    if (
      (globalState.deathVerificationInfo.lastName !== undefined ||
        globalState.deathVerificationInfo.lastName !== "") &&
      (globalState.deathVerificationInfo.firstName !== undefined ||
        globalState.deathVerificationInfo.firstName !== "")
    ) {
      globalState.deathVerificationInfo =
        globalState.totalOrderRecords[props.editIndex];
      //setShowParentChecked(globalState.deathVerificationInfo.showParentTwo);
    }
  }

  globalState.deathVerificationInfo.dateOfDeath = globalState
    .getStarteDeathVerificationInfo.dateOfDeath
    ? DateFormatter(
      globalState.getStarteDeathVerificationInfo.dateOfDeath,
      "mm/dd/yyyy"
    )
    : "";

  const onSubmit = (data) => {
    data.certificateInfoType = "deathVerification";
    data.uid = IDGenerator();
    data.selectedState =
      globalState.getStartedBirthVerificationInfo.selectedState;
    globalActions.deathVerificationInfo(data, props.editIndex);
    history.push(t("URLS.ORDER_REVIEW"));
  };

  const addAnotherRecord = (data) => {
    data.certificateInfoType = "deathVerification";
    data.uid = IDGenerator();
    data.selectedState =
      globalState.getStartedBirthVerificationInfo.selectedState;
    globalActions.deathVerificationInfo(data, props.editIndex);
    window.scrollTo(0, 0);
    history.push(t("URLS.SELECT_VITAL_RECORD"));
  };

  return (
    <div>
      <Progress currentStep={2} totalSteps={4} />
      <h1 className="deathverification-heading">
        {t("DEATH_VERIFICATION_INFO.HEADING")}
      </h1>
      <h2 className="birth-personal-info">
        {t("DEATH_VERIFICATION_INFO.DEATH_VERIFICATIONINFO")}
      </h2>
      <div>
        <form
          className="verify-content-section"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="verifyinformation-div">
            <FormInput
              id="firstName"
              name="firstName"
              type="text"
              label={t("DEATH_VERIFICATION_INFO.FIRST_NAME")}
              register={register}
              className={`birth-info-input answer ${errors.firstName ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathVerificationInfo.firstName
                  ? globalState.deathVerificationInfo.firstName
                  : ""
              }
              error={errors.firstName}
            />
            <FormInputOptional
              id="middleName"
              name="middleName"
              type="text"
              label={t("DEATH_VERIFICATION_INFO.MIDDLE_NAME")}
              labelOptional={t("COMMONS.OPTIONAL")}
              register={register}
              className={`birth-info-input answer ${errors.middleName ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathVerificationInfo.middleName
                  ? globalState.deathVerificationInfo.middleName
                  : ""
              }
              error={errors.middleName}
            />
            <FormInput
              id="lastName"
              name="lastName"
              type="text"
              label={t("DEATH_VERIFICATION_INFO.LAST_NAME")}
              register={register}
              className={`birth-info-input answer ${errors.lastName ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathVerificationInfo.lastName
                  ? globalState.deathVerificationInfo.lastName
                  : ""
              }
              error={errors.lastName}
            />

            <div className="death-gender-input-align">
              <label className="name">
                {t("DEATH_VERIFICATION_INFO.GENDER")}
                <Controller
                  name="selectedGender"
                  className={`get-state-birth ${errors.selectedGender ? "error-red" : ""
                    }`}
                  as={Select}
                  options={GenderList()}
                  control={control}
                  isClearable
                  defaultValue={
                    globalState.deathVerificationInfo.selectedGender.label
                      ? globalState.deathVerificationInfo.selectedGender
                      : ""
                  }
                  rules={{ required: true }}
                />
                {errors.selectedGender && (
                  <p className="error-red-content">
                    {t("COMMON_ERROR_MSG.GENDER_ERROR")}
                  </p>
                )}
              </label>
            </div>

            <FormInputOptional
              id="ssn"
              name="ssn"
              type="number"
              label={t("DEATH_VERIFICATION_INFO.SSN")}
              labelOptional={t("COMMONS.OPTIONAL")}
              register={register}
              className={`birth-info-input ${errors.ssn ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathVerificationInfo.ssn
                  ? globalState.deathVerificationInfo.ssn
                  : ""
              }
              error={errors.ssn}
            />
            <FormInput
              id="dateOfDeath"
              name="dateOfDeath"
              type="text"
              label={t("DEATH_VERIFICATION_INFO.DATE_DEATH")}
              className={`birth-info-input answer ${errors.dateOfDeath ? "error-red" : ""
                }`}
              register={register}
              disabled={true}
              defaultValue={globalState.deathVerificationInfo.dateOfDeath}
            />
            <FormInput
              id="city"
              name="city"
              type="text"
              label={t("DEATH_VERIFICATION_INFO.CITY")}
              register={register}
              className={`birth-info-input answer ${errors.city ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathVerificationInfo.city
                  ? globalState.deathVerificationInfo.city
                  : ""
              }
              error={errors.city}
            />


            <div className="info-input-align">
              <label className="name">
                {t("DEATH_CERTIFICATE_INFO.COUNTY")}
                <Controller
                  name="countyOfDeath"
                  className={`get-state-birth ${errors.countyOfDeath ? "error-red" : ""
                    }`}
                  as={Select}
                  options={countyList}
                  control={control}
                  isClearable
                  defaultValue={
                    globalState.deathVerificationInfo.countyOfDeath.label
                      ? globalState.deathVerificationInfo.countyOfDeath
                      : ""
                  }
                  rules={{ required: true }}
                />
                {errors.countyOfDeath && (
                  <p className="error-red-content">
                    {t("COMMON_ERROR_MSG.COUNTY_DEATH")}
                  </p>
                )}
              </label>
            </div>

            <div className="county-death">
              <label htmlFor="dateOfBirth" className="birth-content">
                {t("DEATH_VERIFICATION_INFO.DATE_ON_CERTIFICATE")}
                <span className="optional-text">{t("COMMONS.OPTIONAL")}</span>
              </label>
              <Controller
                control={control}
                name="dateOfBirth"
                defaultValue={globalState.deathVerificationInfo.dateOfBirth}
                className="input-date"
                render={({ onChange, onBlur, value }) => (
                  <ReactDatePicker
                    onChange={onChange}
                    id="dateOfBirth"
                    onBlur={onBlur}
                    selected={value}
                    className={`input-date get-state-birth ${errors.dateOfBirth ? "error-red" : ""
                      }`}
                    showMonthDropdown
                    showYearDropdown
                    open={isMobile ? false : undefined}
                    customInput={<MaskedInput mask="11/11/1111" />}
                    placeholderText="00/00/0000"
                  />
                )}
              />
              {errors.dateOfBirth && (
                <p className="error-red-content">{errors.dateOfBirth.message}</p>
              )}
            </div>

            <FormInputOptional
              id="cityOfBirth"
              name="cityOfBirth"
              type="text"
              label={t("DEATH_VERIFICATION_INFO.CITY_BIRTH")}
              labelOptional={t("COMMONS.OPTIONAL")}
              register={register}
              className={`birth-info-input answer ${errors.cityOfBirth ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathVerificationInfo.cityOfBirth
                  ? globalState.deathVerificationInfo.cityOfBirth
                  : ""
              }
              error={errors.cityOfBirth}
            />
            <FormInputOptional
              id="countyOfBirth"
              name="countyOfBirth"
              type="text"
              label={t("DEATH_VERIFICATION_INFO.COUNTY_BIRTH")}
              labelOptional={t("COMMONS.OPTIONAL")}
              register={register}
              className={`birth-info-input answer ${errors.countyOfBirth ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathVerificationInfo.countyOfBirth
                  ? globalState.deathVerificationInfo.countyOfBirth
                  : ""
              }
              error={errors.countyOfBirth}
            />

            <div className="select-align">
              <label className="birth-content">
                {t("DEATH_VERIFICATION_INFO.STATE_BIRTH")}
                <span className="optional-text">{t("COMMONS.OPTIONAL")}</span>
                <Controller
                  name="stateOfBirth"
                  className={`get-state-birth field-label-padding ${errors.stateOfBirth ? "error-red" : ""
                    }`}
                  as={Select}
                  options={stateList}
                  control={control}
                  isClearable
                  defaultValue={
                    globalState.deathVerificationInfo.stateOfBirth
                      .label
                      ? globalState.deathVerificationInfo.stateOfBirth
                      : ""
                  }
                  rules={{ required: true }}
                />
                {errors.stateOfBirth && (
                  <p className="error-red-content">
                    {t("GET_STARTED_BIRTHCERTIFICATE.STATE_ERROR")}
                  </p>
                )}
              </label>
            </div>


            <h2 className="deathverification-parent">
              {t("DEATH_VERIFICATION_INFO.PARENTS_HEADING")}
              <span className="optional-text">{t("COMMONS.OPTIONAL")}</span>
            </h2>
            <FormInput
              id="parent1FirstName"
              name="parent1FirstName"
              type="text"
              label={t("DEATH_VERIFICATION_INFO.PARENT1_FIRST_NAME")}
              register={register}
              rules={{ required: true }}
              className={`birth-info-input answer ${errors.parent1FirstName ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathVerificationInfo.parent1FirstName
                  ? globalState.deathVerificationInfo.parent1FirstName
                  : ""
              }
              error={errors.parent1FirstName}
            />
            <FormInput
              id="parent1MiddleName"
              name="parent1MiddleName"
              type="text"
              label={t("DEATH_VERIFICATION_INFO.PARENT1_MIDDLE_NAME")}
              register={register}
              className={`birth-info-input answer ${errors.parent1MiddleName ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathVerificationInfo.parent1MiddleName
                  ? globalState.deathVerificationInfo.parent1MiddleName
                  : ""
              }
              error={errors.parent1MiddleName}
            />
            <FormInput
              id="parent1LastName"
              name="parent1LastName"
              type="text"
              label={t("DEATH_VERIFICATION_INFO.PARENT1_LAST_NAME")}
              register={register}
              rules={{ required: true }}
              className={`birth-info-input answer ${errors.parent1LastName ? "error-red" : ""
                }`}
              defaultValue={
                globalState.deathVerificationInfo.parent1LastName
                  ? globalState.deathVerificationInfo.parent1LastName
                  : ""
              }
              error={errors.parent1LastName}
            />
            <div className="form-checkbox-default">
              <input
                type="checkbox"
                onChange={() => setShowParentChecked(!showParentChecked)}
                className="checkbox"
                id="parenttwoshow"
                name="showParentTwo"
                ref={register}
                checked={globalState.deathVerificationInfo.showParentTwo}
              />
              <label htmlFor="parenttwoshow" className="checkbox-parent">
                {t("DEATH_VERIFICATION_INFO.PARENT_CHECKBOX")}
              </label>
            </div>
            {!showParentChecked && (
              <>
                <FormInput
                  id="parent2FirstName"
                  name="parent2FirstName"
                  type="text"
                  label={t("DEATH_VERIFICATION_INFO.PARENT2_FIRST_NAME")}
                  register={register}
                  className={`birth-info-input answer ${errors.parent2FirstName ? "error-red" : ""
                    }`}
                  defaultValue={
                    globalState.deathVerificationInfo.parent2FirstName
                      ? globalState.deathVerificationInfo.parent2FirstName
                      : ""
                  }
                  error={errors.parent2FirstName}
                />
                <FormInput
                  id="parent2MiddleName"
                  name="parent2MiddleName"
                  type="text"
                  label={t("DEATH_VERIFICATION_INFO.PARENT2_MIDDLE_NAME")}
                  register={register}
                  className={`birth-info-input answer ${errors.parent2MiddleName ? "error-red" : ""
                    }`}
                  defaultValue={
                    globalState.deathVerificationInfo.parent2MiddleName
                      ? globalState.deathVerificationInfo.parent2MiddleName
                      : ""
                  }
                  error={errors.parent2MiddleName}
                />
                <FormInput
                  id="parent2LastName"
                  name="parent2LastName"
                  type="text"
                  label={t("DEATH_VERIFICATION_INFO.PARENT2_LAST_NAME")}
                  register={register}
                  className={`birth-info-input answer ${errors.parent2LastName ? "error-red" : ""
                    }`}
                  defaultValue={
                    globalState.deathVerificationInfo.parent2LastName
                      ? globalState.deathVerificationInfo.parent2LastName
                      : ""
                  }
                  error={errors.parent2LastName}
                />
              </>
            )}
            <h2 className="death-verification-info record-details-padding">
              {t("DEATH_VERIFICATION_INFO.RECORD_AMOUNT")}
            </h2>
            <NumberField
              id="noOfCopies"
              name="noOfCopies"
              type="number"
              register={register}
              rules={{ required: true }}
              label={t("DEATH_VERIFICATION_INFO.COPIES")}
              defaultValue={
                globalState.deathVerificationInfo.noOfCopies
                  ? globalState.deathVerificationInfo.noOfCopies
                  : ""
              }
              error={errors.noOfCopies}
            />

            <Row className="btn-death-info" sm={1} xs={1} md={2} lg={2}>
              <Col>
                <Button
                  variant="outline-primary"
                  className="back-button-deathverification"
                  onClick={handleSubmit(addAnotherRecord)}
                >
                  {t("COMMONS.ADD_RECORD")}
                </Button>
              </Col>
              <Col xs={{ order: isMobileOnly ? "first" : "last" }}>
                <Button variant="primary" className="next-button" type="submit">
                  {t("COMMONS.NEXT")}
                </Button>
              </Col>
            </Row>
          </div>
        </form>
      </div>
    </div >
  );
}
export default GetStartedDeathVerification;
