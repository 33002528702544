import React from "react";

export const FormInput = ({
  register,
  label,
  name,
  id,
  control,
  defaultValue,
  number,
  className,
  rules,
  error,
  ...inputProps
}) => {
  return (
    <>
      <label htmlFor={id} className="birth-info-name">
        {label}
      </label>
      <div className="info-input-align field-label-padding">
        <input
          ref={register}
          id={id}
          className={className}
          name={name}
          type={number}
          rules={rules}
          defaultValue={defaultValue}
          {...inputProps}
        />
        {error && <p className="error-red-content">{error.message}</p>}
      </div>
    </>
  );
};

export const FormInputOptional = ({
  register,
  label,
  labelOptional,
  name,
  id,
  control,
  defaultValue,
  number,
  className,
  rules,
  error,
  ...inputProps
}) => {
  return (
    <>
      <label htmlFor={id} className="birth-info-name">
        {label}
        <span className="optional-text">{labelOptional}</span>
      </label>
      <div className="info-input-align field-label-padding">
        <input
          ref={register}
          id={id}
          className={className}
          name={name}
          type={number}
          rules={rules}
          defaultValue={defaultValue}
          {...inputProps}
        />
        {error && <p className="error-red-content">{error.message}</p>}
      </div>
    </>
  );
};