import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import history from "./../common/history";
import "./ErrorPage.scss"

function ErrorPage() {
  const { t } = useTranslation();

  return (
    <>
      <Container className="first-container">
        <h2 className="error-page-heading">{t("ERROR_PAGE.404_ERROR")}</h2>
        <p className="error-page-description">{t("ERROR_PAGE.PAGE_NOT_FOUND")}</p>
        <div className="button-align-errorpage">
        <Link
          className="button-primary error-page-button-primary"
          onClick={() => {
            window.scrollTo(0, 0);
            history.push(t("URLS.ORDER_VITAL_RECORDS"));
          }}
        >
          {t("ERROR.TAKE_ME_HOME")}
        </Link>
        </div>
      </Container>
    </>
  );
}

export default ErrorPage;
