import React, { useState } from "react";
import { Container } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";
import "../../../components/pages/orderStatus/OrderStatus.scss";
import { Helmet } from "react-helmet";
import GetCanonicalUrl from "../../common/GetCanonicalUrl";

import { useTranslation } from "react-i18next";

import { NoOrderStatus } from "../../common";
import { OrderStatus } from "../../common";
import { OrderFound } from "../../common";

function OrderStatusPage() {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = (value) => {
    window.scrollTo(0, 0);
    setCurrentStep(value);
  };
  const prevStep = (value) => {
    window.scrollTo(0, 0);
    setCurrentStep(value);
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("ORDER_STATUS.TITLE")}</title>
        
        <meta
          name="description"
          content={t("ORDER_STATUS.META_CONTENT")}
        />

        <link
          rel="canonical"
          href={GetCanonicalUrl() + t("URLS.VITAL_RECORD_ORDER_STATUS_2")}
        />
      </Helmet>
      <Container className="status-container">
        {currentStep === 0 && <OrderStatus nextCallback={nextStep} />}
        {currentStep === 1 && (
          <NoOrderStatus nextCallback={nextStep} prevCallback={prevStep} />
        )}
        {currentStep === 2 && <OrderFound prevCallback={prevStep} />}
      </Container>
    </div>
  );
}

export default OrderStatusPage;
