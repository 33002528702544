import PostCall from "./CommonApi";

export default function GetItemizedTotal(orderId,uuid) {
  let url = "/v1/ovra/transaction/OrderFeeCalculation";
  let requestObject = {
    applicationId: "OVRA",
    agencyId: "DSHS",
    uniqueIdentifier: uuid,
    includeAdditionalStates: false,
    orderNumber: orderId,
  };

  return PostCall(url, requestObject);
}
