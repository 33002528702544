import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Progress from "../../common/Progress";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { GetOrderStatusAmount } from "./OrderReviewHelper";
import { GetOrderStatus } from "../../../api/GetOrderStatus";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import close from "../../../assets/close.svg";
import alert from "../../../assets/alert-warning@1x.png";
import useGlobal from "../../../store";
import "./OrderReview.scss";
import DateFormatter from "../../common/Date";
import history from "../../common/history";
import { trackPromise } from "react-promise-tracker";
import { Spinner } from "../../common/spinner";

function OrderReview(props) {
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  const [OrderReviewUpdated, setOrderReview] = useState([]);
  const [showError, setShowError] = useState({
    moreRecords: false,
    addMoreRecords: false,
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState({
    openModal: false,
    deleteindex: 0,
    multipleRecords: false,
  });
  const [donationChecked, setDonationChecked] = useState(false);

  const { handleSubmit } = useForm({});

  var recordTypeDict = {
    birthCertificate: t("URLS.ORDER_BIRTH_CERTIFICATE"),
    deathVerification: t("URLS.ORDER_DEATH_VERIFICATION_LETTER"),
    marriageVerification: t("URLS.ORDER_MARRIAGE_VERIFICATION_LETTER"),
    birthVerification: t("URLS.ORDER_BIRTH_VERIFICATION_LETTER"),
    deathCertificate: t("URLS.ORDER_DEATH_CERTIFICATE"),
    divorceVerification: t("URLS.ORDER_DIVORCE_VERIFICATION_LETTER"),
  };

  let OrderReview = [];

  const BindOrderReview = () => {
    OrderReview = [];

    if (
      globalState.totalOrderRecords !== undefined &&
      globalState.totalOrderRecords.length > 0
    ) {
      globalState.totalOrderRecords.forEach(function (item, index) {
        var reviewData;
        let RecordDetails = [];
        var recordType;
        if (item.certificateInfoType === "deathVerification") {
          recordType = {
            recordType: "Death Verification",
            copies: item.noOfCopies,
            id: item.uid,
          };
          RecordDetails.push(recordType);
          reviewData = {
            nameOnRecord:
              item.firstName + " " + item.middleName + " " + item.lastName,
            date: DateFormatter(item.dateOfDeath, "mm/dd/yyyy"),
            amount: 0,
            format: "deathVerification",
            id: item.uid,
            recordDetails: RecordDetails,
          };
          OrderReview.push(reviewData);
        } else if (item.certificateInfoType === "birthCertificate") {
          item.orderDetails.forEach(function (element, elemIndex) {
            recordType = {
              recordType: element.recordType,
              copies: element.numberOfCopies,
              id: item.uid + "_" + elemIndex,
            };
            RecordDetails.push(recordType);
          });
          reviewData = {
            nameOnRecord:
              item.firstName + " " + item.middleName + " " + item.lastName,
            date: item.dateOfBirth ? item.dateOfBirth : "",
            amount: 0,
            format: "birthCertificate",
            id: item.uid,
            recordDetails: RecordDetails,
          };
          OrderReview.push(reviewData);
        } else if (item.certificateInfoType === "divorceVerification") {
          recordType = {
            recordType: "Divorce Verification",
            copies: item.noOfCopies,
            id: item.uid,
          };
          RecordDetails.push(recordType);
          reviewData = {
            nameOnRecord:
              item.spouse1FirstName +
              " " +
              item.spouse1MiddleName +
              " " +
              item.spouse1LastName +
              ", " +
              item.spouse2FirstName +
              " " +
              item.spouse2MiddleName +
              " " +
              item.spouse2LastName,
            date: item.dateOfDivorce ? item.dateOfDivorce : "",
            amount: 0,
            format: "divorceVerification",
            id: item.uid,
            recordDetails: RecordDetails,
          };
          OrderReview.push(reviewData);
        } else if (item.certificateInfoType === "deathCertificate") {
          recordType = {
            recordType: "Death Certificate",
            copies: item.noOfCopies,
            id: item.uid,
          };
          RecordDetails.push(recordType);
          reviewData = {
            nameOnRecord:
              item.firstName + " " + item.middleName + " " + item.lastName,
            date: DateFormatter(item.dateOfDeath, "mm/dd/yyyy"),
            amount: 0,
            format: "deathCertificate",
            id: item.uid,
            recordDetails: RecordDetails,
          };
          OrderReview.push(reviewData);
        } else if (item.certificateInfoType === "marriageVerification") {
          recordType = {
            recordType: "Marriage Verification",
            copies: item.noOfCopies,
            id: item.uid,
          };
          RecordDetails.push(recordType);
          reviewData = {
            nameOnRecord:
              item.spouse1FirstName +
              " " +
              item.spouse1MiddleName +
              " " +
              item.spouse1LastName +
              ", " +
              item.spouse2FirstName +
              " " +
              item.spouse2MiddleName +
              " " +
              item.spouse2LastName,
            date: item.dateOfMarriage ? item.dateOfMarriage : "",
            amount: 0,
            format: "marriageVerification",
            id: item.uid,
            recordDetails: RecordDetails,
          };
          OrderReview.push(reviewData);
        } else if (item.certificateInfoType === "birthVerification") {
          recordType = {
            recordType: "Birth Verification",
            copies: item.noOfCopies,
            id: item.uid,
          };
          RecordDetails.push(recordType);
          reviewData = {
            nameOnRecord:
              item.firstName + " " + item.middleName + " " + item.lastName,
            date: item.dateOfBirth ? item.dateOfBirth : "",
            amount: 0,
            format: "birthVerification",
            id: item.uid,
            recordDetails: RecordDetails,
          };
          OrderReview.push(reviewData);
        }
      });

      if (OrderReview.length > 0) {
        trackPromise(
          GetOrderStatus(OrderReview, globalState.uniqueIdentifier).then(
            (response) => {
              if (
                response.data.feeDetails !== undefined &&
                response.data.feeDetails.length > 0
              ) {
                setOrderReview(
                  GetOrderStatusAmount(OrderReview, response.data.feeDetails)
                );
                if (OrderReview.length > 1) {
                  setDeleteModalOpen((prevState) => ({
                    ...prevState,
                    multipleRecords: true,
                  }));
                } else {
                  setDeleteModalOpen((prevState) => ({
                    ...prevState,
                    multipleRecords: false,
                  }));
                }
                if (
                  response.data.recordCopiesLimit === "MAX_RECORD_REQUEST_LIMIT"
                ) {
                  setShowError({ moreRecords: true, addMoreRecords: true });
                }
              } else {
                setShowError({ moreRecords: true, addMoreRecords: true });
              }
            }
          )
        ).catch(function (error) {
          console.log("Checkout error", error);
          history.push(t("URLS.ERROR_PAGE"));
        });
      }
    } else {
      history.push(t("URLS.SELECT_VITAL_RECORD"));
    }
  };

  useEffect(() => {
    BindOrderReview();
  }, []);
  const onSubmit = () => {
    if (!showError.addMoreRecords && !showError.moreRecords) {
      globalState.orderReview.donationChecked = donationChecked;
      props.nextCallback();
    } else {
      setShowError({ moreRecords: true, addMoreRecords: true });
    }
  };

  const deleteRecord = () => {
    var deletedRecordindex = OrderReviewUpdated[deleteModalOpen.deleteindex];
    let orderRecords = [];

    orderRecords = globalState.totalOrderRecords.filter(
      (x) => x.uid !== deletedRecordindex.id
    );

    globalActions.updateTotalOrderRecords(orderRecords);
    OrderReview.pop();
    BindOrderReview();
    setShowError((prevState) => ({ ...prevState, addMoreRecords: false, moreRecords: false }));
    setDeleteModalOpen((prevState) => ({ ...prevState, openModal: false }));
  };

  const addAnotherRecord = () => {
    if (!showError.addMoreRecords) {
      window.scrollTo(0, 0);
      history.push(t("URLS.SELECT_VITAL_RECORD"));
    } else {
      setShowError((prevState) => ({ ...prevState, moreRecords: true }));
    }
  };
  const editRecord = (editIndex) => {
    if (OrderReviewUpdated.length > 0) {
      var singleRecord = OrderReviewUpdated[editIndex];
      if (singleRecord != null) {
        globalActions.saveEditIndex(
          String(
            globalState.totalOrderRecords.findIndex(
              (x) => x.uid === singleRecord.id
            )
          ),
          singleRecord.format
        );
        history.push(recordTypeDict[singleRecord.format]);
      }
    }
  };

  return (
    <>
      <Container className="order-review-container">
        <Progress currentStep={2} totalSteps={4} />
        <h1 className="order-review-heading">{t("ORDER_REVIEW.HEADING")}</h1>
        <p className="order-review-desc">{t("ORDER_REVIEW.DESCRIPTION")}</p>

        <form
          className="order-review-content-section"
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* DELETE LAST RECORD MODAL */}
          <Modal
            show={deleteModalOpen.openModal}
            backdrop="static"
            keyboard={false}
            onHide={() =>
              setDeleteModalOpen((prevState) => ({
                ...prevState,
                openModal: false,
              }))
            }
          >
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-container-order-delete">
              <h2 className="modal-heading-order-delete">
                {t("ORDER_REVIEW.MODAL_DESCRIPTION")}
              </h2>
              {deleteModalOpen.multipleRecords && (
                <p className="modal-desc-order-delete">
                  {t("ORDER_REVIEW.MODAL_MULTIPLE_ORDER")}
                </p>
              )}
              {!deleteModalOpen.multipleRecords && (
                <p className="modal-desc-order-delete">
                  {t("ORDER_REVIEW.MODAL_SINGLE_ORDER")}
                </p>
              )}
              <Button
                variant="primary"
                className="next-button-order"
                onClick={() => deleteRecord()}
              >
                {t("ORDER_REVIEW.DELETE_CONFIRM_MODAL")}
              </Button>
              <div className="modalLink">
                <Link
                  className="modalLinkColor"
                  onClick={() =>
                    setDeleteModalOpen((prevState) => ({
                      ...prevState,
                      openModal: false,
                    }))
                  }
                >
                  {t("ORDER_REVIEW.MODAL_DELETE")}
                </Link>
              </div>
            </div>
          </Modal>

          {OrderReviewUpdated.map((OrderReviewArray, index) => {
            return (
              <>
                <label className="order-sub-heading">
                  {t("ORDER_REVIEW.INFORMATION")} <span>{index + 1}</span>{" "}
                </label>
                <div className="order-info-container">
                  <Row sm={1} xs={1} md={2} lg={2}>
                    <Col>
                      <label className="info-titles">
                        {t("ORDER_REVIEW.NAME")}
                      </label>
                      <p className="info-titles-value">
                        {OrderReviewArray.nameOnRecord}
                      </p>
                    </Col>
                    <Col>
                      <label className="info-titles">
                        {t("ORDER_REVIEW.DATE")}
                      </label>
                      <p className="info-titles-value">
                        {OrderReviewArray.date}
                      </p>
                    </Col>
                  </Row>

                  {OrderReviewArray.recordDetails.map(
                    (RecordDetailsArray, RecordDetailsIndex) => {
                      return (
                        <>
                          {RecordDetailsIndex >= 0 && (
                            <div className="rectangle"></div>
                          )}
                          <Row sm={1} xs={1} md={2} lg={2}>
                            <Col>
                              <label className="info-titles">
                                {t("ORDER_REVIEW.RECORD_TYPE")}
                              </label>
                              <p className="info-titles-value">
                                {RecordDetailsArray.recordType}
                              </p>
                            </Col>
                            <Col>
                              <label className="info-titles">
                                {t("ORDER_REVIEW.NO_OF_COPIES")}
                              </label>
                              <p className="info-titles-value">
                                {RecordDetailsArray.copies}
                              </p>
                            </Col>
                          </Row>
                        </>
                      );
                    }
                  )}
                  <div className="rectangle"></div>
                  <Row>
                    <Col>
                      <label className="info-titles">
                        {t("ORDER_REVIEW.AMOUNT")}
                      </label>
                      <p className="info-titles-value">
                        ${OrderReviewArray.amount}.00{" "}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="order-review-links">
                      <Link
                        className="order-edit"
                        onClick={() => {
                          setDeleteModalOpen((prevState) => ({
                            ...prevState,
                            deleteindex: index,
                          }));
                          editRecord(index);
                        }}
                      >
                        {t("ORDER_REVIEW.EDIT")}
                      </Link>
                      <Link
                        className="order-delete"
                        onClick={() => {
                          setDeleteModalOpen((prevState) => ({
                            ...prevState,
                            openModal: true,
                            deleteindex: index,
                          }));
                        }}
                      >
                        {t("ORDER_REVIEW.DELETE")}
                      </Link>
                    </Col>
                  </Row>
                </div>
              </>
            );
          })}
          <div className="order-button-record-align">
            <Button
              variant="outline-primary"
              className="order-add-another-record"
              onClick={() => {
                addAnotherRecord();
              }}
            >
              {t("ORDER_REVIEW.ADD_ANOTHER_RECORD_BUTTON")}
            </Button>
          </div>
          <h3 className="order-sub-heading">
            {t("ORDER_REVIEW.TEXAS_HOME_VISITATION")}
          </h3>
          <div className="form-checkbox-default">
          <input
            type="checkbox"
            className="checkbox"
            id="checkbox"
            value={donationChecked}
            onChange={(e) => setDonationChecked(!donationChecked)}
          />
          <label htmlFor="checkbox" className="checkbox-attestation">
            {t("ORDER_REVIEW.DONATE")}
          </label>
          </div>
          <div className="cc-form">
            {showError.moreRecords && (
              <Row className="shipping-Info-error-message">
                <Col
                  sm={2}
                  xs={2}
                  md={2}
                  lg={2}
                  className="shipping-Info-alert-icon"
                >
                  <img
                    className="shipping-Info-alert"
                    src={alert}
                    alt="alert"
                  />
                </Col>
                <Col
                  className="shipping-Info-error-content"
                  sm={8}
                  xs={8}
                  md={8}
                  lg={8}
                >
                  <p className="shipping-Info-error-text">
                    {t("ORDER_REVIEW.ORDER_ERROR1")}
                  </p>
                  <p className="shipping-Info-error-desc">
                    {t("ORDER_REVIEW.ORDER_ERROR2")}
                  </p>
                </Col>
                <Col
                  className="shipping-Info-close"
                  sm={2}
                  xs={2}
                  md={2}
                  lg={2}
                >
                  <img
                    src={close}
                    alt="close button"
                    onClick={() =>
                      setShowError((prevState) => ({
                        ...prevState,
                        moreRecords: false,
                      }))
                    }
                  />
                </Col>
              </Row>
            )}
            <div>
              <Spinner />{" "}
            </div>
            <Row
              className="order-review-button-align order-button-record-align"
              sm={1}
              xs={1}
              md={2}
              lg={2}
            >
              <Col md={5} lg={5}>
                <Button
                  variant="primary"
                  className="next-button-order"
                  type="submit"
                >
                  {t("COMMONS.CONTINUE")}
                </Button>
              </Col>
            </Row>
          </div>
        </form>
      </Container>
      <div className="bottom-height-adjust"></div>
    </>
  );
}

export default OrderReview;
