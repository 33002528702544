import PostCall from "./CommonApi";

export const OrderStatusApi = (props) => {
    let url = "/v1/txever/api/OrderService/GetOrderStatusWithRemit";
    let requestObject = props;
    return PostCall(url,requestObject);
};

export const OrderStatusRemitApi = (props) => {
  let url = "/v1/txever/api/OrderService/GetOrderStatusNoRemit";
  let requestObject = props;
  return PostCall(url,requestObject);
};
