import { createBrowserHistory } from "history";
import uuid from "react-native-uuid";
import {intializeGA} from "./GoogleAnalytics";
import pageViewGA from "./GoogleAnalytics";
const history = createBrowserHistory()
var gaPushFlag = 0;
//Initialize Google Analytics
intializeGA();

//console.log("logging: UUID"+ uuid.v1());

history.listen((location,action) => {
  pageViewGA(location.pathname);
  //console.log("PAGEVIEW="+ location.pathname+" HREF="+window.location.href+"  "+ action);
   gaPushFlag=1;
  
})
if (gaPushFlag==0 || (window.performance && (performance.navigation.type === performance.navigation.TYPE_NAVIGATE))) {
  if(window.location.pathname === "/" || window.location.pathname === ""){
  pageViewGA(window.location.href)
 // console.log("PAGEVIEW: HREF"+ " pathname="+ window.location.pathname + " href: "+window.location.href);
  }
  else{
  pageViewGA(window.location.pathname)
  }
 // console.log("PAGEVIEW: PATH"+ " pathname"+ window.location.pathname + " href: "+window.location.href);
}

export default history;