import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import "../birthRecord/RequestBirthRecord.scss";
import VerifyIdentityInState from "../../common/forms/verifyIdentity/VerifyIdentityInState";
import VerifyIdentityOutOfState from "../../common/forms/verifyIdentity/VerifyIdentityOutOfState";
import DeathCertificateInfo from "../../common/forms/deathRecordInfo/DeathCertificateInfo";
import { GetStartedDeathCertificate } from "../../common";
import history from "./../../common/history";
import { useTranslation } from "react-i18next";
import useGlobal from "../../../store";
import uuid from "react-native-uuid";
import GetCanonicalUrl from "../../common/GetCanonicalUrl";
import {GetRelationshipData,GetStateData,GetCountyData} from "referencedata";

function RequestDeathRecord() {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [globalState, globalActions] = useGlobal();
  const [arrayIndex, setArrayIndex] = useState("");

  const nextStep = (value) => {
    window.scrollTo(0, 0);
    value ? setCurrentStep(value) : setCurrentStep(currentStep + 1);

    value
      ? setUrlForForm("deathRecord", value)
      : setUrlForForm("deathRecord", currentStep + 1);
  };
  const prevStep = (value) => {
    window.scrollTo(0, 0);
    value ? setCurrentStep(value) : setCurrentStep(currentStep - 1);
    value
      ? setUrlForForm("deathRecord", value)
      : setUrlForForm("deathRecord", currentStep - 1);
  };

  const setUrlForForm = (record, step) => {
    if (record === "deathRecord") {
      switch (step) {
        case 0:
          history.push(t("URLS.ORDER_DEATH_CERTIFICATE"));
          break;
        case 1:
          history.push(
            t("URLS.ORDER_DEATH_CERTIFICATE") +
              t("URLS.VERIFY_IDENTITY_IN_STATE")
          );
          break;
        case 2:
          history.push(
            t("URLS.ORDER_DEATH_CERTIFICATE") +
              t("URLS.VERIFY_IDENTITY_OUT_STATE")
          );
          break;
        case 3:
          history.push(
            t("URLS.ORDER_DEATH_CERTIFICATE") +
              t("URLS.DEATH_CERTIFICATE_INFORMATION")
          );
          break;
        default:
          history.push(t("URLS.ORDER_DEATH_CERTIFICATE"));
      }
    }
  };
  const firstStep = (value) => {
    window.scrollTo(0, 0);
    value ? setCurrentStep(value) : setCurrentStep(0);
    value
      ? setUrlForForm("deathRecord", value)
      : setUrlForForm("deathRecord", 0);
  };

  useEffect(() => {
    if (!globalState.uniqueIdentifier) {
      globalActions.addUniqueIdentifier("OVRAREF_ID-" + uuid.v1());
    }
    globalActions.clearGlobalState();
    if (
      globalState.saveEditIndex.arrayIndex !== "" &&
      globalState.saveEditIndex.referenceType === "deathCertificate"
    ) {
      setArrayIndex(globalState.saveEditIndex.arrayIndex);
      globalState.getStartedDeathCertificateData.dateOfDeath = new Date(
        globalState.totalOrderRecords[
          globalState.saveEditIndex.arrayIndex
        ].dateOfDeath
      );
      globalState.getStartedDeathCertificateData.selectedState =
        globalState.totalOrderRecords[
          globalState.saveEditIndex.arrayIndex
        ].selectedState;
      globalState.getStartedDeathCertificateData.selectedRelation =
        globalState.totalOrderRecords[
          globalState.saveEditIndex.arrayIndex
        ].selectedRelation;
      globalState.getStartedDeathCertificateData.funeralHomeID =
        globalState.totalOrderRecords[
          globalState.saveEditIndex.arrayIndex
        ].funeralHomeID;
      globalState.deathCertificateInfoData =
        globalState.totalOrderRecords[globalState.saveEditIndex.arrayIndex];
    }
  }, []);

  return (
    <>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{t("DEATH_CERTIFICATE_INFO.TITLE")}</title>
            <meta
              name="description"
              content={t("DEATH_CERTIFICATE_INFO.META_CONTENT")}
            />
            <link
              rel="canonical"
              href={GetCanonicalUrl() + t("URLS.ORDER_DEATH_CERTIFICATE_2")}
            />
          </Helmet>
          <Container className="lg-container">
            {currentStep === 0 && (
                <GetStartedDeathCertificate
                  stateList={GetStateData()}
                  deathRelationList={GetRelationshipData(t("COMMON.LANGUAGE"),"Death")}
                  nextCallback={nextStep}
                />
              )}
            {currentStep === 1 && (
              <VerifyIdentityInState
                stateList={GetStateData()}
                nextCallback={nextStep}
                prevCallback={prevStep}
                flowIndex="deathCertificate"
              />
            )}
            {currentStep === 2 && (
              <VerifyIdentityOutOfState
                stateList={GetStateData()}
                nextCallback={nextStep}
                prevCallback={firstStep}
                flowIndex="deathCertificate"
              />
            )}
            {currentStep === 3 && (
              <DeathCertificateInfo
                stateList={GetStateData()}
                countyList={GetCountyData()}
                editIndex={arrayIndex}
              />
            )}
        </Container>
      </div>
    </>
  );
}

export default RequestDeathRecord;
