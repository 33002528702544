import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import "./GetStartedDeathCertificate.scss";
import { FormInput } from "../../../common/FormInput";
import Progress from "../../Progress";
import Button from "react-bootstrap/Button";
import MaskedInput from "react-maskedinput";
import { useTranslation } from "react-i18next";
import useGlobal from "../../../../store";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import history from "./../../../common/history";
import { isMobile, isMobileOnly } from "react-device-detect";

function GetStartedDeathCertificate(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();

  const GetStartedSchema = yup.object().shape({
    dateOfDeath: yup
      .date()
      .required(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE"))
      .min(new Date("1903-01-01"), t("DATE_COMMON_ERRORS.VERIFY_DATE_MIN"))
      .max(new Date(), t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE"))
      .typeError(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE")),

    validateState: yup.boolean(
      globalState.verifyIdentityData.idVerified === "Y" ? true : false
    ),

    selectedState: yup.object().when("validateState", {
      is: (validateState) => validateState === false,
      then: yup.object().shape({
        label: yup
          .string()
          .typeError(t("GET_STARTED_DEATHCERTIFICATE.STATE_ERROR"))
          .required(t("GET_STARTED_DEATHCERTIFICATE.STATE_ERROR")),

        value: yup
          .string()
          .typeError(t("GET_STARTED_DEATHCERTIFICATE.STATE_ERROR"))
          .required(t("GET_STARTED_DEATHCERTIFICATE.STATE_ERROR")),
      }),
    }),
    selectedRelation: yup
      .string()
      .notOneOf(["Select"])
      .required(t("GET_STARTED_BIRTHCERTIFICATE.RELATION_ERROR")),

    funeralHomeID: yup.string().when("selectedRelation", {
      is: (selectedRelation) => selectedRelation === "Funeral Home of Record",
      then: yup
        .string()
        .required(
          t("GETSTARTED_DEATH_CERTIFICATE_ERROR.FUNERAL_HOME_NUMBER_ERROR")
        )
        .min(
          4,
          t("GETSTARTED_DEATH_CERTIFICATE_ERROR.FUNERAL_HOME_NUMBER_ERROR_MIN")
        )
        .max(
          6,
          t("GETSTARTED_DEATH_CERTIFICATE_ERROR.FUNERAL_HOME_NUMBER_ERROR_MIN")
        )
        .matches(
          /^[_0-9]*((-|\s)*[_0-9])*$/,
          t("GETSTARTED_DEATH_CERTIFICATE_ERROR.FUNERAL_HOME_NUMBER_ERROR")
        )
        .typeError(
          t("GETSTARTED_DEATH_CERTIFICATE_ERROR.FUNERAL_HOME_NUMBER_ERROR")
        ),
    }),
  });
  const { register, handleSubmit, control, errors } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(GetStartedSchema),
  });

  const [selectedRelationship, setSelectedRelationship] = useState("");

  const onSubmit = (data) => {
    if (
      globalState.verifyIdentityData.idVerified !== undefined &&
      globalState.verifyIdentityData.idVerified === "N"
    ) {
      data.selectedState =
        globalState.getStartedDeathCertificateData.selectedState;
    }
    globalActions.addGetStartedDeathCertificate(data);
    if (
      globalState.verifyIdentityData.idVerified !== undefined &&
      globalState.verifyIdentityData.idVerified === "Y"
    ) {
      props.nextCallback(3);
    } else {
      data.selectedState.value === "TX"
        ? props.nextCallback()
        : props.nextCallback(2);
    }
  };

  return (
    <div>
      <Progress currentStep={1} totalSteps={4} />
      <h1 className="birth-heading">
        {t("GET_STARTED_DEATHCERTIFICATE.HEADING")}
      </h1>
      <p className="birth-content">{t("GET_STARTED_DEATHCERTIFICATE.DESC")}</p>
      <label className="sub-get-started">
        {t("GET_STARTED_DEATHCERTIFICATE.GET_STARTED")}
      </label>
      <p className="birth-content">
        {t("GET_STARTED_DEATHCERTIFICATE.DEATH_CONTENT")}
      </p>

      <form className="birth-content-section" onSubmit={handleSubmit(onSubmit)}>
        {(globalState.verifyIdentityData.idVerified === undefined ||
          globalState.verifyIdentityData.idVerified === "" ||
          globalState.verifyIdentityData.idVerified === "N") && (
            <>
              <label className="birth-content ">
                {t("COMMONS.CHOOSE_STATE")}
                <div className="select-align">
                  <Controller
                    name="selectedState"
                    className={`get-state-birth1 ${errors.selectedState ? "error-red" : ""
                      }`}
                    as={Select}
                    options={props.stateList}
                    control={control}
                    isClearable
                    defaultValue={
                      globalState.getStartedDeathCertificateData.selectedState.label
                        ? globalState.getStartedDeathCertificateData.selectedState
                        : ""
                    }
                    rules={{ required: true }}
                  />
                  {errors.selectedState && (
                    <p className="error-red-content">
                      {t("GET_STARTED_BIRTHCERTIFICATE.STATE_ERROR")}
                    </p>
                  )}
                </div>
              </label>
            </>
          )}

        <label className="birth-content ">
          {t("GET_STARTED_DEATHCERTIFICATE.CHOOSE_RELATION")}
          <div className="select-align-death">
            <select
              id="selectedRelation"
              name="selectedRelation"
              className={`birth-info-input answer ${errors.selectedRelation ? "error-red" : ""
                }`}
              control={control}
              ref={register}
              rules={{ required: true }}
              onChange={(e) => setSelectedRelationship(e.target.value)}
            >
              {props.deathRelationList.map(function ({ value, label }, index) {
                return value ===
                  globalState.getStartedDeathCertificateData.selectedRelation ? (
                    <option value={value} selected>
                      {label}
                    </option>
                  ) : (
                    <option value={value}>{label}</option>
                  );
              })}
            </select>
            {errors.selectedRelation && (
              <p className="error-red-content">
                {t("GET_STARTED_DEATHCERTIFICATE.RELATION_ERROR")}
              </p>
            )}
          </div>
        </label>
        {(selectedRelationship === "Funeral Home of Record" ||
          globalState.getStartedDeathCertificateData.selectedRelation ===
          "Funeral Home of Record") && (
            <>
              <FormInput
                id="funeralHomeID"
                name="funeralHomeID"
                type="number"
                label={t("GET_STARTED_DEATHCERTIFICATE.FUNERAL_HOME_NUMBER")}
                register={register}
                rules={{ required: true }}
                className={`birth-info-input answer ${errors.funeralHomeID ? "error-red" : ""
                  }`}
                defaultValue={
                  globalState.getStartedDeathCertificateData.funeralHomeID
                    ? globalState.getStartedDeathCertificateData.funeralHomeID
                    : ""
                }
                error={errors.funeralHomeID}
              />
            </>
          )}
        <label htmlFor="dateOfDeath" className="death-date-content">
          {t("GET_STARTED_DEATHCERTIFICATE.DATE_OF_DEATH")}
        </label>
        <div className="birth-date">
          <Controller
            control={control}
            name="dateOfDeath"
            rules={{ required: true }}
            defaultValue={
              globalState.getStartedDeathCertificateData.dateOfDeath
                ? globalState.getStartedDeathCertificateData.dateOfDeath
                : ""
            }
            className="input-date"
            render={({ onChange, onBlur, value }) => (
              <ReactDatePicker
                onChange={onChange}
                onBlur={onBlur}
                selected={value}
                id="dateOfDeath"
                className={`input-date get-state-birth ${errors.dateOfDeath ? "error-red" : ""
                  }`}
                showMonthDropdown
                showYearDropdown
                open={isMobile ? false : undefined}
                customInput={<MaskedInput mask="11/11/1111" />}
                placeholderText="00/00/0000"
              />
            )}
          />
          {errors.dateOfDeath && (
            <p className="error-red-content">{errors.dateOfDeath.message}</p>
          )}
        </div>

        <Row className="btn-group-align" sm={1} xs={1} md={2} lg={2}>
          <Col>
            <Button
              variant="outline-primary"
              className="back-button"
              onClick={() => {
                window.scrollTo(0, 0);
                history.push(t("URLS.SELECT_VITAL_RECORD"));
              }}
            >
              {t("COMMONS.BACK")}
            </Button>
          </Col>
          <Col xs={{ order: isMobileOnly ? "first" : "last" }}>
            <Button variant="primary" className="next-button" type="submit">
              {t("COMMONS.NEXT")}
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default GetStartedDeathCertificate;
