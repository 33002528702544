import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import PropTypes from "prop-types";

import "./Progress.scss";

function Progress(props) {
  return (
    <React.Fragment>
      <ProgressBar now={(props.currentStep / props.totalSteps) * 100} />
      <div className="progress-step"><strong>
        Step {props.currentStep} of {props.totalSteps}</strong>
      </div>
    </React.Fragment>
  );
}

Progress.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
};
export default Progress;
